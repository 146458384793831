import { ChangeDetectorRef, Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { HeaderData } from 'src/app/interfaces/Header.interface';
import { PageLayoutType } from 'src/app/interfaces/PageLayout.enum';
import { Role } from 'src/app/interfaces/Role.enum';
import { User } from 'src/app/interfaces/User.interface';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent {
  public readonly PageLayoutType = PageLayoutType;
  public currentUser: User;
  private userSubscription?: Subscription;
  public readonly role = Role;

  constructor(private userService: UserService, private cdr: ChangeDetectorRef, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.spinner.show();
    this.userSubscription = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
      this.spinner.hide();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  public getUserHeaderData(): HeaderData {
    return {
      userRole: this.currentUser.role,
      userFirstName: this.currentUser.firstName,
      userLastName: this.currentUser.lastName,
      userProfileImg: this.currentUser.profileImg,
    };
  }
}
