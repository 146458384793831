import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { PopupDialogService } from '../services/popup-dialog.service';
import { MsgInfo } from '../interfaces/MessageModal.interface';

@Directive({
  selector: '[fileDragDrop]',
})
export class FileDragNDropDirective {
  @Input() public isUploadInProgress = false;
  @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
  @Output() private filesHover: EventEmitter<boolean> = new EventEmitter();

  constructor(private popupDialogService: PopupDialogService) {}

  @HostListener('dragover', ['$event']) public onDragOver($event: DragEvent): void {
    if (!this.isUploadInProgress) {
      $event.preventDefault();
      $event.stopPropagation();
      this.filesHover.emit(true);
    }
  }

  @HostListener('dragleave', ['$event']) public onDragLeave($event: DragEvent): void {
    if (!this.isUploadInProgress) {
      $event.preventDefault();
      $event.stopPropagation();
      this.filesHover.emit(false);
    }
  }

  @HostListener('drop', ['$event']) public onDrop($event: any): void {
    if (this.isUploadInProgress) return;

    $event.preventDefault();
    $event.stopPropagation();

    const files = $event.dataTransfer.files;
    const validFiles: File[] = [];

    this.filesHover.emit(false);

    for (const file of files) {
      if (file.type.includes('image/png') || file.type.includes('image/jpeg') || file.type.includes('image/jpg')) {
        validFiles.push(file);
      } else {
        const msgInfo: MsgInfo = {
          success: false,
          msg: 'user_page.message_modal.message.invalid_file',
        };
        this.popupDialogService.openMessageModalPopup(msgInfo);
        return;
      }
    }

    this.filesHover.emit(false);
    this.filesChangeEmiter.emit(validFiles);
  }
}
