import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserRoutes } from '../interfaces/Routes.enum';

export const localGuard: CanActivateFn = (route, state) => {
  const router: Router = inject(Router);

  if (!environment.local) router.navigate([UserRoutes.NOT_FOUND]);
  return environment.local;
};
