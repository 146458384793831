import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationPaths } from 'src/app/interfaces/NavigationPaths.enum';
import { CouponService } from 'src/app/services/coupon.service';

@Component({
  selector: 'app-coupon-auto-select',
  templateUrl: './coupon-auto-select.component.html',
  styleUrls: ['./coupon-auto-select.component.scss'],
})
export class CouponAutoSelectComponent {
  constructor(private route: ActivatedRoute, private router: Router, private coupon: CouponService) {
    const couponCode = this.route.snapshot.paramMap.get('couponCode');

    this.coupon.saveCoupon(couponCode);
    this.router.navigate([NavigationPaths.LOGIN]);
  }
}
