import { InfoBlock } from '../interfaces/InfoBlock.interface';
import { Role } from '../interfaces/Role.enum';

export const AdminStatistics: InfoBlock[] = [
  {
    title: 'admin_page.statistics_page.info_blocks.users',
    icon: 'info-cart-icon.svg',
    iconColor: 'sky-700',
    data: 0,
    query: {
      key: 'role',
      operator: '==',
      value: Role.USER
    },
  },
  {
    title: 'admin_page.statistics_page.info_blocks.events',
    icon: 'orange-card-icon.svg',
    iconColor: 'orange-400',
    stroke: true,
    data: 0,
  },
  {
    title: 'admin_page.statistics_page.info_blocks.customers',
    icon: 'stars-icon.svg',
    iconColor: 'green-300',
    stroke: true,
    data: 0,
  },
  {
    title: 'admin_page.statistics_page.info_blocks.clients',
    icon: 'stars-icon.svg',
    iconColor: 'green-300',
    stroke: true,
    data: 0,
    query: {
      key: 'role',
      operator: '==',
      value: Role.CLIENT
    },
  },
];

export const AdminPaymentStatistics: InfoBlock[] = [
  {
    title: 'admin_page.statistics_page.info_blocks.error_payments',
    icon: 'stars-icon.svg',
    stroke: true,
    iconColor: 'green-300',
    data: 0,
  },
  {
    title: 'admin_page.statistics_page.info_blocks.succeeded_payments',
    icon: 'stars-icon.svg',
    stroke: true,
    iconColor: 'green-300',
    data: 0,
  },
  {
    title: 'admin_page.statistics_page.info_blocks.package1',
    icon: 'stars-icon.svg',
    stroke: true,
    iconColor: 'green-300',
    data: 0,
  },
  {
    title: 'admin_page.statistics_page.info_blocks.package2',
    icon: 'stars-icon.svg',
    stroke: true,
    iconColor: 'green-300',
    data: 0,
  },
  {
    title: 'admin_page.statistics_page.info_blocks.package3',
    icon: 'stars-icon.svg',
    stroke: true,
    iconColor: 'green-300',
    data: 0,
  },
];
