import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-block',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.scss'],
})
export class InfoBlockComponent {
  @Input() icon: string;
  @Input() iconColor?: string;
  @Input() stroke?: boolean;
  @Input() title: string;
  @Input() data: number;
}
