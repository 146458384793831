import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { DateRangeSearchComponent } from 'src/app/components/date-range-search/date-range-search.component';
import { AddEventForm } from 'src/app/definitions/Form.definitions';
import { EventsGuide } from 'src/app/definitions/SectionsGuide.definition';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { ConfirmationPopupType } from 'src/app/interfaces/ConfirmationPopup.enum';
import { DescriptionType } from 'src/app/interfaces/Description.enum';
import { InfoBlock } from 'src/app/interfaces/InfoBlock.interface';
import { PageLayoutType } from 'src/app/interfaces/PageLayout.enum';
import { PopupType } from 'src/app/interfaces/Popup.enum';
import { UserRoutes } from 'src/app/interfaces/Routes.enum';
import { Link } from 'src/app/interfaces/SectionsGuide.interface';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { User } from 'src/app/interfaces/User.interface';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { EventsService } from 'src/app/services/events.service';
import { PaymentService } from 'src/app/services/payment.service';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-events-page',
  templateUrl: './user-events-page.component.html',
  styleUrls: ['./user-events-page.component.scss'],
})
export class UserEventsPageComponent implements OnInit {
  @ViewChild(DateRangeSearchComponent) dateRange: DateRangeSearchComponent;
  public readonly PageLayoutType = PageLayoutType;
  public readonly ButtonType = ButtonType;
  public readonly TitleType = TitleType;
  public readonly DescriptionType = DescriptionType;
  public currentUser: User;
  private userSubscription?: Subscription;
  public InfoBlocks: InfoBlock[] = [];
  public isSearchVisible: boolean = false;
  public searchText: string = '';
  public links: Link[] = EventsGuide;
  private eventsSubscription?: Subscription;
  public isDataLoaded: boolean = false;

  constructor(
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private router: Router,
    private paymentService: PaymentService,
    private popupDialog: PopupDialogService,
    private dataProviderService: DataProviderService,
    private eventsService: EventsService
  ) {}

  ngOnInit(): void {
    this.spinner.show();

    this.userSubscription = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;

      this.paymentService.fetchPayments(this.currentUser, true);

      if (this.currentUser) {
        if (!(this.currentUser && 'activeEvents' in this.currentUser)) {
          this.router.navigate([UserRoutes.USER, UserRoutes.PACKAGES]);
          this.popupDialog.openPopup({
            type: PopupType.DEFAULT_SMALL,
            title: 'user_page.no_bought_events.popup.title.text',
            description: 'user_page.no_bought_events.popup.description.text',
          });
          return;
        }

        this.InfoBlocks = [
          {
            title: 'user_page.info_blocks.bought.text',
            data:
              'activeEvents' in this.currentUser
                ? Number(this.currentUser.activeEvents) +
                  ('totalEvents' in this.currentUser ? Number(this.currentUser.totalEvents) : 0)
                : 0,
            icon: 'info-cart-icon.svg',
            iconColor: 'sky-700',
          },
          {
            title: 'user_page.info_blocks.used.text',
            data: 'totalEvents' in this.currentUser ? Number(this.currentUser.totalEvents) : 0,
            icon: 'orange-card-icon.svg',
            iconColor: 'orange-400',
            stroke: true,
          },
          {
            title: 'user_page.info_blocks.remaining.text',
            data: 'activeEvents' in this.currentUser ? Number(this.currentUser.activeEvents) : 0,
            icon: 'stars-icon.svg',
            iconColor: 'green-300',
            stroke: true,
          },
        ];
      }
      this.isDataLoaded = true;
      this.spinner.hide();
      this.cdr.detectChanges();

      this.eventsSubscription = this.eventsService.triggerEventsSubscription();
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }

  public isUserActive(): boolean {
    return !!this.currentUser;
  }

  public doesUserHaveEvents(): boolean {
    return this.currentUser?.totalEvents && Number(this.currentUser.totalEvents) > 0;
  }

  public openPopupAddEvent(): void {
    if (!(this.currentUser && 'activeEvents' in this.currentUser && Number(this.currentUser.activeEvents) > 0)) {
      this.popupDialog
        .openConfirmationPopup({
          type: ConfirmationPopupType.DEFAULT,
          buttonText: 'user_page.no_active_events.popup.button.text',
          heading: 'user_page.no_active_events.popup.title.text',
          description: 'user_page.no_active_events.popup.description.text',
        })
        .subscribe(res => {
          if (res) {
            this.router.navigate([UserRoutes.USER, UserRoutes.PACKAGES]);
          }
        });
    } else {
      this.popupDialog.openPopup({
        type: PopupType.FORM,
        title: 'user_page.event.form_popup.add_event.title',
        form: AddEventForm,
        user: this.currentUser,
        description: 'user_page.event.form_popup.add_event.description',
        button: 'user_page.event.form_popup.add_event.button',
      });
    }
  }

  public toggleSearchVisibility(): void {
    this.isSearchVisible = !this.isSearchVisible;
  }

  public dateRangeChange(dateRange: DateRange<Date>): void {
    this.dataProviderService.setDateRange(dateRange);
  }

  public searchEvents(): void {
    this.dataProviderService.setSearchInput(this.searchText);
  }

  public changedRoute(): void {
    this.dateRange.resetPicker();
    this.searchText = '';
    this.searchEvents();
  }
}
