import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-block',
  templateUrl: './icon-block.component.html',
  styleUrls: ['./icon-block.component.scss'],
})
export class IconBlockComponent {
  @Input() color: string;
  @Input() icon: string;
  @Input() width?: string;
  @Input() stroke?: boolean;
}
