export enum DbCollections {
  PRODUCTS = 'products',
  USERS = 'users',
  CUSTOMERS = 'customers',
  PAYMENTS = 'payments',
  CHECKOUT_SESSIONS = 'checkout_sessions',
  PRICES = 'prices',
  EVENTS = 'events',
  EVENTS_LIST = 'eventsList',
  STATISTICS = 'statistics',
  COUPONS = 'coupons',
  USED_COUPONS = 'usedCoupons',
}
