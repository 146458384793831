import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Languages } from 'src/app/definitions/Languages.definitions';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { Language } from 'src/app/interfaces/Language.interface';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit, OnDestroy {
  public languages: Language[] = Languages;
  public currentLanguage: string;
  public langContainerVisible: boolean = false;
  public readonly ButtonType = ButtonType;
  public isClickOutsideEnabled: boolean = false;
  private langChangeSubscription: Subscription;

  constructor(private language: LanguageService, private route: ActivatedRoute, private router: Router) {
    this.langChangeSubscription = language.onLanguageChange().subscribe(lang => {
      this.currentLanguage = lang;
    });
  }

  ngOnInit(): void {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    this.currentLanguage = savedLanguage || this.language.getLang();
    this.route.queryParamMap.subscribe(params => {
      const paramLang = params.get('lang');
      if (paramLang) {
        this.currentLanguage = paramLang;
        this.changeLang(paramLang);
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            lang: null,
          },
          queryParamsHandling: 'merge',
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.langChangeSubscription.unsubscribe();
  }

  public changeLang(lang: string): void {
    // Save the selected language in local storage
    localStorage.setItem('selectedLanguage', lang);
    this.language.switchLanguage(lang);
  }

  public getIconPath(): string {
    return `${this.currentLanguage}-icon.svg`;
  }
}
