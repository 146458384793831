import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DateRange, MatCalendar } from '@angular/material/datepicker';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-date-range-search',
  templateUrl: './date-range-search.component.html',
  styleUrls: ['./date-range-search.component.scss'],
})
export class DateRangeSearchComponent {
  public selectedDateRange: DateRange<Date>;
  @Output() dateRangeEmitter = new EventEmitter<DateRange<Date>>();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(MatCalendar) set datePicker(dates: MatCalendar<Date>) {
    dates.selectedChange.subscribe((date: Date) => {
      if (
        this.selectedDateRange &&
        this.selectedDateRange.start &&
        date > this.selectedDateRange.start &&
        !this.selectedDateRange.end
      ) {
        this.selectedDateRange = new DateRange(this.selectedDateRange.start, date);
        this.dateRangeEmitter.emit(this.selectedDateRange);
        this.trigger.closeMenu();
      } else {
        this.selectedDateRange = new DateRange(date, null);
      }
    });

    dates._userDragDrop.subscribe(dates => {
      this.selectedDateRange = dates.value;
      this.dateRangeEmitter.emit(this.selectedDateRange);
    });
  }

  constructor() {
    this.selectedDateRange = new DateRange(null, null);
  }

  public resetPicker(): void {
    this.selectedDateRange = new DateRange(null, null);
    this.dateRangeEmitter.emit(this.selectedDateRange);
  }
}
