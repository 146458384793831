import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ProfileComplete } from './definitions/Form.definitions';
import { PopupType } from './interfaces/Popup.enum';
import { User } from './interfaces/User.interface';
import { AuthenticationService } from './services/authentication.service';
import { CouponService } from './services/coupon.service';
import { LanguageService } from './services/language.service';
import { PopupDialogService } from './services/popup-dialog.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public currentUser: User;
  private userSubscription?: Subscription;
  private isDialogOpenned: boolean = false;

  constructor(
    private languageService: LanguageService,
    private userService: UserService,
    private auth: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private router: Router,
    private popupDialog: PopupDialogService,
    private coupon: CouponService
  ) {}

  ngOnInit(): void {
    this.languageService.initLanguage();

    this.userSubscription = this.userService.currentUser$.subscribe(async user => {
      if (this.currentUser && user && this.currentUser.role !== user.role) {
        window.location.reload();
      }

      if (user && 'isBlocked' in user && user.isBlocked) {
        this.auth.logout();
        this.router.navigate(['/login']);
        window.location.reload();
      }

      if (user) {
        const couponCode = this.coupon.checkSavedCoupons();
        if (couponCode) {
          this.coupon.applyCoupon(couponCode);
        }
        this.currentUser = user;
        this.spinner.hide();
        this.cdr.detectChanges();
        if (!this.isDialogOpenned && 'isProfileCompleted' in user && !user.isProfileCompleted) {
          this.popupDialog.openPopupWithoutClosing({
            type: PopupType.FORM,
            title: 'profile_incompleted.popup.title.text',
            form: ProfileComplete,
            filledInputs: {
              uuid: user.uuid,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              phoneNumber: user.phoneNumber,
            },
            blockClosing: true,
            description: 'profile_incompleted.popup.description.text',
            button: 'admin_page.users_table.edit_user.form_popup.button.update_user',
          });
          this.isDialogOpenned = !this.isDialogOpenned;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }
}
