import { FormFieldsId, FormInputId, FormInputType, FormLabelColor, FormType } from '../interfaces/Form.enum';
import { Form, Input } from '../interfaces/Form.interface';

const RegistrationFormInputs: Input[] = [
  {
    label: 'registration_page.input.label.first_name',
    type: FormInputType.TEXT,
    id: FormInputId.FIRST_NAME,
    inputId: FormInputId.FIRST_NAME,
    placeholder: 'registration_page.input.placeholder.first_name',
    required: true,
  },
  {
    label: 'registration_page.input.label.last_name',
    type: FormInputType.TEXT,
    id: FormInputId.LAST_NAME,
    inputId: FormInputId.LAST_NAME,
    placeholder: 'registration_page.input.placeholder.last_name',
    required: true,
  },
  {
    label: 'registration_page.input.label.phone_number',
    type: FormInputType.TEXT,
    id: FormInputId.PHONE_NUMBER,
    inputId: FormInputId.PHONE_NUMBER,
    placeholder: 'registration_page.input.placeholder.phone_number',
    required: false,
  },
  {
    label: 'registration_page.input.label.email',
    type: FormInputType.EMAIL,
    id: FormInputId.EMAIL,
    inputId: FormInputId.EMAIL,
    placeholder: 'registration_page.input.placeholder.email',
    required: true,
  },
  {
    label: 'registration_page.input.label.password',
    type: FormInputType.PASSWORD,
    id: FormInputId.PASSWORD,
    inputId: FormInputId.PASSWORD,
    placeholder: 'registration_page.input.placeholder.password',
    required: true,
  },
];

export const RegistrationForm: Form = {
  type: FormType.REGISTRATION,
  containsErrorMsg: true,
  elements: RegistrationFormInputs,
  button: 'registration_page.button.registration.text',
};

const LoginFormInputs: Input[] = [
  {
    label: 'login_page.input.label.email',
    type: FormInputType.EMAIL,
    id: FormInputId.EMAIL,
    inputId: FormInputId.EMAIL,
    placeholder: 'login_page.input.placeholder.email',
    required: true,
  },
  {
    label: 'login_page.input.label.password',
    type: FormInputType.PASSWORD,
    id: FormInputId.PASSWORD,
    inputId: FormInputId.PASSWORD,
    placeholder: 'login_page.input.placeholder.password',
    required: true,
    hasRouterLink: true,
    routerLink: '/password-reset',
    routerLinkText: 'login_page.button.password_reset.text',
  },
];

export const LoginForm: Form = {
  type: FormType.LOGIN,
  containsErrorMsg: false,
  elements: LoginFormInputs,
  button: 'login_page.button.login.text',
};

const PaswordResetInputs: Input[] = [
  {
    label: 'password_reset_page.input.label.email',
    type: FormInputType.EMAIL,
    id: FormInputId.EMAIL,
    inputId: FormInputId.EMAIL,
    placeholder: 'password_reset_page.input.placeholder.email',
    required: true,
  },
];

export const PasswordResetForm: Form = {
  type: FormType.PASSWORD_RESET,
  containsErrorMsg: true,
  elements: PaswordResetInputs,
  button: 'password_reset_page.button.password_reset.text',
};

const EditUserFormInputs: Input[] = [
  {
    label: 'admin_page.users_table.edit_user.form_popup.label.first_name',
    type: FormInputType.TEXT,
    id: FormInputId.FIRST_NAME,
    inputId: FormInputId.FIRST_NAME,
    placeholder: '',
    required: true,
    disabled: true,
  },
  {
    label: 'admin_page.users_table.edit_user.form_popup.label.last_name',
    type: FormInputType.TEXT,
    id: FormInputId.LAST_NAME,
    inputId: FormInputId.LAST_NAME,
    placeholder: '',
    required: true,
    disabled: true,
  },
  {
    label: 'admin_page.users_table.edit_user.form_popup.label.phone_number',
    type: FormInputType.TEXT,
    id: FormInputId.PHONE_NUMBER,
    inputId: FormInputId.PHONE_NUMBER,
    placeholder: '',
    required: false,
    disabled: true,
  },
  {
    label: 'admin_page.users_table.edit_user.form_popup.label.active_events',
    type: FormInputType.NUMBER,
    id: FormInputId.ACTIVE_EVENTS,
    inputId: FormInputId.ACTIVE_EVENTS,
    placeholder: '',
    required: false,
  },
  {
    label: 'admin_page.users_table.edit_user.form_popup.label.role',
    type: FormInputType.OPTIONS,
    id: FormInputId.ROLE,
    placeholder: '',
  },
  {
    label: 'admin_page.users_table.edit_user.form_popup.label.email_verified',
    type: FormInputType.SWITCH,
    id: FormFieldsId.IS_EMAIL_VERIFIED,
    placeholder: '',
  },
  {
    label: 'admin_page.users_table.edit_user.form_popup.label.blocked',
    type: FormInputType.SWITCH,
    id: FormFieldsId.IS_BLOCKED,
    placeholder: '',
  },
];

export const EditUserForm: Form = {
  type: FormType.EDIT_USER_POPUP,
  containsErrorMsg: false,
  elements: EditUserFormInputs,
  button: 'admin_page.users_table.edit_user.form_popup.button.update_user',
  customInputClasses: 'px-6 sm:px-10',
};

const ProfileCompleteInputs: Input[] = [
  {
    label: 'admin_page.users_table.edit_user.form_popup.label.first_name',
    type: FormInputType.TEXT,
    id: FormInputId.FIRST_NAME,
    inputId: FormInputId.FIRST_NAME,
    placeholder: '',
    required: true,
  },
  {
    label: 'admin_page.users_table.edit_user.form_popup.label.last_name',
    type: FormInputType.TEXT,
    id: FormInputId.LAST_NAME,
    inputId: FormInputId.LAST_NAME,
    placeholder: '',
    required: true,
  },
  {
    label: 'admin_page.users_table.edit_user.form_popup.label.phone_number',
    type: FormInputType.TEXT,
    id: FormInputId.PHONE_NUMBER,
    inputId: FormInputId.PHONE_NUMBER,
    placeholder: '',
    required: false,
  },
  {
    label: 'registration_page.input.label.email',
    type: FormInputType.TEXT,
    id: FormInputId.EMAIL,
    inputId: FormInputId.EMAIL,
    placeholder: '',
    required: true,
  },
];

export const ProfileComplete: Form = {
  type: FormType.EDIT_PROFILE_POPUP,
  containsErrorMsg: true,
  elements: ProfileCompleteInputs,
  button: 'admin_page.users_table.edit_user.form_popup.button.update_user',
  customInputClasses: 'px-10 max-sm:px-6',
};

const ClientLoginFormInputs: Input[] = [
  {
    label: 'client_page.login.form.first_name.label.text',
    type: FormInputType.TEXT,
    id: FormInputId.FIRST_NAME,
    inputId: FormInputId.FIRST_NAME,
    placeholder: 'client_page.login.form.first_name.placeholder.text',
    required: true,
  },
  {
    label: 'client_page.login.form.last_name.label.text',
    type: FormInputType.TEXT,
    id: FormInputId.LAST_NAME,
    inputId: FormInputId.LAST_NAME,
    placeholder: 'client_page.login.form.last_name.placeholder.text',
    required: true,
  },
  {
    label: 'client_page.login.form.phone_number.label.text',
    type: FormInputType.TEXT,
    id: FormInputId.PHONE_NUMBER,
    inputId: FormInputId.PHONE_NUMBER,
    placeholder: 'client_page.login.form.phone_number.placeholder.text',
  },
];

export const ClientLoginForm: Form = {
  type: FormType.CLIENT_LOGIN,
  containsErrorMsg: true,
  elements: ClientLoginFormInputs,
  button: 'client_page.login.button.text',
  labelColor: FormLabelColor.WHITE,
};

export const ClientDataInputs: Input[] = [
  {
    label: 'client_page.login.form.email.label.text',
    type: FormInputType.TEXT,
    id: FormInputId.EMAIL,
    inputId: FormInputId.EMAIL,
    required: true,
    placeholder: 'client_page.login.form.email.placeholder.text',
  },
];

export const ClientDataForm: Form = {
  type: FormType.CLIENT_DATA,
  containsErrorMsg: true,
  elements: ClientDataInputs,
  button: 'client_page.shopping_cart.popup.email_input.button.order',
  customInputClasses: 'px-10 max-sm:px-6',
};

const AddEventFormInputs: Input[] = [
  {
    label: 'user_page.event.form_popup.name',
    type: FormInputType.TEXT,
    id: FormInputId.NAME,
    inputId: FormInputId.NAME,
    placeholder: '',
    required: true,
    disclaimer: 'user_page.event.form_popup.add_event.disclaimer',
  },
  {
    label: 'user_page.event.form_popup.price',
    type: FormInputType.NUMBER,
    id: FormInputId.PRICE,
    inputId: FormInputId.PRICE,
    placeholder: '',
    required: true,
  },
  {
    label: 'user_page.event.form_popup.currency',
    type: FormInputType.TEXT,
    id: FormInputId.CURRENCY,
    inputId: FormInputId.CURRENCY,
    placeholder: '',
    required: true,
  },
  {
    label: 'user_page.event.form_popup.date',
    type: FormInputType.DATE_PICKER,
    id: FormInputId.EVENT_DATE,
    placeholder: '',
  },
  {
    label: 'user_page.event.form_popup.image',
    type: FormInputType.IMAGE_UPLOAD,
    id: FormInputId.IMAGE,
    placeholder: '',
    required: false,
  },
];

export const AddEventForm: Form = {
  type: FormType.ADD_EVENT,
  containsErrorMsg: true,
  elements: AddEventFormInputs,
  button: 'admin_page.users_table.edit_user.form_popup.button.update_user',
  customInputClasses: 'px-10 max-sm:px-6',
};

export const EditEventForm: Form = {
  type: FormType.EDIT_EVENT,
  containsErrorMsg: true,
  elements: AddEventFormInputs,
  button: 'admin_page.users_table.edit_user.form_popup.button.update_user',
  customInputClasses: 'px-10 max-sm:px-6',
};

export const UserProfileFormInputs = [
  {
    label: 'user_profile_page.form.input.label.first_name.text',
    type: FormInputType.TEXT,
    id: FormInputId.FIRST_NAME,
    inputId: FormInputId.FIRST_NAME,
    placeholder: '',
    required: false,
  },
  {
    label: 'user_profile_page.form.input.label.last_name.text',
    type: FormInputType.TEXT,
    id: FormInputId.LAST_NAME,
    inputId: FormInputId.LAST_NAME,
    placeholder: '',
    required: false,
  },
  {
    label: 'user_profile_page.form.input.label.phone_number.text',
    type: FormInputType.TEXT,
    id: FormInputId.PHONE_NUMBER,
    inputId: FormInputId.PHONE_NUMBER,
    placeholder: '',
    required: false,
  },
  {
    label: 'user_profile_page.form.input.label.company_name.text',
    type: FormInputType.TEXT,
    id: FormInputId.COMPANY_NAME,
    inputId: FormInputId.COMPANY_NAME,
    placeholder: '',
    required: false,
  },
  {
    label: 'user_profile_page.form.input.label.company_address.text',
    type: FormInputType.TEXT,
    id: FormInputId.COMPANY_ADDRESS,
    inputId: FormInputId.COMPANY_ADDRESS,
    placeholder: '',
    required: false,
  },
];

export const UserProfileForm: Form = {
  type: FormType.EDIT_PROFILE,
  containsErrorMsg: true,
  elements: UserProfileFormInputs,
  button: 'user_profile_page.form.submit_button.text',
};

export const AdminProfileFormInputs = [
  {
    label: 'user_profile_page.form.input.label.first_name.text',
    type: FormInputType.TEXT,
    id: FormInputId.FIRST_NAME,
    inputId: FormInputId.FIRST_NAME,
    placeholder: '',
    required: false,
  },
  {
    label: 'user_profile_page.form.input.label.last_name.text',
    type: FormInputType.TEXT,
    id: FormInputId.LAST_NAME,
    inputId: FormInputId.LAST_NAME,
    placeholder: '',
    required: false,
  },
  {
    label: 'user_profile_page.form.input.label.phone_number.text',
    type: FormInputType.TEXT,
    id: FormInputId.PHONE_NUMBER,
    inputId: FormInputId.PHONE_NUMBER,
    placeholder: '',
    required: false,
  },
];

export const AdminProfileForm: Form = {
  type: FormType.EDIT_PROFILE,
  containsErrorMsg: true,
  elements: AdminProfileFormInputs,
  button: 'user_profile_page.form.submit_button.text',
};

export const ClientEventInfoInput: Input[] = [
  {
    label: 'client_page.events.event_id.label.text',
    type: FormInputType.TEXT,
    id: FormInputId.EVENT_REF_ID,
    inputId: FormInputId.EVENT_REF_ID,
    required: true,
    placeholder: 'client_page.events.event_id.placeholder.text',
    disableAutoCapitalize: true,
  },
  {
    label: 'client_page.events.table_number.label.text',
    type: FormInputType.NUMBER,
    id: FormInputId.TABLE_NUMBER,
    inputId: FormInputId.TABLE_NUMBER,
    placeholder: 'client_page.events.table_number.placeholder.text',
  },
];

export const ClientEventInfoForm: Form = {
  type: FormType.CLIENT_EVENT_INPUT,
  containsErrorMsg: true,
  elements: ClientEventInfoInput,
  button: 'client_page.events.go_to_event.button.text',
  labelColor: FormLabelColor.WHITE,
};
