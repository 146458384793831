import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationPaths } from 'src/app/interfaces/NavigationPaths.enum';
import { UserRoutes } from 'src/app/interfaces/Routes.enum';

@Component({
  selector: 'app-user-auto-package-select',
  templateUrl: './user-auto-package-select.component.html',
  styleUrls: ['./user-auto-package-select.component.scss'],
})
export class UserAutoPackageSelectComponent {
  constructor(private route: ActivatedRoute, private router: Router) {
    const packageNumber = this.route.snapshot.paramMap.get('packageNumber');

    if (Number(packageNumber)) {
      localStorage.setItem('packageNumber', packageNumber);
      router.navigate([NavigationPaths.USER_PACKAGES], { queryParamsHandling: 'merge' });
    } else {
      router.navigate([UserRoutes.NOT_FOUND]);
    }
  }
}
