import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { environment } from 'src/environments/environment';
import { UserInfoNeeded } from '../interfaces/Pdf.enum';
import { ProductInfo, UserInfo } from '../interfaces/Pdf.interface';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  private logo = '';

  constructor(private httpClient: HttpClient, private translateService: TranslateService) {
    (window as any).pdfMake.vfs = pdfFonts.pdfMake.vfs;

    this.httpClient
      .get('./assets/images/photo-seller-logo.svg', { responseType: 'text' })
      .subscribe(data => (this.logo = data));
  }

  private translate(key: string): string {
    return this.translateService.instant(key);
  }

  private generateInvoiceContent(
    invoiceNumber: string,
    date: string,
    userInfo: UserInfo,
    productInfo: ProductInfo
  ): TDocumentDefinitions {
    const rows: string[] = [];

    const userInfoNeeded = Object.values(UserInfoNeeded);

    for (let info of userInfoNeeded) {
      if (info in userInfo && !!userInfo[info]) rows.push(userInfo[info]);
    }

    while (rows.length < userInfoNeeded.length) {
      rows.push('');
    }

    return {
      content: [
        [
          // LOGO
          {
            svg: this.logo,
            width: 150,
          },
        ],
        {
          text: this.translate('purchase_invoice.title'),
          fontSize: 26,
          color: '#334C65',
          bold: true,
          alignment: 'center',
        },
        [
          {
            table: {
              widths: ['auto', 'auto'],
              body: [
                [
                  { text: this.translate('purchase_invoice.header.invoice_number'), fontSize: 12, bold: true },
                  { text: invoiceNumber, fontSize: 12, bold: true, margin: [15, 0, 0, 0] },
                ],
                [
                  { text: this.translate('purchase_invoice.header.date_of_issue'), fontSize: 10 },
                  { text: date, fontSize: 10, margin: [15, 0, 0, 0] },
                ],
                [
                  { text: this.translate('purchase_invoice.header.date_due'), fontSize: 10 },
                  { text: date, fontSize: 10, margin: [15, 0, 0, 0] },
                ],
              ],
            },
            margin: [0, 35, 0, 0],
            layout: 'noBorders',
          },
          {
            table: {
              widths: ['50%', '25%', '25%'],
              body: [
                [
                  { text: environment.company.name, fontSize: 12, bold: true },
                  { text: this.translate('purchase_invoice.bill_to'), fontSize: 12, bold: true },
                  { text: this.translate('purchase_invoice.ship_to'), fontSize: 12, bold: true },
                ],
                [
                  { text: environment.company.address.street, fontSize: 10 },
                  { text: rows[0], fontSize: 10 },
                  { text: rows[0], fontSize: 10 },
                ],
                [
                  { text: environment.company.address.suite, fontSize: 10 },
                  { text: rows[1], fontSize: 10 },
                  { text: rows[1], fontSize: 10 },
                ],
                [
                  {
                    text:
                      environment.company.address.city +
                      ', ' +
                      environment.company.address.state +
                      ', ' +
                      environment.company.address.zip,
                    fontSize: 10,
                  },
                  { text: rows[2], fontSize: 10 },
                  { text: rows[2], fontSize: 10 },
                ],
                [
                  { text: environment.company.email, fontSize: 10 },
                  { text: rows[3], fontSize: 10 },
                  { text: rows[3], fontSize: 10 },
                ],
                [
                  { text: environment.company.address.country, fontSize: 10 },
                  { text: rows[4], fontSize: 10 },
                  { text: rows[4], fontSize: 10 },
                ],
              ],
            },
            margin: [0, 20, 0, 0],
            layout: 'noBorders',
          },
          {
            table: {
              widths: ['50%', '10%', '20%', '20%'],
              body: [
                [
                  {
                    text: this.translate('purchase_invoice.table.header.description'),
                    fontSize: 10,
                    bold: true,
                    alignment: 'bottom',
                    color: '#334C65',
                    fillColor: '#E1EFFA',
                    margin: [5, 7],
                  },
                  {
                    text: this.translate('purchase_invoice.table.header.quantity'),
                    fontSize: 10,
                    bold: true,
                    alignment: 'bottom',
                    color: '#334C65',
                    fillColor: '#E1EFFA',
                    margin: [5, 7],
                  },
                  {
                    text: this.translate('purchase_invoice.table.header.unit_price'),
                    fontSize: 10,
                    bold: true,
                    color: '#334C65',
                    fillColor: '#E1EFFA',
                    margin: [5, 7],
                  },
                  {
                    text: this.translate('purchase_invoice.table.header.amount'),
                    fontSize: 10,
                    bold: true,
                    alignment: 'right',
                    color: '#334C65',
                    fillColor: '#E1EFFA',
                    margin: [5, 7],
                  },
                ],
                [
                  { text: productInfo.name, fontSize: 10, margin: [5, 7] },
                  { text: productInfo.quantity, fontSize: 10, margin: [5, 7] },
                  { text: productInfo.currency + ' ' + productInfo.price.toFixed(2), fontSize: 10, margin: [5, 7] },
                  {
                    text: productInfo.currency + ' ' + (productInfo.quantity * productInfo.price).toFixed(2),
                    fontSize: 10,
                    alignment: 'right',
                    margin: [5, 7],
                  },
                ],
              ],
            },
            margin: [0, 20, 0, 0],
            layout: 'noBorders',
          },
        ],
        [
          {
            alignment: 'justify',
            columns: [
              {
                text: '',
              },
              {
                table: {
                  headerRows: 1,
                  widths: ['50%', '50%'],
                  body: [
                    [
                      { text: this.translate('purchase_invoice.table.subtotal'), fontSize: 10, margin: [0, 2.5] },
                      {
                        text: productInfo.currency + ' ' + (productInfo.quantity * productInfo.price).toFixed(2),
                        fontSize: 10,
                        alignment: 'right',
                        margin: [0, 2.5],
                      },
                    ],
                    [
                      { text: this.translate('purchase_invoice.table.total'), fontSize: 10, margin: [0, 2.5] },
                      {
                        text: productInfo.currency + ' ' + (productInfo.quantity * productInfo.price).toFixed(2),
                        fontSize: 10,
                        alignment: 'right',
                        margin: [0, 2.5],
                      },
                    ],
                    [
                      {
                        text: this.translate('purchase_invoice.table.amount_due'),
                        fontSize: 10,
                        bold: true,
                        margin: [0, 2.5],
                      },
                      {
                        text: productInfo.currency + ' ' + (productInfo.quantity * productInfo.price).toFixed(2),
                        fontSize: 10,
                        bold: true,
                        alignment: 'right',
                        margin: [0, 2.5],
                      },
                    ],
                  ],
                },
                layout: {
                  hLineWidth: function (i, node) {
                    return i === node.table.body.length ? 0 : 1;
                  },
                  vLineWidth: function (i, node) {
                    return 0;
                  },
                  hLineColor: function (i, node) {
                    return '#CBE5FF';
                  },
                },
              },
            ],
          },
        ],
      ],
    };
  }

  public generateInvoicePdf(invoiceNumber: string, date: string, userInfo: UserInfo, productInfo: ProductInfo): void {
    let pdf = pdfMake.createPdf(this.generateInvoiceContent(invoiceNumber, date, userInfo, productInfo));
    pdf.download(`Invoice #${invoiceNumber}`);
  }
}
