<div
  *ngIf="order"
  class="container flex flex-col flex-wrap justify-between mt-4 gap-x-12 gap-y-1 sm:gap-y-2 sm:items-center sm:flex-row"
>
  <app-title
    [type]="TitleType.SMALL"
    [text]="order.firstName + ' ' + order.lastName"
    customClasses="sm:whitespace-nowrap"
  ></app-title>

  <div class="flex flex-col gap-1 sm:gap-3 sm:items-center sm:flex-row">
    <p *ngIf="order.tableNumber" class="sm:whitespace-nowrap">
      {{ 'event_page.order_details.table_number' | translate }}:
      <span *ngIf="order.tableNumber" class="font-semibold text-orange-400">{{ order.tableNumber }}</span>
    </p>
    <p *ngIf="order.tableNumber" class="hidden w-1 h-1 rounded-full bg-slate-400 sm:block"></p>
    <p class="sm:whitespace-nowrap">
      {{ 'event_page.order_details.number_of_images' | translate }}:
      <span class="font-semibold text-orange-400">{{ order.totalNumberOfImages }}</span>
    </p>
    <p *ngIf="order.phoneNumber" class="hidden w-1 h-1 rounded-full bg-slate-400 sm:block"></p>
    <p *ngIf="order.phoneNumber" class="sm:whitespace-nowrap">
      {{ 'event_page.order_details.phone_number' | translate }}:
      <span class="font-semibold text-orange-400">{{ order.phoneNumber }}</span>
    </p>
  </div>

  <div class="flex justify-end flex-1 pt-3 sm:pt-0">
    <app-button
      *ngIf="order.isOrderCompleted === false"
      (click)="openFinishOrderPopup()"
      class="self-end w-full rounded-full md:w-fit"
      [buttonInfo]="{
      type: ButtonType.ROYAL_BLUE_SMALL,
      text: 'event_page.finish_order.button.text',
      icon: 'printer-icon.svg',
      iconColor: 'white',
      iconSize: 'w-4',
      customClasses: 'flex items-center justify-center gap-2.5 sm:whitespace-nowrap w-full md:w-fit',
    }"
    ></app-button>
  </div>
</div>

<div *ngIf="order" class="grid grid-cols-4 gap-1 mt-4 mb-10 max-xl:grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1">
  <app-image-card
    *ngFor="let image of orderImages"
    [imageData]="{
      url: image.url,
      name: image.name,
      id: image.id,
      isSelected: image.numberOfImages > 0,
      numberOfImages: image.numberOfImages,
    }"
    [isSelectionBlocked]="order.isOrderCompleted"
    [canBeRemoved]="true"
    (numberOfImagesChange)="hangleNumberOfImageChange($event)"
    (removeImage)="handleRemoveImageFromSelection($event)"
  ></app-image-card>
</div>
