import { Location } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClientDataForm } from 'src/app/definitions/Form.definitions';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { CartItem } from 'src/app/interfaces/CartItem.interface';
import { PriceDetails } from 'src/app/interfaces/OrderDetails.interface';
import { PopupType } from 'src/app/interfaces/Popup.enum';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { User } from 'src/app/interfaces/User.interface';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-shopping-cart-page',
  templateUrl: './shopping-cart-page.component.html',
  styleUrls: ['./shopping-cart-page.component.scss'],
})
export class ShoppingCartPageComponent {
  public cartItems: CartItem[];
  public priceDetails: PriceDetails;
  public readonly TitleType = TitleType;
  public readonly ButtonType = ButtonType;
  public readonly PopupType = PopupType;
  public currentUser: User;
  private userSubscription?: Subscription;

  constructor(
    private _location: Location,
    private shoppingCartService: ShoppingCartService,
    private popupDialog: PopupDialogService,
    private userService: UserService
  ) {
    this.userSubscription = this.userService.currentUser$.subscribe(async user => {
      this.currentUser = user;
    });

    this.shoppingCartService.getItems().subscribe(cartRes => {
      this.cartItems = cartRes;
    });

    shoppingCartService.getPriceDetails().subscribe(price => {
      this.priceDetails = price;
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  checkCart(event: any) {
    if (this.totalQuantity()) {
      return false;
    }

    return undefined;
  }

  public removeFromCartAll(item: CartItem): void {
    // TODO: delete button color and size
    this.popupDialog.openBottomPopup({
      type: PopupType.SHOPPING_CART,
      title: 'client_page.cart.popup.cart.title.text',
      description: 'client_page.cart.popup.cart.description.text',
      icon: 'shopping-cart-trash-icon.svg',
      button: 'client_page.cart.popup.cart.remove.button.text',
      buttonSecond: 'client_page.cart.popup.cart.cancel.button.text',
      buttonFunction: () => {
        this.shoppingCartService.removeItemAll(item);
      },
    });
  }

  public goBack(): void {
    this._location.back();
  }

  public increaseQuantity(item: CartItem): void {
    this.shoppingCartService.updateItem({ ...item, numberOfImages: item.numberOfImages + 1 });
  }

  public decreaseQuantity(item: CartItem): void {
    if (item.numberOfImages > 1)
      this.shoppingCartService.updateItem({ ...item, numberOfImages: item.numberOfImages - 1 });
  }

  public totalQuantity(): number {
    return this.shoppingCartService.getTotalQuantity();
  }

  public totalPrice(): number {
    return this.shoppingCartService.getTotalQuantity() * this.priceDetails.price;
  }

  public createOrder(): void {
    if (this.currentUser?.email) {
      this.shoppingCartService.createOrder({ email: this.currentUser?.email });
    } else {
      this.popupDialog.openPopup({
        type: PopupType.FORM,
        title: 'client_page.shopping_cart.popup.email_input.title.text',
        form: ClientDataForm,
        description: 'client_page.shopping_cart.popup.email_input.description.text',
        button: 'client_page.shopping_cart.popup.email_input.button.order',
        secondButtonText: 'client_page.shopping_cart.popup.email_input.button.skip',
        secondButtonType: ButtonType.ROYAL_BLUE_OUTLINE,
        user: this.currentUser,
      });
    }
  }
}
