import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { ConfirmationPopupType } from 'src/app/interfaces/ConfirmationPopup.enum';
import { ConfirmationPopup } from 'src/app/interfaces/ConfirmationPopup.interface';
import { DescriptionType } from 'src/app/interfaces/Description.enum';
import { TitleType } from 'src/app/interfaces/Title.enum';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss'],
})
export class ConfirmationPopupComponent {
  public readonly TitleType = TitleType;
  public readonly DescriptionType = DescriptionType;
  public readonly ButtonType = ButtonType;
  public readonly ConfirmationPopupType = ConfirmationPopupType;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public confirmation: ConfirmationPopup
  ) {}

  public getContainerClasses(): string {
    return `max-w-143.5 flex flex-wrap px-6 items-center px-10 ${
      this.getIcon() ? 'py-10 gap-y-0.75 gap-x-4 sm:inline-grid sm:grid-cols-2-auto sm:grid-flow-row' : 'py-6'
    }`;
    // * Maybe we gonna use later
    // switch (this.confirmation.type) {
    //   case ConfirmationPopupType.DELETE:
    //     return `max-w-143.5 flex flex-wrap px-6 items-center px-10 pt-10 pb-10 gap-y-0.75 gap-x-4 sm:inline-grid sm:grid-cols-2-auto sm:grid-flow-row`;
    //   default:
    //     return 'max-w-143.5 flex flex-wrap px-6 items-center px-10 pt-10 pb-10 gap-y-0.75 gap-x-4 sm:inline-grid sm:grid-cols-2-auto sm:grid-flow-row';
    // }
  }

  public getIcon(): string {
    switch (this.confirmation.type) {
      case ConfirmationPopupType.DELETE:
        return 'trash-can-icon.svg';
      default:
        return this.confirmation.icon;
    }
  }

  public getConfirmationText(): string {
    switch (this.confirmation.type) {
      case ConfirmationPopupType.DELETE:
        return 'admin_page.users_table.edit_user.confirmation_popup.button.delete';
      default:
        return this.confirmation.buttonText || 'global.button.upload';
    }
  }

  public sendConfirmation(confirmationResult: boolean): void {
    this.dialogRef.close(confirmationResult);
  }
}
