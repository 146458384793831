import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { PaymentErrorCode } from 'src/app/interfaces/ErrorCode.enum';
import { UserRoutes } from 'src/app/interfaces/Routes.enum';

@Component({
  selector: 'app-payment-failed-page',
  templateUrl: './payment-failed-page.component.html',
  styleUrls: ['./payment-failed-page.component.scss'],
})
export class PaymentFailedPageComponent implements OnInit {
  public baseKey: string;

  constructor(private route: ActivatedRoute, private router: Router, private translate: TranslateService) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(take(1)).subscribe(params => {
      const code = params['code'];

      // Check if the provided errorCode is one of the expected values
      if (!Object.values(PaymentErrorCode).includes(code)) {
        this.router.navigate([UserRoutes.NOT_FOUND]);
        return;
      }

      this.baseKey = `payment_page.failed.code_${code}`;
    });
  }
}
