import { Component, Input } from '@angular/core';
import { DescriptionType } from 'src/app/interfaces/Description.enum';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
})
export class DescriptionComponent {
  @Input() type: DescriptionType;
  @Input() text: string;
  @Input() customClasses!: string;

  public getDescClasses(): string {
    switch (this.type) {
      case DescriptionType.MEDIUM:
        return `text-zinc-900 font-normal text-medium-sm ${this.customClasses ? this.customClasses : ''}`;
      case DescriptionType.POPUP:
        return `text-zinc-900 font-normal mt-4 ${this.customClasses ? this.customClasses : ''}`;
      default:
        return `text-gray-400 font-normal text-sm ${this.customClasses ? this.customClasses : ''}`;
    }
  }
}
