import { FieldType } from '../interfaces/Column.enum';
import { DisplayedColumns } from '../interfaces/Column.interface';

export const AdminColumns: DisplayedColumns[] = [
  {
    matColumnDef: 'firstName',
    text: 'admin_page.table.header.first_name',
    canBeSorted: true,
    sortType: 'name',
  },
  {
    matColumnDef: 'lastName',
    text: 'admin_page.table.header.last_name',
    canBeSorted: true,
    sortType: 'name',
  },
  {
    matColumnDef: 'email',
    canBeSorted: true,
    text: 'admin_page.table.header.email',
  },
  {
    matColumnDef: 'isEmailVerified',
    canBeSorted: true,
    text: 'admin_page.table.header.email_verified',
    type: FieldType.BOOLEAN,
    textBasedOnBoolean: {
      true: 'admin_page.table.header.email_verified',
      false: 'admin_page.table.header.email_inactive',
    },
  },
  {
    matColumnDef: 'role',
    canBeSorted: true,
    text: 'admin_page.table.header.role',
    type: FieldType.ROLE,
  },
  {
    matColumnDef: 'edit',
    text: 'admin_page.table.item.button.edit',
    type: FieldType.BUTTON,
  },
];

export const OrdersColumns: DisplayedColumns[] = [
  {
    matColumnDef: 'firstName',
    text: 'admin_page.table.header.first_name',
    canBeSorted: true,
    sortType: 'name',
  },
  {
    matColumnDef: 'lastName',
    text: 'admin_page.table.header.last_name',
    canBeSorted: true,
    sortType: 'name',
  },
  {
    matColumnDef: 'email',
    canBeSorted: true,
    text: 'admin_page.table.header.email',
  },
  {
    matColumnDef: 'paymentCount',
    canBeSorted: true,
    text: 'admin_page.table.header.orders',
  },
  {
    matColumnDef: 'view',
    text: 'admin_page.table.header.view',
    type: FieldType.BUTTON,
  },
];

export const OrderDetailColumns: DisplayedColumns[] = [
  {
    matColumnDef: 'amountWithCurrency',
    text: 'admin_page.table.orders_detail_table.header.amount',
    canBeSorted: true,
  },
  {
    matColumnDef: 'status',
    text: 'admin_page.table.orders_detail_table.header.status',
    canBeSorted: true,
    sortType: 'status',
  },
  {
    matColumnDef: 'formattedCreatedDate',
    text: 'admin_page.table.orders_detail_table.header.date',
    canBeSorted: true,
  },
  {
    matColumnDef: 'last4',
    text: 'admin_page.table.orders_detail_table.header.last4',
    canBeSorted: true,
    sortType: 'last4',
  },
  {
    matColumnDef: 'type',
    canBeSorted: true,
    text: 'admin_page.table.orders_detail_table.header.type',
  },
  {
    matColumnDef: 'brand',
    canBeSorted: true,
    text: 'admin_page.table.orders_detail_table.header.brand',
  },
];

export const UserStatisticsColumns: DisplayedColumns[] = [
  {
    matColumnDef: 'name',
    text: 'user_statistics_page.table.header.event_name',
    canBeSorted: true,
  },
  {
    matColumnDef: 'totalSoldImages',
    text: 'user_statistics_page.table.header.images_sold',
    canBeSorted: true,
  },
  {
    matColumnDef: 'earnings',
    text: 'user_statistics_page.table.header.money_earned',
    canBeSorted: true,
    type: FieldType.MONEY,
  },
  {
    matColumnDef: 'eventDate',
    text: 'user_statistics_page.table.header.event_date',
    canBeSorted: true,
    type: FieldType.DATE,
  },
  {
    matColumnDef: 'envelope',
    text: '',
    type: FieldType.ICON,
  },
];

export const UserOrders: DisplayedColumns[] = [
  {
    matColumnDef: 'firstName',
    text: 'event_page.table.orders.first_name',
    canBeSorted: true,
  },
  {
    matColumnDef: 'lastName',
    text: 'event_page.table.orders.last_name',
    canBeSorted: true,
  },
  {
    matColumnDef: 'tableNumber',
    text: 'event_page.table.orders.table_number',
    canBeSorted: true,
  },
  {
    matColumnDef: 'totalNumberOfImages',
    text: 'event_page.table.orders.number_of_images',
    canBeSorted: true,
  },
  {
    matColumnDef: 'phoneNumber',
    text: 'event_page.table.orders.phone_number',
    canBeSorted: true,
  },
  {
    matColumnDef: 'envelope',
    text: '',
    type: FieldType.ICON,
  },
];

export const UserPurchases: DisplayedColumns[] = [
  {
    matColumnDef: 'amountWithCurrency',
    text: 'user_purchases_page.table.header.amount',
    canBeSorted: false,
  },
  {
    matColumnDef: 'status',
    text: 'user_purchases_page.table.header.status',
    canBeSorted: true,
    type: FieldType.BOOLEAN,
    textBasedOnBoolean: {
      true: 'user_purchases_page.table.payment_status.succeeded',
      false: 'user_purchases_page.table.payment_status.canceled',
    },
  },
  {
    matColumnDef: 'createdDate',
    text: 'user_purchases_page.table.header.date',
    canBeSorted: true,
    type: FieldType.DATE,
    dateOption: 'medium',
  },
  {
    matColumnDef: 'last4',
    text: 'user_purchases_page.table.header.last4',
    canBeSorted: true,
    sortType: 'last4',
  },
  {
    matColumnDef: 'type',
    canBeSorted: true,
    text: 'user_purchases_page.table.header.type',
    styleData: {
      capitalize: true,
    },
  },
  {
    matColumnDef: 'brand',
    canBeSorted: true,
    text: 'user_purchases_page.table.header.brand',
    styleData: {
      capitalize: true,
    },
  },
  {
    matColumnDef: 'download',
    text: 'user_purchases_page.table.header.pdf.download',
    type: FieldType.PDF_BUTTON,
  },
];
