import { Injectable } from '@angular/core';
import { DatabaseService } from './database.service';
import { TranslateService } from '@ngx-translate/core';
import { getDomain } from 'libs/common/utils/DomainUtils';
import { combineRoutes } from 'libs/common/utils/RouteUtils';

@Injectable({
  providedIn: 'root',
})
export class MailService {
  constructor(private db: DatabaseService, private translateService: TranslateService) {}

  public sendMail(
    mailAddress: string,
    subject: string,
    text: string,
    mailTitle: string,
    mailText: string,
    buttonCtaRoute?: string,
    buttonText?: string
  ): void {
    const buttonCta =
      buttonCtaRoute && buttonText
        ? this.translateService.instant('email_template.button_cta', {
            buttonCtaRoute: combineRoutes(getDomain(), buttonCtaRoute),
            buttonText: buttonText,
          })
        : '';

    const emailTemplate = this.translateService.instant('email_template.html', {
      title: mailTitle,
      text: mailText,
      buttonCta: buttonCta,
    });

    this.db.create('mail', {
      to: mailAddress,
      message: {
        subject: subject,
        text: text,
        html: emailTemplate,
      },
    });
  }
}
