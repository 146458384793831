import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { DescriptionType } from 'src/app/interfaces/Description.enum';
import { PopupType } from 'src/app/interfaces/Popup.enum';
import { Popup } from 'src/app/interfaces/Popup.interface';
import { TitleType } from 'src/app/interfaces/Title.enum';

@Component({
  selector: 'app-bottom-popup',
  templateUrl: './bottom-popup.component.html',
  styleUrls: ['./bottom-popup.component.scss'],
})
export class BottomPopupComponent {
  public readonly PopupType = PopupType;
  public readonly TitleType = TitleType;
  public readonly DescriptionType = DescriptionType;
  public readonly ButtonType = ButtonType;

  constructor(@Inject(MAT_DIALOG_DATA) public popupInfo: Popup) {}

  public performAction(): void {
    if (this.popupInfo && this.popupInfo.buttonFunction) {
      this.popupInfo.buttonFunction();
    }
  }
}
