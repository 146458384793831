<app-modal>
  <app-button modal-button [buttonInfo]="{ type: ButtonType.LANG_SWITCH }">
    <div class="flex items-center">
      <app-icon
        class="mr-2 rounded-sm shadow-lang"
        aria-label="Language Icon"
        [icon]="getIconPath()"
        width="w-6"
      ></app-icon>
      <app-icon aria-label="Arrow Icon" icon="arrow-icon.svg" width="w-3" color="slate-400"></app-icon>
    </div>
  </app-button>

  <div modal-container class="my-3.5">
    <button class="px-4 py-1.5" *ngFor="let language of languages" (click)="changeLang(language.short)">
      <div class="flex items-center gap-3">
        <app-icon
          [className]="currentLanguage === language.short ? 'opacity-100' : 'opacity-0'"
          icon="check-icon.svg"
          color="sky-700"
          width="w-5"
        ></app-icon>

        <div
          class="w-6 overflow-hidden rounded-sm shadow-lang"
          aria-label="Language Icon"
          [inlineSVG]="'./assets/icons/' + language.icon"
        ></div>

        <p class="text-sm font-normal leading-5 text-zinc-900">{{ language.language }}</p>
      </div>
    </button>
  </div>
</app-modal>
