<div
  class="flex flex-col items-center w-full max-w-md mx-auto px-5 pb-10 lg:pb-0 lg:-translate-x-1/2 lg:-translate-y-1/2 lg:top-1/2 lg:left-1/2 lg:absolute"
  [class]="emptyAbove ? 'pt-12 lg:pt-0' : ''"
  [ngClass]="
    onlyCentar
      ? 'pb-0 -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 absolute'
      : 'lg:pb-0 lg:-translate-x-1/2 lg:-translate-y-1/2 lg:top-1/2 lg:left-1/2 lg:absolute'
  "
>
  <app-title
    *ngIf="topTitle"
    [type]="TitleType.BIG"
    [text]="topTitle"
    customClasses="text-center leading-8 mb-10"
  ></app-title>
  <div [inlineSVG]="svgPath"></div>
  <app-title [type]="TitleType.MEDIUM" [text]="title" customClasses="text-center leading-8 mt-8"></app-title>
  <app-description
    *ngIf="description"
    [type]="DescriptionType.SMALL"
    [text]="description"
    customClasses="text-center mt-4 mb-8"
  ></app-description>
  <app-button
    *ngIf="routerPath"
    class="rounded-full"
    [routerLink]="routerPath"
    [buttonInfo]="{
      customClasses: 'px-8 gap-3 flex flex-row-reverse justify-center items-center',
      text: buttonText,
      type: ButtonType.ROYAL_BLUE,
      icon: 'arrow-right.svg',
      iconColor: 'white',
      iconSize: 'w-5'
    }"
  ></app-button>
  <ng-content select="[customSwitcher]"></ng-content>
  <ng-content select="[customButton]"></ng-content>
</div>
