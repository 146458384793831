<div *ngIf="isDataLoaded && isEventActive()" class="sticky w-full top-0 z-20 bg-white shadow-sm">
  <div class="container">
    <div class="flex items-center justify-between py-4">
      <app-title [type]="TitleType.MOBILE_RESPONSIVE" text="client_page.event.title.text"></app-title>
      <div class="flex items-center gap-5">
        <div (click)="routeToCart()" class="relative cursor-pointer">
          <app-icon icon="cart-icon.svg" color="slate-400" width="w-6"></app-icon>
          <p
            *ngIf="totalQuantity()"
            class="w-3.5 h-3.5 text-small-sm text-center absolute -top-0.5 left-4 text-white text-extra-sm font-semibold rounded-full bg-orange-400"
          >
            {{ totalQuantity() }}
          </p>
        </div>
        <app-language></app-language>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="isDataLoaded && isEventActive()"
  [class]="selectedItems.length > 0 ? 'pb-32' : 'pb-10'"
  class="container mt-4"
>
  <app-description
    [type]="DescriptionType.MEDIUM"
    [text]="getInstantTranslation('client_page.event.description.text', { eventName: event.name })"
    customClasses="text-slate-400"
  ></app-description>

  <app-description
    [type]="DescriptionType.MEDIUM"
    [text]="getInstantTranslation('client_page.event.price_per_image.description.text', { imagePrice: imagesPrice })"
    customClasses="text-slate-400 mt-2"
  ></app-description>

  <div
    infiniteScroll
    [infiniteScrollDistance]="0"
    [infiniteScrollThrottle]="100"
    (scrolled)="loadMoreImages()"
    class="grid grid-cols-1 gap-2 mt-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4"
  >
    <app-image-card
      *ngFor="let image of slicedEventImages"
      [imageData]="{
        url: image.url,
        id: image.id,
        isSelected: isSelected(image.id),
        numberOfImages: getNumberOfSelectedImages(image.id),
        name: image.name
      }"
      [blockNameView]="true"
      [isRounded]="true"
      (selectionChange)="selectImage($event)"
      (numberOfImagesChange)="changeNumberOfImages($event)"
      selectionPosition="bottom"
    ></app-image-card>
  </div>
  <app-button
    *ngIf="getTotalNumber()"
    (click)="addToCartPopup()"
    class="container fixed max-w-sm transform -translate-x-1/2 rounded-full bottom-10 left-1/2"
    [buttonInfo]="{
      type: ButtonType.ROYAL_BLUE_MOBILE_RESPONSIVE,
      text: getInstantTranslation(
        getTotalNumber() > 1
          ? 'client_page.event.success.popup.add_mulltiple_image.button.text'
          : 'client_page.event.success.popup.add_single_image.button.text',
        { imagesLength: getTotalNumber() }
      ),
      customClasses: 'border border-white shadow-orderButton'
    }"
  ></app-button>
</div>

<div *ngIf="isDataLoaded && !isEventActive()">
  <app-message-container
    *ngIf="!event || !event.saleEnd"
    svgPath="./assets/icons/404-not-found.svg"
    title="client_page.event.not_active.message.title.text"
    description="client_page.event.not_active.message.description.no_sale_end.text"
    buttonText="client_page.event.not_active.message.button.text"
    routerPath="/client/event"
    [onlyCentar]="true"
  ></app-message-container>
  <app-message-container
    *ngIf="event && event.saleEnd"
    svgPath="./assets/icons/404-not-found.svg"
    title="client_page.event.not_active.message.title.text"
    description="client_page.event.not_active.message.description.sale_disabled.text"
    buttonText="client_page.event.not_active.message.button.text"
    routerPath="/client/event"
    [onlyCentar]="true"
  ></app-message-container>
</div>
