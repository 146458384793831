<div
  *ngIf="
    popupInfo &&
    (popupInfo.type === PopupType.DEFAULT ||
      popupInfo.type === PopupType.DEFAULT_SMALL ||
      popupInfo.type === PopupType.SIDE_NAV_CONTAINER ||
      popupInfo.type === PopupType.CLIENT_POPUP)
  "
  [class]="getMainContainerClasses()"
>
  <button
    *ngIf="popupInfo.type !== PopupType.DEFAULT_SMALL"
    [mat-dialog-close]="true"
    [class]="getCloseButtonClasses()"
  >
    <app-icon icon="close-icon.svg" color="zinc-600" width="w-5" alt="Close Icon"></app-icon>
  </button>

  <app-icon
    *ngIf="popupInfo.icon"
    [icon]="popupInfo.icon"
    [width]="popupInfo.type === PopupType.CLIENT_POPUP ? 'w-50' : 'w-20'"
    color="green-400"
    alt="Send Check Icon"
  ></app-icon>

  <app-title
    *ngIf="popupInfo.title"
    [ngClass]="{
    'mt-6': popupInfo.icon && popupInfo.icon !== '',
  }"
    [type]="TitleType.MEDIUM"
    [text]="popupInfo.title"
    [customClasses]="getTitleClasses()"
  ></app-title>

  <app-description
    *ngIf="popupInfo.description"
    [type]="DescriptionType.POPUP"
    [text]="popupInfo.description"
    [customClasses]="getSubtitleClasses()"
  ></app-description>

  <app-button
    class="w-full rounded-full"
    [mat-dialog-close]="true"
    *ngIf="popupInfo.button && popupInfo.buttonFunction"
    (click)="performAction()"
    [buttonInfo]="{
      text: popupInfo.button,
      type: ButtonType.ROYAL_BLUE,
      customClasses: getButtonCustomClasses(),
      icon: popupInfo.buttonIcon
    }"
  ></app-button>

  <a
    *ngFor="let item of popupInfo.headerItems"
    [routerLink]="item.href"
    [mat-dialog-close]="true"
    class="nav-item nav-item-mobile"
    [ngClass]="{ active: item.isActive }"
  >
    <p class="text">
      {{ item.text | translate }}
    </p>
  </a>

  <div *ngIf="popupInfo.type === PopupType.DEFAULT_SMALL" class="flex justify-end">
    <app-button
      class="mt-2 rounded-full"
      [mat-dialog-close]="true"
      [buttonInfo]="{ text: 'event_page.toggle_sale.popup.button.close', type: ButtonType.ROYAL_BLUE_SMALL }"
    ></app-button>
  </div>
</div>

<!-- FORM POPUP -->
<div protectDisplay *ngIf="popupInfo && popupInfo.type === PopupType.FORM" [class]="getMainContainerClasses()">
  <button *ngIf="!popupInfo.blockClosing" [mat-dialog-close]="true" [class]="getCloseButtonClasses()">
    <app-icon icon="close-icon.svg" color="zinc-600" width="w-5" alt="Close Icon"></app-icon>
  </button>

  <div class="px-6 pt-10 pb-6 sm:px-10">
    <app-title [type]="TitleType.MEDIUM" [text]="popupInfo.title" customClasses="leading-tight"></app-title>

    <app-description
      [type]="DescriptionType.SMALL"
      [text]="popupInfo.description"
      customClasses="mt-3"
    ></app-description>
  </div>

  <app-form
    *ngIf="formData"
    [formInfo]="formData"
    [filledInputs]="popupInfo.filledInputs"
    (formDataEmitter)="emitFromForm($event)"
  >
    <app-button
      *ngIf="!!popupInfo.secondButtonText"
      [buttonInfo]="{
        type: popupInfo.secondButtonType || ButtonType.RED_SMALL,
        buttonActionType: ButtonActionType.BUTTON,
        text: popupInfo.secondButtonText,
        isDisabled: isDeletionButtonDissabled
      }"
      second-btn
      (click)="secondBtnAction()"
    ></app-button>
  </app-form>
</div>
<!-- SALE POPUP -->
<div *ngIf="popupInfo && popupInfo.type === PopupType.SALE">
  <div [class]="getMainContainerClasses()">
    <div [inlineSVG]="'./assets/icons/' + popupInfo.icon" class="py-10"></div>

    <div class="px-3 pb-10 border-t-2 sm:px-10 border-sky-700">
      <app-title [type]="TitleType.MEDIUM" text="{{ popupInfo.title | translate }}" customClasses="mt-8"></app-title>

      <app-description
        [type]="DescriptionType.SMALL"
        text="{{ popupInfo.description | translate }}"
        customClasses="text-zinc-900 mt-3"
      ></app-description>
    </div>
  </div>
  <div class="px-3 py-6 bg-blue-100 sm:px-10">
    <div class="flex flex-wrap gap-1 justify-end sm:gap-2">
      <app-button
        class="rounded-full"
        [mat-dialog-close]="true"
        [buttonInfo]="{
          text: 'event_page.toggle_sale.popup.button.close',
          type: ButtonType.ROYAL_BLUE_OUTLINE,
        }"
      >
      </app-button>
      <app-button
        class="rounded-full"
        (click)="performAction()"
        [buttonInfo]="{
          text: popupInfo.button,
          type: ButtonType.ROYAL_BLUE_SMALL,
        }"
      ></app-button>
    </div>
  </div>
</div>
