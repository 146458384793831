import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { firstValueFrom } from 'rxjs';
import { ButtonActionType, ButtonType } from 'src/app/interfaces/Button.enum';
import { CouponType } from 'src/app/interfaces/Coupon.enum';
import { Coupon } from 'src/app/interfaces/Coupon.interface';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-coupon-generator',
  templateUrl: './coupon-generator.component.html',
  styleUrls: ['./coupon-generator.component.scss'],
})
export class CouponGeneratorComponent {
  public couponsInput: string = '';
  private apiUrl = environment.localApi + 'generate_qr';
  public discountType = Object.values(CouponType);
  public couponType = CouponType;
  public couponData: Coupon = {
    discountType: this.discountType[0],
    isOnlyForNewCustomers: false,
    expires: firebase.firestore.Timestamp.fromDate(new Date()),
    onlyUseOnce: false,
  };
  public readonly ButtonType = ButtonType;
  public readonly buttonActionType = ButtonActionType;

  constructor(private http: HttpClient, private db: AngularFirestore, private popupDialog: PopupDialogService) {}

  public setDate(date: Date): void {
    this.couponData.expires = firebase.firestore.Timestamp.fromDate(date);
  }

  public async generateQrCode(): Promise<void> {
    let noSpaces = this.couponsInput.replace(/\s+/g, '');
    let coupons = noSpaces.split(',');

    if (this.couponData.discountType == this.couponType.FREE_EVENT) {
      delete this.couponData.discount;
      delete this.couponData.stripePromotionId;
    } else {
      delete this.couponData.freeEvents;
    }

    if (
      coupons.length == 1 &&
      !('stripePromotionId' in this.couponData) &&
      this.couponData.discountType != this.couponType.FREE_EVENT
    ) {
      this.popupDialog.openMessageModalPopup({
        msg: 'coupon_generator.popup.failed.missing_stripe_promotion_id',
        success: false,
      });
      return;
    }

    if (coupons.length > 1 && 'stripePromotionId' in this.couponData) {
      this.popupDialog.openMessageModalPopup({
        msg: 'coupon_generator.popup.error.stripe_promotion_id',
        success: false,
      });
      return;
    }

    let couponsBatch: string[][] = [];

    for (const [index, value] of coupons.entries()) {
      let couponsBatchIndex = Math.floor(index / 500);

      if (!couponsBatch[couponsBatchIndex]) {
        couponsBatch[couponsBatchIndex] = [];
      }

      couponsBatch[couponsBatchIndex].push(value);
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    for (const batches of couponsBatch) {
      let batch = this.db.firestore.batch();
      for (const coupon of batches) {
        let ref = this.db.firestore.collection('coupons').doc(coupon);
        batch.set(ref, this.couponData);
      }

      await batch.commit().catch(err => {
        console.error(err);
      });

      try {
        const serverRes = await firstValueFrom(this.http.post(this.apiUrl, batches, { headers }));

        console.log(serverRes);
      } catch (err) {
        console.error('Error sending coupons:', err);
      }
    }
  }
}
