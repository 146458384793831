import { Component } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent {
  constructor(private language: LanguageService) {}

  public changeLanguage(lang: string): void {
    this.language.switchLanguage(lang);
  }
}
