import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { BehaviorSubject, Observable, catchError, map, of, switchMap, take } from 'rxjs';
import { FormCredentials } from '../interfaces/Form.interface';
import { User } from '../interfaces/User.interface';
import { AuthenticationService } from './authentication.service';
import { DatabaseService } from './database.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public currentUser$: Observable<User>;
  private userSubject$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  private deleteUserFunc = this.functions.httpsCallable('deleteUser');

  constructor(
    private functions: AngularFireFunctions,
    private db: DatabaseService,
    private fireAuth: AngularFireAuth,
    private auth: AuthenticationService
  ) {
    this.currentUser$ = this.fireAuth.authState.pipe(
      switchMap(user => {
        if (user?.uid) {
          return db.getById('users', user.uid).pipe(
            map((userData): User => {
              if (userData) {
                return userData as User;
              } else {
                return null;
              }
            })
          );
        } else {
          return of(null);
        }
      })
    );

    this.currentUser$.subscribe(user => this.userSubject$.next(user));
  }

  public async delete(uid: string): Promise<boolean> {
    return await new Promise(resolve => {
      this.blockUser(uid).then(res => {
        if (res) {
          this.deleteUserFunc({ uid: uid })
            .pipe(
              take(1),
              catchError(error => {
                console.error(error);
                resolve(false);
                return of(false);
              })
            )
            .subscribe(deleteResponse => {
              if (deleteResponse) {
                resolve(true);
              } else {
                resolve(false);
              }
            });
        } else {
          resolve(false);
        }
      });
    });
  }

  public async update(data: User): Promise<boolean> {
    return await this.db.update('users', data.uuid, data);
  }

  private async blockUser(uid: string): Promise<boolean> {
    return await this.db.update('users', uid, { isBlocked: true });
  }

  get currentUser(): User | null {
    return this.userSubject$.getValue();
  }

  public async authAnonymous(data: FormCredentials): Promise<boolean> {
    return await this.auth.anonymousLogin(data);
  }
}
