import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[blockNegativeNumbers]',
})
export class BlockNegativeNumbersDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const value = parseFloat(inputElement.value);

    if (value < 0) {
      inputElement.value = '';
      event.preventDefault();
    }
  }
}
