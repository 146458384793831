<div class="relative min-h-screen px-5 py-10">
  <div
    class="bg-client-registration-login top-0 right-0 w-full h-full bg-no-repeat bg-cover bg-center absolute z-0 brightness-[30%]"
  ></div>
  <div class="flex flex-col sm:max-w-176 mx-auto">
    <div class="flex items-center justify-between">
      <div class="z-10 w-32" aria-label="Logo" inlineSVG="./assets/images/photo-seller-white-logo.svg"></div>
      <app-language></app-language>
    </div>

    <div class="z-10 mt-24">
      <app-title customClasses="text-white" [type]="TitleType.BIG" text="client_page.login.title.text"></app-title>
      <app-description
        customClasses="text-white mt-4 mb-8"
        [type]="DescriptionType.BIG"
        text="client_page.login.description.text"
      ></app-description>
      <app-form [formInfo]="formData" (formDataEmitter)="emitFromForm($event)"></app-form>
    </div>
  </div>
</div>
