import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { NavigationPaths } from '../interfaces/NavigationPaths.enum';
import { Role } from '../interfaces/Role.enum';
import { LanguageService } from '../services/language.service';
import { UserService } from '../services/user.service';

export const privateGuard: CanActivateFn = (route, state): Observable<boolean> => {
  const userService: UserService = inject(UserService);
  const router: Router = inject(Router);
  const roleAccess = route.data['roleAccess'];
  const languageService: LanguageService = inject(LanguageService);

  if (route.queryParamMap.get('lang')) {
    localStorage.setItem('selectedLanguage', route.queryParamMap.get('lang'));
    languageService.switchLanguage(route.queryParamMap.get('lang'));
  }

  return userService.currentUser$.pipe(
    take(1),
    map(user => {
      const eventRefId = route.params['eventRefId'];
      let packageNumber = undefined;

      if (roleAccess === Role.USER && state.url.includes(NavigationPaths.USER_PACKAGES)) {
        const urlBreak = state.url.split('/');
        if (urlBreak.length > 3) {
          packageNumber = urlBreak[urlBreak.length - 1];
        }
      }

      if (roleAccess === Role.CLIENT && eventRefId) {
        const eventRefIdLocal = localStorage.getItem('eventId');
        if (eventRefId !== eventRefIdLocal) {
          localStorage.removeItem('tableNumber');
          localStorage.setItem('eventId', eventRefId);
        }
      }

      if (user && user.isEmailVerified) {
        if (user.role === roleAccess) {
          return true;
        } else {
          if (user.role === Role.ADMIN) {
            router.navigate([NavigationPaths.ADMIN_DASHBOARD]);
            return false;
          }

          if (user.role === Role.USER) {
            router.navigate([NavigationPaths.USER_DASHBOARD]);
            return false;
          }

          if (user.role === Role.CLIENT) {
            router.navigate([NavigationPaths.CLIENT_EVENTS]);
            return false;
          }
        }
      }

      if (roleAccess === Role.USER && packageNumber && !user) {
        router.navigate(['package', packageNumber]);
      } else if (roleAccess === Role.CLIENT) {
        router.navigate([NavigationPaths.CLIENT_LOGIN]);
      } else {
        router.navigate([NavigationPaths.LOGIN]);
      }

      return false;
    })
  );
};
