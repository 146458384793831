<div class="w-full border-b border-slate-200">
  <div class="container flex items-center py-4 lg:py-0">
    <div
      routerLink="{{ logoRouterLink }}"
      class="cursor-pointer w-36 sm:w-40"
      aria-label="Logo"
      inlineSVG="./assets/images/photo-seller-logo.svg"
    ></div>

    <div class="ml-10 hidden gap-4 lg:flex xl:ml-40.5">
      <a
        *ngFor="let item of headerItems"
        [routerLink]="item.href"
        class="nav-item"
        [ngClass]="{ active: isActive(item.href) }"
      >
        <p class="text">
          {{ item.text | translate }}
        </p>
      </a>
    </div>

    <div class="flex items-center gap-4 ml-auto">
      <app-language></app-language>

      <app-modal>
        <button
          modal-button
          class="flex items-center justify-center overflow-hidden font-medium rounded-full w-9 h-9 bg-zinc-900 font-montserrat"
        >
          <p class="text-xl text-white select-none">
            {{ (headerData?.userFirstName[0] || '').toUpperCase()
            }}{{ (headerData?.userLastName[0] || '').toUpperCase() }}
          </p>
          <!-- Update later if profile image feature updates -->
          <!-- <img *ngIf="!!headerData.userProfileImg" [src]="headerData.userProfileImg" alt="User Profile Image" /> -->
        </button>

        <div modal-container class="w-32">
          <app-button class="text-left" routerLink="profile" [buttonInfo]="{ type: ButtonType.DROPDOWN_WHITE }">
            <p class="text-left">{{ 'header.profile_button.modal.profile' | translate }}</p>
          </app-button>
          <app-button (click)="logout()" class="text-left" [buttonInfo]="{ type: ButtonType.DROPDOWN_WHITE }">
            <p class="text-left">{{ 'header.profile_button.modal.logout' | translate }}</p>
          </app-button>
        </div>
      </app-modal>

      <button (click)="openSideContainer()" class="flex flex-col justify-between w-8 h-6 lg:hidden">
        <div *ngFor="let item of [].constructor(3)" class="w-full h-1 rounded-full bg-zinc-900"></div>
      </button>
    </div>
  </div>
</div>
