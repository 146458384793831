export enum FieldType {
  EMAIL_VERIFICATION = 'emailVerification',
  ROLE = 'role',
  BUTTON = 'button',
  PDF_BUTTON = 'pdfButton',
  ICON = 'icon',
  DATE = 'date',
  BOOLEAN = 'boolean',
  MONEY = 'money',
}
