import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AdminColumns } from 'src/app/definitions/Columns.definition';
import { PageLayoutType } from 'src/app/interfaces/PageLayout.enum';
import { Role } from 'src/app/interfaces/Role.enum';
import { TableType } from 'src/app/interfaces/Table.enum';
import { EditedUser, User } from 'src/app/interfaces/User.interface';
import { DatabaseService } from 'src/app/services/database.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-admin-users-control-page',
  templateUrl: './admin-users-control-page.component.html',
  styleUrls: ['./admin-users-control-page.component.scss'],
})
export class AdminUsersControlPageComponent implements OnInit, OnDestroy {
  public users: User[];
  public usersCopy: User[];
  public currentUser: User;
  public editedUser: EditedUser;
  public PageLayoutType = PageLayoutType;
  public adminColumns = AdminColumns;
  public readonly TableType = TableType;
  public isDataLoaded: boolean = false;
  private usersSubscription: Subscription;
  private userSubscription: Subscription;

  constructor(private db: DatabaseService, private userService: UserService, private spinner: NgxSpinnerService) {
    this.editedUser = {};
  }

  ngOnInit(): void {
    this.spinner.show();
    this.userSubscription = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });

    this.usersSubscription = this.db.getAll('/users').subscribe(res => {
      this.users = res as User[];

      this.isDataLoaded = true;
      this.spinner.hide();
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.usersSubscription.unsubscribe();
  }

  public getBg(displayedUser: User): string {
    if (!displayedUser.isEmailVerified) {
      return 'bg-red-100';
    }

    if (displayedUser.role === Role.ADMIN) {
      return 'bg-blue-100';
    }

    if (displayedUser.role === Role.USER) {
      return 'bg-green-100';
    }

    return 'bg-yellow-100';
  }
}
