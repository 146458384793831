<button
  [class]="getButtonClasses() + ' ' + getAdditionalClasses() + ' ' + getCustomClasses()"
  [type]="buttonInfo.buttonActionType ? buttonInfo.buttonActionType : 'button'"
  [disabled]="buttonInfo.isDisabled ? buttonInfo.isDisabled : false"
>
  <app-icon
    *ngIf="buttonInfo.icon"
    [icon]="buttonInfo.icon"
    [color]="buttonInfo.iconColor ? buttonInfo.iconColor : 'white'"
    [width]="buttonInfo.iconSize ? buttonInfo.iconSize : 'w-5'"
  ></app-icon>
  {{ buttonInfo.text | translate }}
  <ng-content *ngIf="!buttonInfo.text"></ng-content>
</button>
