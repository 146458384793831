import { ChangeDetectorRef, Component, Inject, Optional } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginatorDefaultOptions,
  MatPaginatorIntl,
  _MatPaginatorBase,
} from '@angular/material/paginator';
import { ButtonType } from 'src/app/interfaces/Button.enum';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent extends _MatPaginatorBase<MatPaginatorDefaultOptions> {
  _formFieldAppearance?: MatFormFieldAppearance;
  public ButtonType = ButtonType;

  constructor(
    intl: MatPaginatorIntl,
    public changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(MAT_PAGINATOR_DEFAULT_OPTIONS) defaults?: MatPaginatorDefaultOptions
  ) {
    super(intl, changeDetectorRef, defaults);

    if (defaults && defaults.formFieldAppearance != null) {
      this._formFieldAppearance = defaults.formFieldAppearance;
    }
  }

  private emitValue(): void {
    this.page.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length,
    });
  }

  public changePage(page: number): void {
    this.pageIndex = page - 1;
    this.emitValue();
  }

  public isButtonDisabled(button: number | string): boolean {
    return typeof button == 'string';
  }

  public generateRange(numberOfPages: number): any[] {
    const MAX_VISIBLE_PAGES = 7;
    const currentPage = this.pageIndex + 1;
    const middlePage = Math.floor(MAX_VISIBLE_PAGES / 2);

    let startPage: number;
    let endPage: number;

    if (numberOfPages <= MAX_VISIBLE_PAGES) {
      startPage = 1;
      endPage = numberOfPages;
    } else {
      if (currentPage <= middlePage) {
        startPage = 1;
        endPage = MAX_VISIBLE_PAGES - 2; // Exclude the last two pages (will be added later)
      } else if (currentPage >= numberOfPages - middlePage) {
        startPage = numberOfPages - MAX_VISIBLE_PAGES + 3; // Exclude the first two pages (will be added later)
        endPage = numberOfPages;
      } else {
        startPage = currentPage - middlePage + 2; // Exclude the first two pages (will be added later)
        endPage = currentPage + middlePage - 2; // Exclude the last two pages (will be added later)
      }
    }

    // Create the array of page numbers.
    const pageNumbers: any[] = [];
    if (startPage > 1) {
      pageNumbers.push(1); // Always include the first page
      if (startPage > 2) {
        pageNumbers.push('...'); // Add an ellipsis after the first page
      }
    }
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    if (endPage < numberOfPages) {
      if (endPage < numberOfPages - 1) {
        pageNumbers.push('...'); // Add an ellipsis before the last page
      }
      pageNumbers.push(numberOfPages); // Always include the last page
    }

    return pageNumbers;
  }

  public getStart(): number {
    return this.pageIndex * this.pageSize + 1;
  }

  public getEnd(): number {
    return Math.min((this.pageIndex + 1) * this.pageSize, this.length);
  }

  public isVisible(): boolean {
    return this.length > this._displayedPageSizeOptions[0];
  }
}
