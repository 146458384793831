<div class="grid grid-cols-4 gap-1 mt-4 mb-10 max-xl:grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1">
  <app-image-card
    *ngFor="let image of eventImages"
    [imageData]="{
        url: image.url,
        name: getShorterFileName(image.name),
        id: image.id,
        isSelected: isSelected(image.id),
      }"
    (selectionChange)="selectImage($event)"
  ></app-image-card>
</div>
