import { Component } from '@angular/core';

@Component({
  selector: 'app-bottom-image',
  templateUrl: './bottom-image.component.html',
  styleUrls: ['./bottom-image.component.scss']
})
export class BottomImageComponent {

}
