<div *ngIf="eventInfoBlocks.length" class="p-6 border rounded-xl">
  <app-title [type]="TitleType.MEDIUM" [text]="title" [customClasses]="'mb-3 md:mb-5 text-xl'"></app-title>
  <div class="flex flex-col gap-x-20 gap-y-5 sm:flex-row">
    <div *ngFor="let eventInfoBlock of eventInfoBlocks">
      <p class="mb-1 text-slate-400">{{ eventInfoBlock?.dataTitle | translate }}</p>
      <ng-container *ngIf="isStringType(eventInfoBlock.data); else dateBlock">
        <p class="font-semibold text-zinc-900 lg:whitespace-nowrap">
          {{ eventInfoBlock.data.toString() | translate : { hoursLeft: saleHoursLeft, minutesLeft: saleMinutesLeft } }}
        </p>
      </ng-container>
      <ng-template #dateBlock>
        <p class="font-semibold text-zinc-900 lg:whitespace-nowrap">
          {{ eventInfoBlock?.data | date : eventInfoBlock.dateFormat }}
        </p>
      </ng-template>
    </div>
  </div>
</div>
