<div
  class="flex items-center gap-2 px-4 py-3 rounded max-w-108"
  [class]="msgInfo.success ? 'bg-green-100' : 'bg-red-100'"
>
  <app-icon icon="check-icon-round.svg" width="w-4" [color]="msgInfo.success ? 'green-300' : 'red-600'"></app-icon>

  <div class="flex-1 w-full">
    <p class="text-base font-normal">{{ msgInfo.msg | translate }}</p>
  </div>

  <button [mat-dialog-close]="true">
    <app-icon icon="close-icon.svg" width="w-4" [color]="msgInfo.success ? 'green-300' : 'red-600'"></app-icon>
  </button>
</div>
