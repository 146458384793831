import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { ButtonActionType, ButtonType } from 'src/app/interfaces/Button.enum';
import { CouponType } from 'src/app/interfaces/Coupon.enum';
import { Coupon } from 'src/app/interfaces/Coupon.interface';
import { Currency } from 'src/app/interfaces/Currency.enum';
import { DescriptionType } from 'src/app/interfaces/Description.enum';
import { PaymentMethod } from 'src/app/interfaces/PaymentMethod.interface';
import { Product } from 'src/app/interfaces/Product.interface';
import { UserRoutes } from 'src/app/interfaces/Routes.enum';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { User } from 'src/app/interfaces/User.interface';
import { CouponService } from 'src/app/services/coupon.service';
import { PaypalService } from 'src/app/services/paypal.service';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';
import { StripeService } from 'src/app/services/stripe.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-popup',
  templateUrl: './payment-popup.component.html',
  styleUrls: ['./payment-popup.component.scss'],
})
export class PaymentPopupComponent implements OnInit {
  [x: string]: any;

  public products$: Observable<Product[]>;
  public payments$: Observable<any[]>;
  public customers$: Observable<User[]>;
  public products: Product[] = [];
  public couponData: Coupon;
  public couponInput: string = '';

  public isLoading = false;
  public buttonType = ButtonType;
  public titleType = TitleType;
  public buttonActionType = ButtonActionType;
  public descriptionType = DescriptionType;
  public payPalConfig?: IPayPalConfig;
  public selectedCurrency: Currency = Currency.USD;
  public showSuccess: boolean;
  public showCancel: boolean;
  public showError: boolean;
  public showCouponInput: boolean = false;
  public couponType = CouponType;

  constructor(
    private dialogRef: MatDialogRef<PaymentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public popupInfo: PaymentMethod,
    private stripeService: StripeService,
    private spinner: NgxSpinnerService,
    private payPalService: PaypalService,
    private router: Router,
    private coupon: CouponService,
    private cdr: ChangeDetectorRef,
    private popupDialog: PopupDialogService
  ) {}

  ngOnInit(): void {
    this.initConfig();

    this.coupon.coupon$.subscribe(data => {
      if (data && data.discountType !== CouponType.FREE_EVENT) {
        this.couponData = data;
        this.initConfig();
        this.cdr.detectChanges();
      }
    });
  }

  public async stripeMethod(): Promise<void> {
    this.isLoading = true;
    this.spinner.show();

    try {
      await this.stripeService.initiatePayment(
        this.popupInfo.selectedPackage.prices[0].id,
        this.popupInfo.selectedPackage.id,
        () => {
          this.isLoading = false; // Set isLoading to false after payment is completed
          this.spinner.show();
        },
        this.couponData ? this.couponData.stripePromotionId : undefined
      );
    } catch (error) {
      console.error('Error initiating payment:', error);
      this.isLoading = false; // Set isLoading to false in case of an error
      this.spinner.hide();
    }
  }

  private initConfig(): void {
    let price = this.popupInfo.selectedPackage.prices[0].unit_amount / 100;
    if (this.couponData) {
      if (this.couponData.discountType == this.couponType.PERCENTAGE) {
        price = price - (price * this.couponData.discount) / 100;
      } else {
        price = price - this.couponData.discount;
      }
    }

    this.payPalConfig = {
      currency: this.selectedCurrency.toUpperCase(),
      clientId: environment.PAYPAL_CLIENT_ID,

      createOrderOnClient: data =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: this.selectedCurrency.toUpperCase(),
                value: price.toString(),
                breakdown: {
                  item_total: {
                    currency_code: this.selectedCurrency.toUpperCase(),
                    value: price.toString(),
                  },
                },
              },
              items: [
                {
                  name: this.popupInfo.selectedPackage.name,
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: this.selectedCurrency.toUpperCase(),
                    value: price.toString(),
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: 'true',
        extraQueryParams: [
          {
            name: 'disable-funding',
            value: 'credit,card,paylater,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo',
          },
        ],
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onApprove: (data, actions) => {
        // console.log('onApprove - transaction was approved, but not authorized', data, actions);
        // actions.order.get().then((details: any) => {
        //   console.log('onApprove - you can get full order details inside onApprove: ', details);
        // });
      },
      onClientAuthorization: data => {
        // console.log(
        //   'onClientAuthorization - you should probably inform your server about completed transaction at this point',
        //   data
        // );
        this.payPalService.savePayPalPayment(data, this.popupInfo.selectedPackage, () => {
          this.spinner.show();
          this.dialogRef.close();
          this.router.navigate([UserRoutes.USER, UserRoutes.PAYMENT, UserRoutes.SUCCESS]);
        });
        this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        this.dialogRef.close();
        this.router.navigate([UserRoutes.USER, UserRoutes.PAYMENT, UserRoutes.CANCELED], {
          queryParams: { code: '2' },
        });
        this.showCancel = true;
      },
      onError: err => {
        console.error('PAYMENT Error', err);
        this.dialogRef.close();
        this.router.navigate([UserRoutes.USER, UserRoutes.PAYMENT, UserRoutes.CANCELED], {
          queryParams: { code: '3' },
        });
        this.showError = true;
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        //this.resetStatus();
      },
    };
  }

  public showCouponInputField(): void {
    this.showCouponInput = true;
    this.cdr.detectChanges();
  }

  public applyCoupon(): void {
    this.spinner.show();
    this.coupon.applyCoupon(this.couponInput, true).then(res => {
      this.popupDialog.openMessageModalPopup({
        msg: res ? 'payment_method.popup.coupon_applied.success' : 'payment_method.popup.coupon_applied.failed',
        success: res,
      });
      if (!res) {
        this.showCouponInput = false;
      } else {
        this.coupon.saveCoupon(this.couponInput);
      }
      this.spinner.hide();
      this.cdr.detectChanges();
    });
  }
}
