import { Component, Input } from '@angular/core';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { DescriptionType } from 'src/app/interfaces/Description.enum';
import { TitleType } from 'src/app/interfaces/Title.enum';

@Component({
  selector: 'app-message-container',
  templateUrl: './message-container.component.html',
  styleUrls: ['./message-container.component.scss'],
})
export class MessageContainerComponent {
  @Input() svgPath: string;
  @Input() topTitle?: string;
  @Input() title: string;
  @Input() description: string;
  @Input() buttonText?: string;
  @Input() routerPath?: string;
  @Input() emptyAbove?: boolean;
  @Input() onlyCentar?: boolean;

  public TitleType = TitleType;
  public DescriptionType = DescriptionType;
  public ButtonType = ButtonType;
}
