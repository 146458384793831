<div class="relative" (click)="$event.stopPropagation()">
  <div (click)="toggle($event)">
    <ng-content select="[modal-button]"></ng-content>
  </div>

  <div
    (clickOutside)="close()"
    (click)="close()"
    *ngIf="isOpened"
    class="absolute right-0 z-50 bg-white rounded shadow-lang"
    [ngClass]="openAbove && isBottomReached() ? 'bottom-[calc(100%+4px)]' : 'top-[calc(100%+4px)]'"
  >
    <ng-content select="[modal-container]"></ng-content>
  </div>
</div>
