<div class="container pb-16">
  <app-title customClasses="py-2" [type]="TitleType.SMALL" text="admin_page.statistics_page.statistics"></app-title>

  <div
    *ngIf="adminStatisticsData.length > 2"
    class="grid items-center w-full grid-cols-3 gap-6 max-xl:grid-cols-2 max-lg:grid-cols-1 max-sm:pt-6"
  >
    <app-info-block
      *ngFor="let infoBlock of adminStatisticsData"
      [title]="infoBlock.title"
      [data]="infoBlock.data"
      [icon]="infoBlock.icon"
      [iconColor]="infoBlock.iconColor"
      [stroke]="infoBlock.stroke"
    >
    </app-info-block>
  </div>
  <div class="mt-8">
    <app-title customClasses="py-2" [type]="TitleType.SMALL" text="admin_page.statistics_page.payments"></app-title>
    <div
      *ngIf="pyamentStatisticsData.length > 2"
      class="grid items-center w-full grid-cols-3 gap-6 max-xl:grid-cols-2 max-lg:grid-cols-1 max-sm:pt-6"
    >
      <app-info-block
        *ngFor="let infoBlock of pyamentStatisticsData"
        [title]="infoBlock.title"
        [data]="infoBlock.data"
        [icon]="infoBlock.icon"
        [iconColor]="infoBlock.iconColor"
        [stroke]="infoBlock.stroke"
      >
      </app-info-block>
    </div>
  </div>

  <div class="mt-8">
    <app-title customClasses="py-2" [type]="TitleType.SMALL" text="admin_page.statistics_page.countries"></app-title>
    <div
      *ngIf="countryStatisticsArray.length > 0"
      class="grid items-center w-full grid-cols-3 gap-6 max-xl:grid-cols-2 max-lg:grid-cols-1 max-sm:pt-6"
    >
      <app-info-block
        *ngFor="let infoBlock of countryStatisticsArray"
        [title]="infoBlock.title"
        [data]="infoBlock.data"
        [icon]="infoBlock.icon"
        [iconColor]="infoBlock.iconColor"
        [stroke]="infoBlock.stroke"
      >
      </app-info-block>
    </div>
  </div>
</div>
