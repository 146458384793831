<div class="flex flex-col items-center justify-between h-screen">
  <div class="container">
    <div class="sticky top-0 z-20 flex items-center justify-between bg-white py-5">
      <app-back-button [backWithoutHistory]="true" [iconWidth]="'w-6'"></app-back-button>
      <app-title
        class="whitespace-nowrap left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 absolute"
        [type]="TitleType.MOBILE_RESPONSIVE"
        text="client_page.cart.title.text"
      ></app-title>
    </div>
    <ul *ngIf="cartItems.length && priceDetails.price">
      <li class="my-4" *ngFor="let cartItem of cartItems">
        <div class="flex justify-between min-[340px]:items-center">
          <div class="flex items-center w-full gap-4">
            <div class="w-full min-w-28 max-w-36 h-21.5 rounded-lg overflow-hidden">
              <img class="object-cover object-center w-full h-21.5" [src]="cartItem.url" alt="" />
            </div>
            <div class="flex flex-col justify-between h-21.5">
              <p class="text-sky-900 text-base">
                {{ (cartItem.numberOfImages * priceDetails.price).toFixed(2) }} {{ priceDetails.currency }}
              </p>
              <div class="flex items-center justify-between">
                <app-icon
                  (click)="decreaseQuantity(cartItem)"
                  class="p-2 border rounded-full"
                  [ngClass]="cartItem.numberOfImages === 1 ? 'opacity-50 cursor-default' : 'cursor-pointer'"
                  icon="minus-icon.svg"
                  color="sky-700"
                  width="w-5"
                ></app-icon>
                <p class="w-10 px-3 text-center text-slate-400 text-base">
                  {{ cartItem.numberOfImages }}
                </p>
                <app-icon
                  (click)="increaseQuantity(cartItem)"
                  class="p-2 border rounded-full cursor-pointer"
                  icon="plus-icon.svg"
                  color="sky-700"
                  width="w-5"
                ></app-icon>
              </div>
            </div>
          </div>
          <button (click)="removeFromCartAll(cartItem)" class="w-5 h-5 ml-auto text-zinc-600">
            <app-icon icon="close-icon.svg" color="zinc-600" width="w-5" alt="Close Icon"></app-icon>
          </button>
        </div>
      </li>
    </ul>
    <div *ngIf="!cartItems || !cartItems.length" class="flex flex-col items-center justify-center mt-20">
      <app-icon class="-translate-x-2" icon="empty-cart-icon.svg" width="w-20" color="zinc-400"></app-icon>
      <p class="mt-4 text-zinc-400">
        {{ 'client_page.cart.empty.text' | translate }}
      </p>
    </div>
  </div>
  <div
    *ngIf="priceDetails && cartItems && cartItems.length"
    class="container sticky bottom-0 z-20 flex flex-col px-5 pt-6 pb-10 bg-slate-200 rounded-t-2xl"
  >
    <div class="flex gap-4 items-center justify-between mb-6 text-sky-900 text-base">
      <p>{{ 'client_page.cart.total_price.text' | translate }} ({{ totalQuantity() }})</p>
      <p>{{ totalPrice().toFixed(2) }} {{ priceDetails.currency }}</p>
    </div>
    <app-button
      (click)="createOrder()"
      class="rounded-full"
      [buttonInfo]="{
        type: ButtonType.GREEN_MOBILE_RESPONSIVE,
        text: 'client_page.cart.button.text',
        customClasses: 'flex gap-2 flex-row-reverse items-center justify-center',
        icon: 'arrow-right.svg',
        iconColor: 'white',
        iconSize: 'w-5',
        isDisabled: cartItems.length === 0
      }"
    ></app-button>
  </div>
</div>
