<div
  *ngIf="popupInfo && popupInfo.type === PopupType.SHOPPING_CART"
  class="w-full flex flex-col px-8 py-9 bg-white rounded-t-3xl"
>
  <button [mat-dialog-close]="true" class="absolute top-6 right-6">
    <app-icon icon="close-icon.svg" color="zinc-600" width="w-5" alt="Close Icon"></app-icon>
  </button>

  <app-icon class="self-center" [icon]="popupInfo.icon" width="w-40" alt="Send Check Icon"></app-icon>

  <app-title
    *ngIf="popupInfo.title"
    [type]="TitleType.MOBILE_RESPONSIVE"
    [text]="popupInfo.title"
    customClasses="leading-tight mt-6 font-semibold"
  ></app-title>

  <app-description
    *ngIf="popupInfo.description"
    [type]="DescriptionType.MEDIUM"
    [text]="popupInfo.description"
    customClasses="mb-3 mt-2 leading-tight"
  ></app-description>

  <div class="flex justify-between mt-4">
    <app-button
      class="rounded-full"
      *ngIf="popupInfo.buttonSecond"
      [mat-dialog-close]="true"
      [buttonInfo]="{ text: popupInfo.buttonSecond, type: ButtonType.ROYAL_BLUE_OUTLINE_MOBILE_RESPONSIVE }"
    ></app-button>
    <app-button
      *ngIf="popupInfo.button && popupInfo.buttonFunction"
      class="rounded-full"
      [mat-dialog-close]="true"
      [ngClass]="popupInfo.buttonSecond ? '' : 'w-full'"
      (click)="performAction()"
      [buttonInfo]="{
        text: popupInfo.button,
        type: popupInfo.buttonSecond ? ButtonType.RED_SMALL_MOBILE_RESPONSIVE : ButtonType.ROYAL_BLUE_MOBILE_RESPONSIVE,
        customClasses: 'flex gap-2 ' + (popupInfo.buttonSecond ? '' : 'flex items-center justify-center'),
        icon: popupInfo.buttonSecond ? 'delete-icon.svg' : 'cart-icon.svg',
        iconColor: 'white',
        iconSize: 'w-5'
      }"
    >
    </app-button>
  </div>
</div>
