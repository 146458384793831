import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[protectDisplay]',
})
export class ProtectDisplayDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    const element = this.el.nativeElement;

    // Monitor changes to the style attribute of the element
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'style') {
          const styleValue = element.getAttribute('style');
          if (styleValue && styleValue.includes('display: none')) {
            // Remove the 'display: none' style to make the element visible
            const newStyleValue = styleValue.replace('display: none;', '');
            this.renderer.setAttribute(element, 'style', newStyleValue);
          }
        }
      });
    });

    observer.observe(element, { attributes: true });
  }
}
