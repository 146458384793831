import { Injectable } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataProviderService {
  private searchInput = new BehaviorSubject<string>(null);
  private dateRangeInput = new BehaviorSubject<DateRange<Date>>(null);

  constructor() {}

  public setSearchInput(search: string): void {
    this.searchInput.next(search);
  }

  public searchInputChange(): Observable<string> {
    return this.searchInput.asObservable();
  }

  public setDateRange(dateRange: DateRange<Date>): void {
    if(dateRange) {
      this.dateRangeInput.next(dateRange);
    }
  }

  public onDateRangeChange(): Observable<DateRange<Date>> {
    return this.dateRangeInput.asObservable();
  }
}
