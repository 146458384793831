import { Component, Input } from '@angular/core';
import { TitleType } from 'src/app/interfaces/Title.enum';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent {
  @Input() type: TitleType;
  @Input() text: string;
  @Input() customClasses!: string;

  public isMainHeading(): boolean {
    if (this.type !== TitleType.SMALL) {
      return true;
    }

    return false;
  }

  public getMainHeadingClasses(): string {
    if (this.type === TitleType.MOBILE_RESPONSIVE) {
      return `text-sky-900 font-montserrat text-2xl sm:text-2xl-medium ${this.customClasses ? this.customClasses : ''}`;
    }
    return `text-sky-900 ${
      this.type === TitleType.BIG ? 'text-4xl' : 'text-2xl-medium'
    } font-montserrat font-semibold ${this.customClasses ? this.customClasses : ''}`;
  }

  public getHeadingClasses(): string {
    return `text-sky-900 text-2xl font-montserrat font-semibold ${this.customClasses ? this.customClasses : ''}`;
  }
}
