import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientEventInfoForm } from 'src/app/definitions/Form.definitions';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { DescriptionType } from 'src/app/interfaces/Description.enum';
import { Form, FormCredentials } from 'src/app/interfaces/Form.interface';
import { TitleType } from 'src/app/interfaces/Title.enum';

@Component({
  selector: 'app-client-events-page',
  templateUrl: './client-events-page.component.html',
  styleUrls: ['./client-events-page.component.scss'],
})
export class ClientEventsPageComponent implements OnInit {
  public TitleType = TitleType;
  public DescriptionType = DescriptionType;
  public ButtonType = ButtonType;
  public eventRefId: string = '';
  public isButtonDisabled: boolean = true;
  public formData: Form = ClientEventInfoForm;

  constructor(private router: Router) {}

  ngOnInit(): void {
    const eventId = localStorage.getItem('eventId');
    if (eventId) {
      this.eventRefId = eventId;
    }
  }

  public validate(): void {
    if (this.eventRefId.length === 6) {
      this.isButtonDisabled = false;
    } else {
      this.isButtonDisabled = true;
    }
  }

  public emitFromForm(data: FormCredentials): void {
    if (data && 'eventRefId' in data) {
      localStorage.setItem('showPopup', 'true');
      if ('tableNumber' in data) localStorage.setItem('tableNumber', data['tableNumber']);
      localStorage.setItem('eventId', data['eventRefId']);
      this.router.navigate([`client/event/${data['eventRefId']}`]);
    }
  }
}
