import { Component, EventEmitter, Input, Output } from '@angular/core';
import { shortenFileName } from 'libs/common/utils/FileUtils';
import { Image, ImageSelection } from 'src/app/interfaces/Image.interface';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.scss'],
})
export class ImageCardComponent {
  @Input() imageData: Image;
  @Input() isSelectionBlocked?: boolean;
  @Input() canBeRemoved?: boolean;
  @Input() selectionPosition?: 'top' | 'bottom';
  @Input() isRounded?: boolean;
  @Input() blockNameView?: boolean;
  @Output() selectionChange = new EventEmitter<ImageSelection>();
  @Output() numberOfImagesChange = new EventEmitter<ImageSelection>();
  @Output() removeImage = new EventEmitter<string>();

  public toggleSelection(): void {
    this.selectionChange.emit({
      imageId: this.imageData.id,
      isSelected: !this.imageData.isSelected,
      id: this.imageData.id,
      name: this.imageData.name,
      url: this.imageData.url,
    });
  }

  public getPosition(): string {
    switch (this.selectionPosition) {
      case 'bottom':
        return 'bottom-0 right-0 flex-row-reverse gap-3 justify-start';
      default:
        return 'top-0 left-0 justify-between';
    }
  }

  public operation(event: MouseEvent, number: number): void {
    event.stopPropagation();
    if (!(number === -1 && this.imageData.numberOfImages === 1)) {
      this.numberOfImagesChange.emit({
        imageId: this.imageData.id,
        numberOfSelected: this.imageData.numberOfImages + number,
      });
    }
  }

  public handleRemove(): void {
    this.removeImage.emit(this.imageData.id);
  }

  public stopPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }

  public getShorterFileName(fileName: string): string {
    return shortenFileName(fileName);
  }
}
