import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { User } from 'src/app/interfaces/User.interface';
import { PageLayoutType } from 'src/app/interfaces/PageLayout.enum';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/services/user.service';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { DescriptionType } from 'src/app/interfaces/Description.enum';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent implements OnInit, OnDestroy {
  public readonly PageLayoutType = PageLayoutType;
  public readonly ButtonType = ButtonType;
  public readonly TitleType = TitleType;
  public readonly DescriptionType = DescriptionType;
  public user: User;
  private userSubscription?: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private auth: AuthenticationService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.userSubscription = this.userService.currentUser$.subscribe(subUser => {
      this.user = subUser;
      this.spinner.hide();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  public logout(): void {
    this.auth.logout();
  }
}
