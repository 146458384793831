import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PayPalResponse, Payment } from 'src/app/interfaces/Payment.interface';
import { DbCollections } from '../interfaces/Collections.enum';
import { Product } from '../interfaces/Product.interface';
import { DatabaseService } from './database.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class PaypalService {
  constructor(private userService: UserService, private firestore: AngularFirestore, private db: DatabaseService) {}

  public async savePayPalPayment(data: PayPalResponse, selectedPackage: Product, callback: () => void): Promise<void> {
    try {
      // TODO: Take a look at current user logic
      const currentUser = this.userService.currentUser; // Use the currentUser getter

      const convertedData = this.mapPayPalToStripe(data, selectedPackage);
      if (currentUser) {
        await this.firestore
          .collection(DbCollections.CUSTOMERS)
          .doc(currentUser.uuid)
          .collection(DbCollections.PAYMENTS)
          .doc(convertedData.id)
          .set(convertedData)
          .then(() => {
            console.log('PAYMENT SET IN FIRESTORE FOR USER: ' + currentUser.uuid);
            callback();
          })
          .catch(err => {
            console.error('ERROR DURING PAYMENT SAVING ' + err);
          });
      } else {
        console.log('ERROR');
      }
    } catch (error) {
      console.error('Error fetching user information:', error);
    }
  }

  public mapPayPalToStripe(paypalData: PayPalResponse, selectedPackage: Product): Payment {
    const purchaseUnit = paypalData.purchase_units[0];
    const capture = purchaseUnit.payments.captures[0];
    const payerName = `${paypalData.payer.name.given_name} ${paypalData.payer.name.surname}`;
    const shippingAddress = purchaseUnit.shipping.address;

    return {
      id: paypalData.id,
      amount: Math.round(parseFloat(purchaseUnit.amount.value) * 100),
      created: Math.round(Date.parse(capture.create_time) / 1000),
      charges: {
        total_count: 1,
        data: [
          {
            id: capture.id,
            amount: Math.round(parseFloat(purchaseUnit.amount.value) * 100),
            created: Date.parse(capture.create_time),
            status: paypalData.status === 'COMPLETED' ? 'succeeded' : 'failed',
            billing_details: {
              name: payerName,
              address: {
                line1: shippingAddress.address_line_1,
                city: shippingAddress.admin_area_2,
                state: shippingAddress.admin_area_1,
                postal_code: shippingAddress.postal_code,
                country: shippingAddress.country_code,
                line2: null,
              },
              email: paypalData.payer.email_address,
              phone: null,
            },
            currency: purchaseUnit.amount.currency_code,
            description: purchaseUnit.description,
            // Setting missing properties with default/null values:
            balance_transaction: null,
            metadata: {},
            livemode: false,
            destination: null,
            failure_message: null,
            fraud_details: {},
            source: null,
            amount_refunded: 0,
            refunds: {
              data: [],
              total_count: 0,
              has_more: false,
              url: '',
              object: 'list',
            },
            statement_descriptor: null,
            transfer_data: null,
            receipt_url: '',
            shipping: null,
            review: null,
            captured: true,
            calculated_statement_descriptor: '',
            refunded: false,
            outcome: {
              reason: null,
              risk_level: '',
              risk_score: 0,
              seller_message: '',
              network_status: '',
              type: '',
            },
            payment_method: '',
            order: null,
            dispute: null,
            disputed: false,
            failure_code: null,
            transfer_group: null,
            on_behalf_of: null,
            payment_method_details: {
              type: 'PayPal',
              card: {
                brand: 'PayPal',
                last4: 'N/A',
              },
            },
            amount_captured: Math.round(parseFloat(purchaseUnit.amount.value) * 100),
            source_transfer: null,
            failure_balance_transaction: null,
            receipt_number: null,
            application: null,
            receipt_email: null,
            paid: true,
            application_fee: null,
            payment_intent: '',
            invoice: null,
            statement_descriptor_suffix: null,
            application_fee_amount: 0,
            object: 'charge',
            customer: '',
          },
        ],
        has_more: false,
        object: 'list',
        url: '', // Provide an appropriate URL or value here
      },
      currency: purchaseUnit.amount.currency_code,
      description: purchaseUnit.description,
      // Setting missing properties for Payment:
      paymentAddedToUser: false,
      items: [
        {
          amount_tax: 0,
          quantity: 1,
          amount_discount: 0,
          price: {
            tax_behavior: 'unspecified',
            metadata: [],
            product: selectedPackage.id,
            livemode: false,
            created: Date.parse(capture.create_time),
            active: true,
            unit_amount_decimal: Math.round(parseFloat(purchaseUnit.amount.value) * 100).toString(),
            billing_scheme: 'per_unit',
            unit_amount: Math.round(parseFloat(purchaseUnit.amount.value) * 100),
            type: 'one_time',
            currency: purchaseUnit.amount.currency_code,
            id: selectedPackage.prices[0].id,
            object: 'price',
            recurring: null,
            transform_quantity: null,
            tiers_mode: null,
            nickname: null,
            lookup_key: null,
            custom_unit_amount: null,
          },
          amount_total: Math.round(parseFloat(purchaseUnit.amount.value) * 100),
          description: '', // TODO: Need to take a look at this
          currency: purchaseUnit.amount.currency_code,
          id: '', // TODO: Need to take a look at this
          amount_subtotal: Math.round(parseFloat(purchaseUnit.amount.value) * 100),
          object: 'item',
        },
      ],
      invoice: null,
      on_behalf_of: null,
      source: null,
      prices: {},
      confirmation_method: '',
      transfer_data: null,
      last_payment_error: null,
      object: 'payment',
      cancellation_reason: null,
      amount_capturable: 0,
      review: null,
      setup_future_usage: null,
      payment_method_options: {
        card: {
          request_three_d_secure: 'automatic',
          installments: null,
          network: null,
          mandate_options: null,
        },
      },
      statement_descriptor: null,
      receipt_email: null,
      client_secret: '',
      metadata: {},
      next_action: null,
      livemode: false,
      statement_descriptor_suffix: null,
      amount_details: {
        tip: null,
      },
      customer: '',
      application_fee_amount: 0,
      shipping: null,
      payment_method: '',
      processing: null,
      transfer_group: null,
      amount_received: Math.round(parseFloat(purchaseUnit.amount.value) * 100),
      canceled_at: null,
      capture_method: '',
      payment_method_types: [],
      application: null,
      automatic_payment_methods: null,
      latest_charge: capture.id,
      status: paypalData.status === 'COMPLETED' ? 'succeeded' : 'failed',
    };
  }
}
