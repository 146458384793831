export enum ProductIds {
  SMALL = 'prod_OJ0TgtuafY7uYy',
  MEDIUM = 'prod_OJ0U3XaTL41EEQ',
  BIG = 'prod_OJ0UKDVGT59vib',
}

export enum ProductNames {
  SMALL = 'PhotoSeller 1',
  MEDIUM = 'PhotoSeller 11',
  BIG = 'PhotoSeller 50',
}
