import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, Subscription, map, of, switchMap } from 'rxjs';
import { CartItem } from '../interfaces/CartItem.interface';
import { DbCollections } from '../interfaces/Collections.enum';
import { Order } from '../interfaces/OrderDetails.interface';
import { User } from '../interfaces/User.interface';
import { DatabaseService } from './database.service';
import { EventsService } from './events.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private ordersSubject: BehaviorSubject<Order[]> = new BehaviorSubject<Order[]>([]);
  private eventId: string = '';

  constructor(
    private userService: UserService,
    private db: DatabaseService,
    private eventsService: EventsService,
    private firestore: AngularFirestore
  ) {}

  public triggerOrdersSubscription(eventId: string): Subscription {
    return this.userService.currentUser$
      .pipe(
        switchMap(user => {
          return this.getAllOrders(user?.uuid, eventId);
        })
      )
      .subscribe((res: Order[]) => {
        res ? this.ordersSubject.next([...res]) : this.ordersSubject.next([]);
      });
  }

  // Get all Orders from DB by event
  private getAllOrders(uuid: string, eventId: string): Observable<Order[]> {
    return this.db.getAll(`${DbCollections.EVENTS}/${uuid}/userEvents/${eventId}/orders`) as Observable<Order[]>;
  }

  // Subscription for getting not completed orders
  public getOrders(): Observable<Order[]> {
    return this.ordersSubject.pipe(
      map(orders => orders.filter(order => order.hasOwnProperty('isOrderCompleted') && !order.isOrderCompleted))
    );
  }

  // Subscription for getting completed orders
  public getFinishedOrders(): Observable<Order[]> {
    return this.ordersSubject.pipe(
      map(orders => orders.filter(order => order.hasOwnProperty('isOrderCompleted') && order.isOrderCompleted))
    );
  }

  // Get one order from DB based on event
  public getOrder(uuid: string, eventRefId: string, orderId: string): Observable<Order | boolean> {
    return this.eventsService.getEvent(eventRefId).pipe(
      switchMap(event => {
        if (typeof event != 'boolean') this.eventId = event.id;
        return typeof event != 'boolean'
          ? this.db.getById<Order>(`/events/${uuid}/userEvents/${event.id}/orders`, orderId)
          : of(false);
      })
    );
  }

  // TODO: Finish this
  public async updateOrderImages(
    user: User,
    orderId: string,
    images: CartItem[],
    totalNumberOfImages: number
  ): Promise<boolean> {
    return await this.db.update(`${DbCollections.EVENTS}/${user.uuid}/userEvents/${this.eventId}/orders`, orderId, {
      images: images,
      totalNumberOfImages: totalNumberOfImages,
    });
  }

  public async updateOrderStatus(user: User, order: Order): Promise<boolean> {
    let res = true;
    res = await this.db.update(`${DbCollections.EVENTS}/${user.uuid}/userEvents/${this.eventId}/orders`, order.id, {
      isOrderCompleted: true,
    });

    if (res) {
      res = await this.db.updateFieldValue(`${DbCollections.EVENTS}/${user.uuid}/userEvents`, this.eventId, {
        totalSoldImages: order.totalNumberOfImages,
      });
    }

    if (res) {
      res = await this.db.updateFieldValue(`${DbCollections.STATISTICS}/user/${user.uuid}`, 'events', {
        numberOfSoldImages: order.totalNumberOfImages,
        numberOfFinishedOrders: 1,
      });
    }

    return res;
  }

  public async updateOrderImageCount(
    user: User,
    eventId: string,
    orderId: string,
    imageId: string,
    increment: boolean
  ): Promise<void> {
    const orderRef = this.firestore.firestore.doc(
      `${DbCollections.EVENTS}/${user.uuid}/userEvents/${eventId}/orders/${orderId}`
    );

    try {
      await this.firestore.firestore.runTransaction(async transaction => {
        const orderSnapshot = await transaction.get(orderRef);

        if (!orderSnapshot.exists) {
          throw new Error();
        }

        const order = orderSnapshot.data() as Order;

        const imageIndex = order.images.findIndex(image => image.id === imageId);

        if (imageIndex !== -1) {
          const changeValue = increment ? 1 : -1;
          order.images[imageIndex].numberOfImages = (order.images[imageIndex].numberOfImages || 0) + changeValue;

          order.images[imageIndex].numberOfImages = Math.max(order.images[imageIndex].numberOfImages, 0);

          order.totalNumberOfImages = order.images.reduce((total, image) => (total += image.numberOfImages || 0), 0);

          transaction.update(orderRef, order);
        } else {
          throw new Error();
        }
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
