import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(private analytics: AngularFireAnalytics) {}

  public logEvent(eventName: string, params?: { [key: string]: any }) {
    this.analytics.logEvent(eventName, params);
  }
}
