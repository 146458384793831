<div *ngIf="isVisible()" class="flex items-center justify-between bg-white" #paginator>
  <div class="flex justify-between flex-1 sm:hidden">
    <button
      [disabled]="_previousButtonsDisabled()"
      (click)="previousPage()"
      class="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
    >
      {{ 'pagination.button.previous' | translate }}
    </button>
    <button
      [disabled]="_nextButtonsDisabled()"
      (click)="nextPage()"
      class="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
    >
      {{ 'pagination.button.next' | translate }}
    </button>
  </div>
  <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
    <div>
      <p class="text-sm text-gray-700 lowercase">
        {{ getStart() }}
        -
        {{ getEnd() }}
        {{ 'admin_page.pagination.text.of' | translate }}
        {{ length }}
        {{ 'admin_page.pagination.text.results' | translate }}
      </p>
    </div>
    <div class="flex items-center gap-3">
      <div class="flex items-center gap-2">
        <p>{{ 'admin_page.pagination.text.items_per_page' | translate }}</p>

        <app-modal [openAbove]="true">
          <button
            modal-button
            class="flex cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-neutral-300 focus:outline-none focus:ring-2 focus:ring-sky-700 sm:text-sm sm:leading-6"
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="listbox-label"
          >
            {{ pageSize }}
            <span class="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
              <svg class="w-5 h-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path
                  fill-rule="evenodd"
                  d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </button>

          <div modal-container class="w-16">
            <app-button
              [buttonInfo]="{ type: ButtonType.DROPDOWN_WHITE }"
              (click)="_changePageSize(PageSize)"
              *ngFor="let PageSize of _displayedPageSizeOptions"
              >{{ PageSize }}</app-button
            >
          </div>
        </app-modal>
      </div>

      <nav class="inline-flex -space-x-px rounded-md shadow-sm isolate" aria-label="Pagination">
        <button
          [disabled]="_previousButtonsDisabled()"
          (click)="previousPage()"
          class="relative inline-flex items-center px-2 py-2 text-gray-400 rounded-l-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        >
          <span class="sr-only">{{ 'pagination.button.previous' | translate }}</span>
          <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path
              fill-rule="evenodd"
              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <button
          [disabled]="isButtonDisabled(number)"
          (click)="changePage(number)"
          [class]="
            number === pageIndex + 1
              ? 'z-10 bg-sky-700 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700'
              : 'text-zinc-900 ring-1 ring-inset ring-neutral-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
          "
          class="relative inline-flex items-center px-4 py-2 text-sm font-bold focus:z-20"
          *ngFor="let number of generateRange(getNumberOfPages())"
        >
          {{ number }}
        </button>

        <button
          [disabled]="_nextButtonsDisabled()"
          (click)="nextPage()"
          class="relative inline-flex items-center px-2 py-2 text-gray-400 rounded-r-md ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        >
          <span class="sr-only">{{ 'pagination.button.next' | translate }}</span>
          <svg class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path
              fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </nav>
    </div>
  </div>
</div>
