import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { DescriptionType } from 'src/app/interfaces/Description.enum';
import { InfoBlock } from 'src/app/interfaces/InfoBlock.interface';
import { PageLayoutType } from 'src/app/interfaces/PageLayout.enum';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { User } from 'src/app/interfaces/User.interface';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-packages-page',
  templateUrl: './packages-page.component.html',
  styleUrls: ['./packages-page.component.scss'],
})
export class PackagesPageComponent implements OnInit {
  public readonly PageLayoutType = PageLayoutType;
  public readonly ButtonType = ButtonType;
  public readonly TitleType = TitleType;
  public readonly DescriptionType = DescriptionType;
  public currentUser: User;
  private userSubscription?: Subscription;
  public InfoBlocks: InfoBlock[] = [];

  constructor(private userService: UserService, private cdr: ChangeDetectorRef, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.spinner.show();
    this.userSubscription = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
      this.spinner.hide();
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  public isUserActive(): boolean {
    return !!this.currentUser;
  }

  public doesUserHaveEvents(): boolean {
    if (this.currentUser && 'activeEvents' in this.currentUser && Number(this.currentUser.activeEvents) > 0) {
      return false;
    }
    return false;
  }
}
