import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/interfaces/User.interface';
import { CouponService } from 'src/app/services/coupon.service';
import { PaymentService } from 'src/app/services/payment.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-payment-success-page',
  templateUrl: './payment-success-page.component.html',
  styleUrls: ['./payment-success-page.component.scss'],
})
export class PaymentSuccessPageComponent implements OnInit {
  public isSuccess: boolean = false;
  private currentUser: User;
  private subscription: Subscription;

  constructor(
    private userService: UserService,
    private paymentService: PaymentService,
    private coupon: CouponService
  ) {}

  ngOnInit(): void {
    this.subscription = this.paymentService.isSuccess$.subscribe(success => {
      this.isSuccess = success;
      if (this.isSuccess) {
        this.coupon.checkIsCouponUsed(this.isSuccess);
        this.coupon.removeSavedCoupon();
      }
    });

    this.currentUser = this.userService.currentUser;
    this.paymentService.fetchPayments(this.currentUser);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
