import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ClientLoginForm } from 'src/app/definitions/Form.definitions';
import { DescriptionType } from 'src/app/interfaces/Description.enum';
import { Form, FormCredentials } from 'src/app/interfaces/Form.interface';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-client-login-page',
  templateUrl: './client-login-page.component.html',
  styleUrls: ['./client-login-page.component.scss'],
})
export class ClientLoginPageComponent {
  public formData: Form = ClientLoginForm;

  public readonly TitleType = TitleType;
  public readonly DescriptionType = DescriptionType;

  constructor(private userService: UserService, private router: Router) {}

  public emitFromForm(data: FormCredentials): void {
    this.userService.authAnonymous(data);
  }
}
