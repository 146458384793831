import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationPopupType } from 'src/app/interfaces/ConfirmationPopup.enum';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';

@Component({
  selector: 'app-selection-popup',
  templateUrl: './selection-popup.component.html',
  styleUrls: ['./selection-popup.component.scss'],
})
export class SelectionPopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SelectionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public numberOfSelectedItems: number,
    private popupDialogService: PopupDialogService
  ) {}

  ngOnInit(): void {
    if (!this.numberOfSelectedItems) {
      this.dialogRef.close(false);
    }
  }

  public closePopup(): void {
    this.popupDialogService
      .openConfirmationPopup({
        type: ConfirmationPopupType.DELETE,
        heading: 'user_page.event.form_popup.delete_photos.title',
        description: 'user_page.event.form_popup.delete_photos.description',
      })
      .subscribe(res => {
        // TODO: NEED TO TAKE A LOOK
        if (res) {
          this.dialogRef.close(true);
        }
      });
  }
}
