import { AuthPageLayoutType, PageLayoutType } from '../interfaces/PageLayout.enum';
import { AuthPageLayout, PageLayout } from '../interfaces/PageLayout.interface';
import { LoginForm, RegistrationForm, ClientLoginForm, PasswordResetForm } from './Form.definitions';

export const LoginPage: AuthPageLayout = {
  title: 'login_page.main_heading.page.title',
  description: 'login_page.description.page.text',
  type: AuthPageLayoutType.LOGIN,
  form: LoginForm,
};

export const PasswordReset: AuthPageLayout = {
  title: 'password_reset_page.main_heading.page.text',
  description: 'password_reset_page.description.page.text',
  type: AuthPageLayoutType.PASSWORD_RESET,
  form: PasswordResetForm,
};

export const RegistrationPage: AuthPageLayout = {
  title: 'registration_page.main_heading.page.title',
  description: 'registration_page.description.page.text',
  type: AuthPageLayoutType.REGISTRATION,
  form: RegistrationForm,
};

export const ClientLoginPage: PageLayout = {
  title: 'registration_page.main_heading.page.title',
  description: 'registration_page.description.page.text',
  type: PageLayoutType.CLIENT_LOGIN,
  form: ClientLoginForm,
};
