export const environment = {
  PAYPAL_CLIENT_ID: 'Ad4iOpDwjsp5eYtYOJqTaiE9qWGBoShMToODFvOaXKlGI7VIUWBdBNuYb9zs42gInZ-MVh-Mqqqc8WYd',
  production: false,
  local: false,
  localApi: 'http://localhost:7200/',
  baseUrl: 'https://qa.photoseller.me/',
  firebaseConfig: {
    apiKey: 'AIzaSyCxpsUYmAwxeU3q6N-jt8NU2g04sIPBFws',
    authDomain: 'photo-seller-87304.firebaseapp.com',
    databaseURL: 'https://photo-seller-87304-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'photo-seller-87304',
    storageBucket: 'photo-seller-87304.appspot.com',
    messagingSenderId: '950612631913',
    appId: '1:950612631913:web:f81aa5ded21a732f3addf8',
    measurementId: 'G-CM76WSTRQB',
  },
  company: {
    name: 'NENASAL LLC',
    address: {
      street: '1007 N Orange',
      suite: '1268',
      city: 'Wilmington',
      state: 'Delaware',
      zip: '19801',
      country: 'United States',
    },
    email: 'office@photoseller.me',
  },
  recaptcha: {
    siteKey: '6LdYqEYqAAAAAEtWl5L6V0FxaCL7389ifhs3kSID',
  },
};
