import { Overlay } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { BottomPopupComponent } from '../components/bottom-popup/bottom-popup.component';
import { ConfirmationPopupComponent } from '../components/confirmation-popup/confirmation-popup.component';
import { MessageModalComponent } from '../components/message-modal/message-modal.component';
import { PaymentPopupComponent } from '../components/payment-popup/payment-popup.component';
import { PopupWithLinkComponent } from '../components/popup-with-link/popup-with-link.component';
import { PopupComponent } from '../components/popup/popup.component';
import { SelectionPopupComponent } from '../components/selection-popup/selection-popup.component';
import { UploadPopupComponent } from '../components/upload-popup/upload-popup.component';
import { ConfirmationPopup } from '../interfaces/ConfirmationPopup.interface';
import { FormType } from '../interfaces/Form.enum';
import { MsgInfo } from '../interfaces/MessageModal.interface';
import { PaymentMethod } from '../interfaces/PaymentMethod.interface';
import { Popup } from '../interfaces/Popup.interface';
import { UploadPopup } from '../interfaces/UploadPopup.interface';

@Injectable({
  providedIn: 'root',
})
export class PopupDialogService {
  private selectionDialogRef: MatDialogRef<SelectionPopupComponent>;
  private popupWithLinkDialogRef: MatDialogRef<PopupWithLinkComponent>;

  constructor(private dialog: MatDialog, private router: Router, private overlay: Overlay) {}

  public openSideContainerPopup(data: Popup) {
    this.dialog.open(PopupComponent, {
      enterAnimationDuration: '0',
      backdropClass: 'bg-black/[.3]',
      panelClass: 'side-panel',
      position: { right: '0', top: '0' },
      height: '100vh',
      width: '100%',
      maxWidth: '280px',
      data,
    });
  }

  public openPopup(data: Popup): void {
    this.dialog.open(PopupComponent, {
      backdropClass: 'bg-black/[.5]',
      panelClass: 'popup',
      disableClose: data.form && data.form.type === FormType.EDIT_PROFILE_POPUP ? true : false,
      data,
    });
  }

  public openBottomPopup(data: Popup): void {
    this.dialog.open(BottomPopupComponent, {
      enterAnimationDuration: '0',
      backdropClass: 'bg-black/[.5]',
      panelClass: ['popup', 'bottom-popup'],
      position: { bottom: '0px' },
      data,
    });
  }

  public openPopupWithoutClosing(data: Popup): void {
    this.dialog.open(PopupComponent, {
      backdropClass: 'bg-black/[.5]',
      panelClass: 'popup',
      disableClose: true,
      data,
    });
  }

  public openConfirmationPopup(data: ConfirmationPopup): Observable<boolean> {
    const confirmationPopupRef = this.dialog.open(ConfirmationPopupComponent, {
      backdropClass: 'bg-black/[.5]',
      panelClass: 'delete-popup',
      data,
    });

    return confirmationPopupRef.afterClosed();
  }

  public openPopupWithLink(data: MsgInfo): void {
    this.popupWithLinkDialogRef = this.dialog.open(PopupWithLinkComponent, {
      disableClose: true,
      hasBackdrop: false,
      panelClass: 'popup-with-link',
      position: { bottom: '40px' },
      width: '100%',
      maxWidth: '350px',
      data,
    });

    this.popupWithLinkDialogRef.afterOpened().subscribe(() => {
      const navigationSubscription = this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.closePopupWithLink();
          navigationSubscription.unsubscribe();
        }
      });

      setTimeout(() => {
        this.closePopupWithLink();
        navigationSubscription.unsubscribe();
      }, 5000);
    });
  }

  public closePopupWithLink(): void {
    if (this.popupWithLinkDialogRef) {
      this.popupWithLinkDialogRef.close();
    }
  }

  public openMessageModalPopup(data: MsgInfo): void {
    const dialogMsgRef = this.dialog.open(MessageModalComponent, {
      disableClose: true,
      hasBackdrop: false,
      panelClass: 'message-modal',
      position: { top: '10px' },
      width: '100%',
      maxWidth: '440px',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data,
    });

    dialogMsgRef.afterOpened().subscribe(() => {
      setTimeout(() => {
        dialogMsgRef.close();
      }, 3000);
    });
  }

  public openPaymentPopup(data: PaymentMethod): void {
    this.dialog.open(PaymentPopupComponent, {
      backdropClass: 'bg-black/[.5]',
      panelClass: 'popup',
      data,
    });
  }

  public openSelectionPopup(numberOfSelectedItems: number): Observable<boolean> {
    if (numberOfSelectedItems === 0 && this.selectionDialogRef) {
      this.selectionDialogRef.close();
      return of(false);
    }

    if (this.selectionDialogRef && this.selectionDialogRef.componentInstance) {
      this.selectionDialogRef.componentInstance.numberOfSelectedItems = numberOfSelectedItems;
      return of(false);
    } else {
      this.selectionDialogRef = this.dialog.open(SelectionPopupComponent, {
        hasBackdrop: false,
        position: { bottom: '40px' },
        panelClass: 'selection-popup',
        disableClose: true,
        scrollStrategy: this.overlay.scrollStrategies.noop(),
        data: numberOfSelectedItems,
      });
    }

    return this.selectionDialogRef.afterClosed();
  }

  public openUploadPopup(data: UploadPopup): void {
    this.dialog.open(UploadPopupComponent, {
      backdropClass: 'bg-black/[.5]',
      panelClass: 'popup',
      data,
    });
  }
}
