import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { ConfirmationPopupType } from 'src/app/interfaces/ConfirmationPopup.enum';
import { Event } from 'src/app/interfaces/Event.interface';
import { UserRoutes } from 'src/app/interfaces/Routes.enum';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() eventData: Event;
  @Output() deleteEvent: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() editEvent: EventEmitter<Event> = new EventEmitter<Event>();
  public readonly ButtonType = ButtonType;

  constructor(private popupDialogService: PopupDialogService, private router: Router) {}

  public onDeleteEvent(event: Event): void {
    this.popupDialogService
      .openConfirmationPopup({
        type: ConfirmationPopupType.DELETE,
        heading: 'user_page.event.form_popup.delete_event.title',
        description: 'user_page.event.form_popup.delete_event.description',
      })
      .subscribe(res => {
        // TODO: NEED TO TAKE A LOOK
        if (res) {
          this.deleteEvent.emit(event);
        }
      });
  }

  public onCardClick(eventRefId: string): void {
    this.router.navigate([UserRoutes.USER, UserRoutes.EVENTS, eventRefId]);
  }

  public onEditEvent(event: Event): void {
    // TODO: NEED TO TAKE A LOOK
    this.editEvent.emit(event);
  }

  public convertToJSDate(data: number | { seconds?: number; nanoseconds?: number } | Date): Date {
    // If data is a Date object, simply return it
    if (data instanceof Date) {
      return data;
    }

    // If data is a number (only seconds provided)
    if (typeof data === 'number') {
      return new Date(data * 1000);
    }

    // If data has the 'seconds' property
    if (data && data.seconds) {
      return new Date(data.seconds * 1000);
    }

    // Default return (you can adapt this as needed)
    return new Date();
  }
}
