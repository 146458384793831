import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { OrdersColumns } from 'src/app/definitions/Columns.definition';
import { PaymentInfo, PaymentMap } from 'src/app/interfaces/Payment.interface';
import { TableType } from 'src/app/interfaces/Table.enum';
import { User, UserPaymentData } from 'src/app/interfaces/User.interface';
import { StripeService } from 'src/app/services/stripe.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  public readonly adminColumns = OrdersColumns;
  public readonly TableType = TableType;
  public paymentEntries: PaymentInfo[] = [];
  public usersForTable: User[] = [];
  public isDataLoaded: boolean = false;

  constructor(private stripeService: StripeService, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.spinner.show();
    this.stripeService.getAllPayments().subscribe((data: PaymentMap) => {
      this.paymentEntries = Object.entries(data)
        .map(([userId, paymentInfo]) => ({
          userId,
          user: paymentInfo.user,
          payments: paymentInfo.payments,
        }))
        .filter(entry => entry.payments.length > 0);

      this.usersForTable = this.getUsersFromPaymentEntries(this.paymentEntries);

      this.isDataLoaded = true;
      this.spinner.hide();
    });
  }

  public getUsersFromPaymentEntries(paymentEntries: PaymentInfo[]): UserPaymentData[] {
    return paymentEntries.map(entry => ({
      ...entry.user, // Spread the user properties
      payments: entry.payments,
      paymentCount: entry.payments.length,
    }));
  }
}
