<div *ngIf="isDataLoaded" class="container pb-10">
  <app-title customClasses="py-2" [type]="titleType.SMALL" text="admin_page.statistics_page.statistics"></app-title>

  <div class="grid items-center w-full grid-cols-3 gap-6 max-xl:grid-cols-2 max-lg:grid-cols-1 max-sm:pt-6">
    <app-info-block
      *ngFor="let infoBlock of infoBlocks"
      [title]="infoBlock.title"
      [data]="infoBlock.data"
      [icon]="infoBlock.icon"
      [iconColor]="infoBlock.iconColor"
      [stroke]="infoBlock.stroke"
    >
    </app-info-block>
  </div>

  <div *ngIf="events.length > 0" class="mt-10">
    <app-table
      (emitId)="getIdFromTable($event)"
      [data]="eventsTableData"
      [displayedColumns]="userStatisticsColumns"
      [type]="TableType.USER_STATISTICS"
      [currentUser]="currentUser"
      [canBeFilteredByDate]="true"
      [isRowClickEnabled]="true"
    ></app-table>
  </div>
  <p *ngIf="events.length === 0" class="mt-10 text-lg text-center text-slate-300">
    {{ 'user_statistics_page.table.no_events.text' | translate }}
  </p>
</div>
