import { Component } from '@angular/core';
import { PasswordReset } from 'src/app/definitions/PageLayout.definitions';
import { AuthPageLayout } from 'src/app/interfaces/PageLayout.interface';

@Component({
  selector: 'app-password-reset-page',
  templateUrl: './password-reset-page.component.html',
  styleUrls: ['./password-reset-page.component.scss'],
})
export class PasswordResetPageComponent {
  public passwordResetPage: AuthPageLayout = PasswordReset;
}
