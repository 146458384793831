import { Regex } from '../interfaces/Regex.interface';

export const REGEXES: Regex = {
  firstName: /^[\p{L}\u0900-\u097F' \-,.]+$/u,
  lastName: /^[\p{L}\u0900-\u097F' \-,.]+$/u,
  phoneNumber: /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
  email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()\-_=+{};:,.<>?[\]\\\|\/]{8,}$/,
  companyAddress: /^[A-Za-z0-9][a-zA-Z0-9\s.,!]*(?:['\-,.!][a-zA-Z0-9\s.,!]+)*$/,
  companyName: /^[A-Za-z0-9][a-zA-Z0-9\s.,!]*(?:['\-,.!][a-zA-Z0-9\s.,!]+)*$/,
  name: /.*/,
  number: /^\d+$/,
  currency: /^[^0-9]+$/,
  price: /^\d+(?:[.,]\d+)?(?<![\.,])$/,
  eventDate: /.*/,
  eventRefId: /^[a-z0-9]+$/,
};
