<div class="container pb-10">
  <app-collection *ngIf="events && events.length > 0" [currentUser]="currentUser" [events]="events"></app-collection>

  <p *ngIf="events.length === 0" class="text-lg text-center mt-7 text-slate-300">
    {{
      (copyEvents.length
        ? 'user_page.events.inactive_events.search.none'
        : 'user_page.events.inactive_events.none.text'
      ) | translate
    }}
  </p>
</div>
