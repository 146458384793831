<!-- <app-collection [currentUser]="currentUser"></app-collection> -->

<div
  *ngIf="isDataLoaded"
  class="container grid items-center w-full grid-cols-3 gap-6 max-xl:grid-cols-2 max-lg:grid-cols-1 max-sm:pt-6"
>
  <app-info-block
    *ngFor="let infoBlock of InfoBlocks"
    [title]="infoBlock.title"
    [data]="infoBlock.data"
    [icon]="infoBlock.icon"
    [iconColor]="infoBlock.iconColor"
    [stroke]="infoBlock.stroke"
  ></app-info-block>
</div>

<div *ngIf="isDataLoaded && !doesUserHaveEvents()" class="flex flex-col items-center max-w-md pb-40 mx-auto mt-24">
  <div inlineSVG="./assets/icons/suprise-box-icon.svg"></div>

  <app-title
    [type]="TitleType.SMALL"
    text="user_page.no_events.title"
    customClasses="px-4 md:px-11 text-center mt-8 mx-auto"
  ></app-title>

  <app-description
    [type]="DescriptionType.SMALL_DARK"
    text="user_page.no_events.description"
    customClasses="text-center mt-4 mb-8"
  ></app-description>

  <app-button
    (click)="openPopupAddEvent()"
    class="rounded-full"
    [buttonInfo]="{
      type: ButtonType.ROYAL_BLUE_ADD,
      text: 'user_page.no_events.button',
      icon: 'plus-icon.svg',
      iconColor: 'white',
      iconSize: 'w-5',
      customClasses: 'flex gap-2.5 items-center'
    }"
  ></app-button>
</div>

<app-bottom-image *ngIf="isDataLoaded && !doesUserHaveEvents()"></app-bottom-image>

<div *ngIf="isDataLoaded && doesUserHaveEvents()" class="mt-14">
  <div class="container flex flex-wrap items-center justify-between gap-2 lg:gap-4">
    <app-title [type]="TitleType.SMALL" text="user_page.header_item.events"></app-title>

    <app-icon
      (click)="toggleSearchVisibility()"
      class="block ml-auto lg:hidden"
      icon="search-icon.svg"
      color="slate-400"
      width="w-5"
    ></app-icon>

    <app-date-range-search class="lg:ml-8" (dateRangeEmitter)="dateRangeChange($event)"></app-date-range-search>

    <div class="relative items-center hidden w-64 ml-auto lg:flex">
      <app-icon
        class="absolute top-2/4 left-3.5 -translate-y-1/2"
        icon="search-icon.svg"
        color="gray-400"
        width="w-5"
      ></app-icon>
      <input
        name="search-events"
        (input)="searchEvents()"
        [(ngModel)]="searchText"
        class="w-full border border-slate-300 rounded pl-10 py-2.5 font-normal text-sm placeholder:text-slate-300"
        type="text"
        [placeholder]="'admin_page.users_table.input.search' | translate"
      />
    </div>

    <app-button
      class="hidden rounded-full lg:block"
      (click)="openPopupAddEvent()"
      [buttonInfo]="{
        type: ButtonType.ROYAL_BLUE_ADD,
        text: 'user_page.event.form_popup.button_another_event',
        icon: 'plus-icon.svg',
        iconColor: 'white',
        iconSize: 'w-5',
        customClasses: 'flex gap-2.5 items-center whitespace-nowrap'
      }"
    ></app-button>

    <app-button
      class="rounded-full lg:hidden"
      (click)="openPopupAddEvent()"
      [buttonInfo]="{
        type: ButtonType.ROYAL_BLUE_ADD_ICON,
        icon: 'plus-icon.svg',
        iconColor: 'white',
        iconSize: 'w-5',
      }"
    ></app-button>
  </div>

  <div *ngIf="isSearchVisible" class="container relative flex items-center w-full mt-2 lg:hidden">
    <app-icon
      class="absolute -translate-y-1/2 top-2/4 left-8"
      icon="search-icon.svg"
      color="slate-400"
      width="w-5"
    ></app-icon>
    <input
      name="search-events"
      (input)="searchEvents()"
      [(ngModel)]="searchText"
      class="w-full border border-slate-300 rounded pl-10 py-2.5 font-normal text-sm placeholder:text-slate-300"
      type="text"
      [placeholder]="'admin_page.users_table.input.search' | translate"
    />
  </div>

  <app-sections-guide [links]="links" (onRouteChange)="changedRoute()"></app-sections-guide>

  <router-outlet></router-outlet>
</div>
