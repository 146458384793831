import { Component } from '@angular/core';
import { LoginPage } from 'src/app/definitions/PageLayout.definitions';
import { AuthPageLayout, PageLayout } from 'src/app/interfaces/PageLayout.interface';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent {
  public loginPage: AuthPageLayout = LoginPage;

  constructor() {}
}
