<div *ngIf="isDataLoaded" class="container pb-10">
  <app-table
    *ngIf="userPurchases.length > 0"
    [data]="userPurchasesTableData"
    [displayedColumns]="userPurchasesColumns"
    [type]="tableType.USER_PURCHASES"
    [currentUser]="currentUser"
    pageTitle="user_purchases_page.table.title.text"
    (dataEmitter)="tableButtonTrigger($event)"
  ></app-table>

  <div *ngIf="userPurchases.length === 0">
    <app-message-container
      svgPath="./assets/icons/suprise-box-icon.svg"
      title="user_purchases_page.no_purchases.title"
      description="user_purchases_page.no_purchases.description"
    >
      <app-button
        customButton
        class="rounded-full"
        routerLink="/user/packages"
        [buttonInfo]="{
            customClasses:'px-8 gap-3 flex justify-center items-center',
            text: 'user_purchases_page.no_purchases.button',
            type: ButtonType.ROYAL_BLUE,
            icon: 'cart-icon.svg',
          }"
      ></app-button
    ></app-message-container>
  </div>
</div>
