<app-message-container
  svgPath="./assets/icons/404-not-found.svg"
  title="not_found_page.title.text"
  description="not_found_page.description.text"
  [buttonText]="
    user ? 'not_found_page.button.text.go_back_to_dashboard' : 'not_found_page.button.text.go_back_to_login'
  "
  routerPath="/login"
  [emptyAbove]="!user"
></app-message-container>

<app-bottom-image></app-bottom-image>
