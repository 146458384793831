import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { UserStatisticsColumns } from 'src/app/definitions/Columns.definition';
import { DbCollections } from 'src/app/interfaces/Collections.enum';
import { Event } from 'src/app/interfaces/Event.interface';
import { InfoBlock } from 'src/app/interfaces/InfoBlock.interface';
import { TableType } from 'src/app/interfaces/Table.enum';
import { EventsData } from 'src/app/interfaces/Table.interface';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { User } from 'src/app/interfaces/User.interface';
import { DatabaseService } from 'src/app/services/database.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-statistics-page',
  templateUrl: './user-statistics-page.component.html',
  styleUrls: ['./user-statistics-page.component.scss'],
})
export class UserStatisticsPageComponent implements OnInit, OnDestroy {
  public readonly titleType = TitleType;
  public readonly TableType = TableType;
  public userStatisticsColumns = UserStatisticsColumns;
  public currentUser: User;
  private userSubscription: Subscription;
  private userStatistics: Subscription;
  private userEvents: Subscription;
  public events: Event[] = [];
  public eventsTableData: EventsData[] = [];
  public infoBlocks: InfoBlock[] = [
    {
      title: 'user_statistics_page.info_blocks.orders_finished',
      icon: 'info-cart-icon.svg',
      iconColor: 'sky-700',
      data: 0,
    },
    {
      title: 'user_statistics_page.info_blocks.images_sold',
      icon: 'orange-card-icon.svg',
      iconColor: 'orange-400',
      stroke: true,
      data: 0,
    },
  ];
  public isDataLoaded: boolean = false;

  constructor(
    private userService: UserService,
    private db: DatabaseService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.userSubscription = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
      this.fetchUserStatistics();
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.userStatistics) {
      this.userStatistics.unsubscribe();
    }
    if (this.userEvents) {
      this.userEvents.unsubscribe();
    }
  }

  private fetchUserStatistics(): void {
    if (this.currentUser) {
      this.userStatistics = this.db
        .getById(`${DbCollections.STATISTICS}/user/${this.currentUser.uuid}`, 'events')
        .subscribe((res: any) => {
          this.infoBlocks[0].data = res?.numberOfFinishedOrders ? res?.numberOfFinishedOrders : 0;
          this.infoBlocks[1].data = res?.numberOfSoldImages ? res?.numberOfSoldImages : 0;
        });

      this.userEvents = this.db
        .getAll(`${DbCollections.EVENTS}/${this.currentUser.uuid}/userEvents`)
        .subscribe((events: any) => {
          this.events = events;

          this.eventsTableData = events
            .filter((event: Event) => event.totalSoldImages > 0)
            .map((event: Event) => {
              return {
                id: event.id,
                name: event.name,
                totalSoldImages: event.totalSoldImages || 0,
                earnings: `${(event.totalSoldImages * event.price).toFixed(2)} ${event.currency}`,
                eventDate: event.eventDate.toMillis(),
              };
            })
            .sort((a: EventsData, b: EventsData) => b.eventDate - a.eventDate);

          this.isDataLoaded = true;
          this.spinner.hide();
        });
    }
  }

  public getIdFromTable(eventId: string): void {
    const event = this.events.find(event => event.id === eventId);
    this.router.navigate([`/user/events/${event.refId}/orders/finished`]);
  }
}
