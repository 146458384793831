import firebase from 'firebase/compat/app';

export interface EventImage {
  id: string;
  name: string;
  url: string;
  creationDate?: Date;
}

export interface Event {
  id: string;
  name: string;
  currency: string;
  price: number;
  isActive: boolean;
  eventDate: firebase.firestore.Timestamp;
  activeDate: firebase.firestore.Timestamp;
  mainImage: string;
  refId: string;
  saleEnd?: firebase.firestore.Timestamp;
  createdAt: Date;
  totalSoldImages: number;
  image: File;
  includeWatermark?: boolean;
}

export interface EventRef {
  ref: Event;
  path: string;
}

export enum DateSearchKeys {
  EVENT_DATE = 'eventDate',
  SALE_END = 'saleEnd',
}

export enum InputSearchKeys {
  NAME = 'name',
}
