import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { WhereFilterOp } from '@angular/fire/firestore';
import firebase from 'firebase/compat/app';
import { Observable, combineLatest, first, map, of, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';
import { DbCollections } from '../interfaces/Collections.enum';
import { Event, EventRef } from '../interfaces/Event.interface';
import { Product } from '../interfaces/Product.interface';

type FirestoreData = {
  [key: string]: any;
};

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  constructor(private firestore: AngularFirestore, private storage: AngularFireStorage) {}

  private getCollection(path: string): Observable<object[]> {
    return this.firestore
      .collection(path)
      .snapshotChanges()
      .pipe(
        map((actions: any[]) =>
          actions.map(action => {
            const id = action.payload.doc.id;
            const data = action.payload.doc.data();
            const copyData = Object.assign({}, data);
            copyData.id = id;
            return copyData;
          })
        )
      );
  }

  public getAll(path: string): Observable<object[]> {
    return this.getCollection(path);
  }

  public getById<T>(path: string, id: string): Observable<T> {
    return this.firestore.collection(path).doc(id).valueChanges() as Observable<T>;
  }

  public getByDateRange(path: string, startDate: Date, endDate: Date): Observable<object[]> {
    const startTimestamp = firebase.firestore.Timestamp.fromDate(startDate);
    const endTimestamp = firebase.firestore.Timestamp.fromDate(endDate);

    const queryFn: QueryFn = ref => ref.where('eventDate', '>=', startTimestamp).where('eventDate', '<=', endTimestamp);

    return this.firestore
      .collection(path, queryFn)
      .snapshotChanges()
      .pipe(
        map((actions: any[]) =>
          actions.map(action => {
            const id = action.payload.doc.id;
            const data = action.payload.doc.data();
            return { id, ...data };
          })
        )
      );
  }

  public async createWithId(path: string, id: string, data: object): Promise<boolean> {
    if (!id || id === '') {
      return false;
    }

    try {
      await this.firestore.collection(path).doc(id).set(data);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  public async create(path: string, data: object): Promise<boolean> {
    try {
      await this.firestore.collection(path).add(data);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  public async createAndGetId(path: string, data: object): Promise<string> {
    try {
      const response = await this.firestore.collection(path).add(data);
      return response.id;
    } catch (err) {
      console.error(err);
      return '';
    }
  }

  public async update(path: string, id: string, data: object): Promise<boolean> {
    try {
      await this.firestore.collection(path).doc(id).update(data);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  public async incrementFieldValue(path: string, id: string, field: string): Promise<void> {
    const documentRef = this.firestore.collection(path).doc(id);

    const snapshot = await documentRef.get().pipe(take(1)).toPromise();

    if (snapshot.exists) {
      const data = snapshot.data() as FirestoreData;

      if (data && data[field]) {
        documentRef.update({
          [field]: firebase.firestore.FieldValue.increment(1),
        });
      } else {
        documentRef.set(
          {
            [field]: 1,
          },
          { merge: true }
        );
      }
    } else {
      documentRef.set({
        [field]: 1,
      });
    }
  }

  public async delete(path: string, id: string): Promise<boolean> {
    try {
      this.firestore.collection(path).doc(id).delete();

      return true;
    } catch (err) {
      console.error(err);

      return false;
    }
  }

  public uploadFile(file: File | string, path: string): AngularFireUploadTask {
    const storageRef = this.storage.ref(path);
    return typeof file == 'string' ? storageRef.putString(file, 'data_url') : storageRef.put(file);
  }

  public async deleteFile(downloadUrl: string): Promise<boolean> {
    return this.storage.storage
      .refFromURL(downloadUrl)
      .delete()
      .then(() => true)
      .catch(err => {
        console.error(err);
        return false;
      });
  }

  public getProducts(): Observable<Product[]> {
    return this.firestore
      .collection(DbCollections.PRODUCTS)
      .snapshotChanges()
      .pipe(
        map((actions: any[]) =>
          actions.map(action => {
            const id = action.payload.doc.id;
            const data = action.payload.doc.data();
            return { id, ...data };
          })
        ),
        switchMap(products => combineLatest(products.map(product => this.getProductWithPrices(product)))),
        map(products => products.filter(product => product.active)) // Filter out inactive products
      );
  }

  private getProductWithPrices(product: any): Observable<Product> {
    return this.firestore
      .collection(DbCollections.PRODUCTS)
      .doc(product.id)
      .collection(DbCollections.PRICES)
      .snapshotChanges() // Subscribe to changes in the 'prices' collection
      .pipe(
        map(priceSnapshots => {
          const prices = priceSnapshots.map(snapshot => {
            const id = snapshot.payload.doc.id;
            const data = snapshot.payload.doc.data();
            return { id, ...data };
          });
          return { ...product, prices };
        })
      );
  }

  public getRefrences<T>(eventRefId: string): Observable<EventRef | boolean> {
    return this.getById<T>('eventsList', eventRefId).pipe(
      first(),
      switchMap(res => {
        if (typeof res == 'object') {
          return (res && 'eventRef' in res && (res['eventRef'] as DocumentReference)).get().then(ref => {
            return {
              ref: ref.data() as Event,
              path: (res && 'eventRef' in res && (res['eventRef'] as DocumentReference)).path,
            };
          });
        } else {
          // Return a false value as an observable
          return of(false);
        }
      })
    );
  }

  public async getDbCount(
    db: string,
    key?: string,
    operator?: WhereFilterOp,
    value?: string | boolean
  ): Promise<number> {
    const collRef = this.firestore.collection(db);
    let query; // Use Query type for query

    if (key && operator && value) {
      query = await collRef.ref.where(key, operator, value).get(); // Modify the query
    } else {
      query = await collRef.ref.get();
    }

    const snapshot = query; // Use get() to fetch the data
    return snapshot.size;
  }

  public async updateFieldValue(path: string, id: string, data: { [fieldName: string]: number }): Promise<boolean> {
    try {
      const firestore = this.firestore.firestore;
      await firestore.runTransaction(async transaction => {
        const docRef = firestore.collection(path).doc(id);
        const doc = await transaction.get(docRef);

        if (!doc.exists) {
          transaction.set(docRef, {});
        }
        const updatedData: { [key: string]: number } = {};

        for (const [fieldName, newValue] of Object.entries(data)) {
          const currentValue = doc.data()?.[fieldName] || 0;
          const updatedValue = currentValue + newValue;
          updatedData[fieldName] = updatedValue;
        }

        transaction.update(docRef, updatedData);
      });

      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  public async set(path: string, id: string, data: object): Promise<boolean> {
    try {
      await this.firestore.collection(path).doc(id).set(data);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }
}
