import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IPayPalConfig } from 'ngx-paypal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, switchMap } from 'rxjs';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { Currency } from 'src/app/interfaces/Currency.enum';
import { DescriptionType } from 'src/app/interfaces/Description.enum';
import { Product } from 'src/app/interfaces/Product.interface';
import { UserRoutes } from 'src/app/interfaces/Routes.enum';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { User } from 'src/app/interfaces/User.interface';
import { DatabaseService } from 'src/app/services/database.service';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';
import { StripeService } from 'src/app/services/stripe.service';

@Component({
  selector: 'app-package-listing',
  templateUrl: './package-listing.component.html',
  styleUrls: ['./package-listing.component.scss'],
})
export class PackageListingComponent implements OnInit {
  @Input() currentUser: User;
  @Input() isAdmin: boolean;

  public isButtonDisabled: boolean;
  public selectedPackage: Product;
  public readonly ButtonType = ButtonType;
  public readonly TitleType = TitleType;
  public readonly DescriptionType = DescriptionType;

  // Neven
  [x: string]: any;

  public products$: Observable<Product[]>;
  public payments$: Observable<any[]>;
  public customers$: Observable<User[]>;
  public products: Product[] = [];

  public isLoading = false;
  public filteredProducts: Product[] = [];
  public selectedCurrency: Currency = Currency.USD; // Set the default currency to USD
  public currencies = Object.values(Currency);
  public payPalConfig?: IPayPalConfig;
  public showSuccess: boolean;
  public showCancel: boolean;
  public showError: boolean;
  public isCurrencySwitchEnabled = false;
  public isDataLoaded: boolean = false;

  private packageNumber: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private popup: PopupDialogService,
    private stripeService: StripeService,
    private db: DatabaseService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.isButtonDisabled = true;

    this.packageNumber = this.route.snapshot.paramMap.get('packageNumber');
  }

  ngOnInit(): void {
    this.spinner.show();
    this.products$ = this.db.getProducts();

    this.products$.subscribe(products => {
      this.products = this.stripeService
        .filterPricesByCurrency(products, Currency.USD)
        .sort((a, b) => a.prices[0].unit_amount - b.prices[0].unit_amount);

      if (!this.packageNumber) this.packageNumber = localStorage.getItem('packageNumber');
      if (this.packageNumber) {
        if (Number(this.packageNumber) <= products.length) {
          this.selectPackage(Number(this.packageNumber) - 1);
          localStorage.removeItem('packageNumber');
        } else {
          this.router.navigate([UserRoutes.NOT_FOUND]);
        }
      }

      this.isDataLoaded = true;
      this.spinner.hide();
    });
  }

  public selectPackage(index: number): void {
    this.selectedPackage = this.products[index];
    this.isButtonDisabled = false;
    this.cdr.detectChanges();
  }

  public async initiatePayment() {
    this.popup.openPaymentPopup({ selectedPackage: this.selectedPackage });
  }

  onCurrencyChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    this.selectedCurrency = target.value as Currency;
    this.db
      .getProducts()
      .pipe(
        switchMap(products => {
          return new Observable<Product[]>(subscriber => {
            const filteredProducts = this.stripeService.filterPricesByCurrency(products, this.selectedCurrency);
            subscriber.next(filteredProducts);
            subscriber.complete();
          });
        })
      )
      .subscribe(filteredProducts => {
        this.products = filteredProducts;
      });
  }

  public getEventLabel(countStr: string): string {
    const count = parseInt(countStr, 10);

    // Check if count is not a valid number after conversion
    if (isNaN(count)) {
      return 'Invalid count';
    }

    let eventTranslation = this.translateService.instant('general.event');
    if (count !== 1) {
      eventTranslation = this.translateService.instant('general.events');
    }

    return `${count} ${eventTranslation}`;
  }
}
