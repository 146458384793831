import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { QrCodePopupComponent } from 'src/app/components/qr-code-popup/qr-code-popup.component';
import { OrdersGuide } from 'src/app/definitions/SectionsGuide.definition';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { ConfirmationPopupType } from 'src/app/interfaces/ConfirmationPopup.enum';
import { DescriptionType } from 'src/app/interfaces/Description.enum';
import { Event, EventImage } from 'src/app/interfaces/Event.interface';
import { ImageSelection } from 'src/app/interfaces/Image.interface';
import { PageLayoutType } from 'src/app/interfaces/PageLayout.enum';
import { PopupType } from 'src/app/interfaces/Popup.enum';
import { UserRoutes } from 'src/app/interfaces/Routes.enum';
import { Link } from 'src/app/interfaces/SectionsGuide.interface';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { UploadPopup } from 'src/app/interfaces/UploadPopup.interface';
import { User } from 'src/app/interfaces/User.interface';
import { EventsService } from 'src/app/services/events.service';
import { OrdersService } from 'src/app/services/orders.service';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-event-page',
  templateUrl: './event-page.component.html',
  styleUrls: ['./event-page.component.scss'],
})
export class EventPageComponent {
  public readonly PageLayoutType = PageLayoutType;
  public readonly ButtonType = ButtonType;
  public readonly TitleType = TitleType;
  public readonly DescriptionType = DescriptionType;
  public readonly PopupType = PopupType;
  public currentUser: User;
  private userSubscription?: Subscription;
  private ordersSubscription?: Subscription;
  private eventImagesSubscription?: Subscription;
  public selectedImages: string[] = [];
  public selectedFiles: File[] = [];
  public event: Event;
  public eventRefId: string;
  public eventImages: EventImage[];
  public isMobile: boolean = false;
  public readonly links: Link[] = OrdersGuide;

  constructor(
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private popupDialog: PopupDialogService,
    private route: ActivatedRoute,
    private eventsService: EventsService,
    private dialog: MatDialog,
    private router: Router,
    private ordersService: OrdersService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.eventRefId = this.route.snapshot.paramMap.get('eventRefId');
    this.links = this.links.map(link => {
      return { ...link, href: link.href.replace(':eventRefId', this.eventRefId) };
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.resetSelection();
      }
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.userSubscription = this.userService.currentUser$.subscribe(subUser => {
      this.currentUser = subUser;
      this.getEventData();
    });

    this.breakpointObserver
      .observe([
        '(max-width: 768px)', // For small screens
      ])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    if (this.ordersSubscription) {
      this.ordersSubscription.unsubscribe();
    }
    if (this.eventImagesSubscription) {
      this.eventImagesSubscription.unsubscribe();
    }
  }

  private getEventData(): void {
    // TODO: Get Event
    this.eventsService.getEventAsObservable(this.eventRefId).subscribe(res => {
      this.spinner.hide();
      this.cdr.detectChanges();
      if (typeof res != 'boolean') {
        this.event = res;

        this.ordersSubscription = this.ordersService.triggerOrdersSubscription(res.id);

        this.eventImagesSubscription = this.eventsService.getEventImages().subscribe(images => {
          this.eventImages = images;
        });

        // Sort the eventImages array by creationDate in descending order
        // Sort the eventImages array by creationDate in descending order
        this.eventImages.sort((a: EventImage, b: EventImage) => {
          const dateA = new Date(a.creationDate);
          const dateB = new Date(b.creationDate);

          // Check if creationDate is valid and available
          if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
            return dateB.getTime() - dateA.getTime();
          }

          // If creationDate is missing or invalid, use alphanumeric comparison by image name
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          const numA = parseInt(nameA, 10);
          const numB = parseInt(nameB, 10);

          // Check if both names are numeric
          if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB;
          }

          // Handle alphanumeric comparison
          if (isNaN(numA) && isNaN(numB)) {
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          }

          // Sort numeric names before alphanumeric names
          if (!isNaN(numA)) return -1;
          if (!isNaN(numB)) return 1;

          // Default return statement
          return 0;
        });
      } else {
        this.router.navigate([UserRoutes.NOT_FOUND]);
      }
    });
  }

  public isSelected(imageId: string): boolean {
    return this.selectedImages.includes(imageId);
  }

  private resetSelection(): void {
    this.selectedImages = [];
    this.popupDialog.openSelectionPopup(this.selectedImages.length);
  }

  public selectImage(imageSelect: ImageSelection): void {
    imageSelect.isSelected
      ? this.selectedImages.push(imageSelect.imageId)
      : (this.selectedImages = this.selectedImages.filter(value => value !== imageSelect.imageId));

    this.popupDialog.openSelectionPopup(this.selectedImages.length).subscribe(res => {
      if (res) {
        this.spinner.show();
        const selectedImagesData: EventImage[] = this.eventImages.filter(item => this.selectedImages.includes(item.id));
        this.eventsService.deleteImages(this.currentUser.uuid, selectedImagesData, this.event.id).then(res => {
          this.spinner.hide();
          this.popupDialog.openMessageModalPopup({
            msg: res
              ? 'user_page.message_modal.message.delete_image_successfully'
              : 'user_page.message_modal.message.delete_image_failed',
            success: res,
          });

          this.resetSelection();
        });
      }
    });
  }

  public handleEnableSale(): void {
    // Checks if saleEnd exists in event
    // If not shows popup with message
    if (!('saleEnd' in this.event)) {
      this.popupDialog.openPopup({
        type: PopupType.SALE,
        title: 'event_page.toggle_sale.popup.title',
        description: 'event_page.toggle_sale.popup.description',
        icon: 'sale-enable-icon.svg',
        button: 'event_page.toggle_sale.popup.button.enable_sale',
        buttonFunction: () => {
          const currentTime = new Date();
          const saleEndDate = new Date(currentTime.getTime() + 24 * 60 * 60 * 1000);
          const saleEndTimestamp = firebase.firestore.Timestamp.fromMillis(saleEndDate.getTime());

          this.eventsService
            .editEvent(this.currentUser, {
              ...this.event,
              saleEnd: saleEndTimestamp,
              isActive: true,
            })
            .then(res => {
              this.handleSaleResult(res, this.event);
            })
            .catch(err => {
              console.error(err);
            });
        },
      });
    } else {
      if (this.event.isActive) {
        this.popupDialog
          .openConfirmationPopup({
            type: ConfirmationPopupType.DEFAULT,
            heading: 'event_page.toggle_sale.confirmation_popup.heading',
            description: 'event_page.toggle_sale.confirmation_popup.description',
            buttonText: 'event_page.toggle_sale.confirmation_popup.button',
          })
          .subscribe(res => {
            if (res) {
              this.eventsService
                .editEvent(this.currentUser, {
                  ...this.event,
                  isActive: false,
                })
                .then(res => {
                  this.handleSaleResult(res, this.event);
                })
                .catch(err => {
                  console.error(err);
                });
            }
          });
      } else {
        this.eventsService
          .editEvent(this.currentUser, {
            ...this.event,
            isActive: true,
          })
          .then(res => {
            this.handleSaleResult(res, this.event);
          })
          .catch(err => {
            console.error(err);
          });
      }
    }
  }

  public handleEnableWatermark(withPopupAlert?: boolean): void {
    if (withPopupAlert) {
      this.popupDialog
        .openConfirmationPopup({
          type: ConfirmationPopupType.DEFAULT,
          heading: !this.event?.includeWatermark
            ? 'event_page.toggle_watermark.title.enable_watermark'
            : 'event_page.toggle_watermark.title.disable_watermark',
          description: !this.event?.includeWatermark
            ? 'event_page.toggle_watermark.description.enable_watermark'
            : 'event_page.toggle_watermark.description.disable_watermark',
          buttonText: !this.event?.includeWatermark
            ? 'event_page.toggle_watermark.button.enable_watermark'
            : 'event_page.toggle_watermark.button.disable_watermark',
        })
        .subscribe(res => {
          if (res) {
            this.eventsService.editEvent(this.currentUser, {
              ...this.event,
              includeWatermark: !('includeWatermark' in this.event) || !this.event.includeWatermark ? true : false,
            });
          }
        });
    } else {
      this.eventsService.editEvent(this.currentUser, {
        ...this.event,
        includeWatermark: !('includeWatermark' in this.event) || !this.event.includeWatermark ? true : false,
      });
    }
  }

  private handleSaleResult(res: boolean, event: Event): void {
    this.popupDialog.openMessageModalPopup({
      msg: res
        ? event.isActive
          ? 'event_page.toggle_sale.popup.message_modal.enable_sale.success'
          : 'event_page.toggle_sale.popup.message_modal.disable_sale.success'
        : event.isActive
        ? 'event_page.toggle_sale.popup.message_modal.enable_sale.failed'
        : 'event_page.toggle_sale.popup.message_modal.disable_sale.failed',
      success: res,
    });
  }

  public onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    this.selectedFiles = [];
    for (let i = 0; i < files.length; i++) {
      this.selectedFiles.push(files[i]);
    }
  }

  public openUploadPopup(): void {
    const param: UploadPopup = {
      uuid: this.currentUser.uuid,
      eventId: this.event.id,
      eventImages: this.eventImages,
      includeWatermark: this.event.includeWatermark,
    };
    this.popupDialog.openUploadPopup(param);
  }

  public openQrCode(): void {
    this.dialog.open(QrCodePopupComponent, {
      backdropClass: 'bg-black/[.5]',
      panelClass: 'popup',
      data: { eventId: this.route.snapshot.paramMap.get('eventRefId') },
    });
  }

  public getImagesCount(count: number): string {
    return count > 1 ? `general.images` : `general.image`;
  }

  public canEventBeEnabled(): boolean {
    const currentDateMillis = firebase.firestore.Timestamp.now().toMillis();

    return !('saleEnd' in this.event) || this.event.saleEnd.toMillis() > currentDateMillis;
  }

  public getBackButtonParam(): string {
    if (this.event && this.event.hasOwnProperty('saleEnd')) {
      const currentTimestamp = firebase.firestore.Timestamp.fromDate(new Date()).toMillis();
      if (this.event.saleEnd.toMillis() > currentTimestamp) {
        return UserRoutes.EVENTS_ACTIVE;
      } else {
        return UserRoutes.EVENTS_INACTIVE;
      }
    } else {
      return '';
    }
  }
}
