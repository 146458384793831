import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserOrders } from 'src/app/definitions/Columns.definition';
import { Order } from 'src/app/interfaces/OrderDetails.interface';
import { TableType } from 'src/app/interfaces/Table.enum';
import { User } from 'src/app/interfaces/User.interface';
import { OrdersService } from 'src/app/services/orders.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-finished-orders-page',
  templateUrl: './user-finished-orders-page.component.html',
  styleUrls: ['./user-finished-orders-page.component.scss'],
})
export class UserFinishedOrdersPageComponent implements OnInit, OnDestroy {
  public readonly tableType = TableType;
  public readonly userOrdersColumns = UserOrders;
  public orders: Order[] = [];
  public currentUser: User;
  private userSubscription?: Subscription;
  private eventRefId: string;

  constructor(
    private userService: UserService,
    private ordersService: OrdersService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.eventRefId = this.route.parent.snapshot.paramMap.get('eventRefId');

    this.userSubscription = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });

    this.ordersService.getFinishedOrders().subscribe(orders => {
      this.orders = orders.map(order => {
        if (Number.isNaN(order.tableNumber)) {
          delete order.tableNumber;
        }
        return order;
      });
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  public getIdFromTable(id: string): void {
    this.router.navigate([`/user/events/${this.eventRefId}/orders/finished`, id]);
  }
}
