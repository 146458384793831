import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoutes } from 'src/app/interfaces/Routes.enum';
import { Link } from 'src/app/interfaces/SectionsGuide.interface';

@Component({
  selector: 'app-sections-guide',
  templateUrl: './sections-guide.component.html',
  styleUrls: ['./sections-guide.component.scss'],
})
export class SectionsGuideComponent {
  @Input() links: Link[] = [];
  @Output() onRouteChange: EventEmitter<void> = new EventEmitter<void>();

  constructor(private router: Router) {}

  public isActive(route: string): boolean {
    return (
      this.router.url === route ||
      // Custom
      (!this.router.url.includes(UserRoutes.FINISHED_ORDERS) &&
        route.includes(UserRoutes.ORDERS) &&
        this.router.url.includes(UserRoutes.ORDERS) &&
        this.router.url.includes(route)) ||
      (route.includes(UserRoutes.FINISHED_ORDERS) &&
        this.router.url.includes(UserRoutes.ORDERS) &&
        this.router.url.includes(route))
    );
  }

  public onRouteChangeFunc(route: string): void {
    if (!this.isActive(route)) {
      this.onRouteChange.emit();
    }
  }
}
