<div class="bg-white p-6 rounded-xl shadow-xl space-y-4 mb-10 container" *ngIf="userInfo">
  <div class="flex space-x-4 items-center">
    <div class="flex-none">
      <!-- Update later if profile image feature updates -->
      <!-- <img
        class="h-14 w-14 rounded-full"
        [src]="userInfo.profileImg ? userInfo.profileImg : 'https://via.placeholder.com/150'"
        alt="User profile image"
      /> -->
      <div class="h-14 w-14 rounded-full flex items-center justify-center overflow-hidden bg-zinc-900">
        <p class="text-xl text-white select-none">
          {{ (userInfo?.firstName[0] || '').toUpperCase() }}{{ (userInfo?.lastName[0] || '').toUpperCase() }}
        </p>
      </div>
    </div>
    <div>
      <div class="text-xl font-semibold">{{ userInfo.firstName }} {{ userInfo.lastName }}</div>
      <p class="text-gray-500">{{ userInfo.email }}</p>
    </div>
  </div>

  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
    <div class="flex items-center space-x-2">
      <span class="font-medium">{{ 'admin_page.table.orders_detail_table.user.role' | translate }}:</span>
      <span>{{ userInfo.role | uppercase }}</span>
    </div>
    <div class="flex items-center space-x-2">
      <span class="font-medium">{{ 'admin_page.table.orders_detail_table.user.phone_number' | translate }}:</span>
      <span>{{ userInfo.phoneNumber || '-' }}</span>
    </div>
    <div class="flex items-center space-x-2">
      <span class="font-medium">{{ 'admin_page.table.orders_detail_table.user.total_events' | translate }}:</span>
      <span>{{ userInfo.activeEvents }}</span>
    </div>
    <div class="flex items-center space-x-2">
      <span class="font-medium">{{ 'admin_page.table.orders_detail_table.user.is_admin' | translate }}:</span>
      <span>{{ userInfo.role === Role.ADMIN ? ('general.yes' | translate) : ('general.no' | translate) }}</span>
    </div>
    <div class="flex items-center space-x-2">
      <span class="font-medium">{{ 'admin_page.table.orders_detail_table.user.active_events' | translate }}:</span>
      <span>{{ userInfo.totalEvents }}</span>
    </div>
    <div class="flex items-center space-x-2">
      <span class="font-medium">{{ 'admin_page.table.orders_detail_table.user.email_verified' | translate }}:</span>
      <span>{{ userInfo.isEmailVerified ? ('general.yes' | translate) : ('general.no' | translate) }}</span>
    </div>
    <div class="flex items-center space-x-2">
      <span class="font-medium">{{ 'admin_page.table.orders_detail_table.user.is_blocked' | translate }}:</span>
      <span>{{ userInfo.isBlocked ? ('general.yes' | translate) : ('general.no' | translate) }}</span>
    </div>
  </div>
</div>

<div class="container">
  <app-table
    [data]="userOrders | async"
    [displayedColumns]="orderColumns"
    [type]="TableType.USER_ORDERS"
    pageTitle="admin_page.header_item.orders_details"
  ></app-table>
</div>
<div class="pb-16"></div>
