import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { EditEventForm } from 'src/app/definitions/Form.definitions';
import { Event } from 'src/app/interfaces/Event.interface';
import { PopupType } from 'src/app/interfaces/Popup.enum';
import { User } from 'src/app/interfaces/User.interface';
import { EventsService } from 'src/app/services/events.service';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss'],
})
export class CollectionComponent implements OnChanges {
  @Input() currentUser: User;
  @Input() events: Event[] = [];
  public itemsPerPage: number = 12;
  public currentPage: number = 0;
  public pageSizeOptions: number[] = [12, 32, 80];
  public displayedEvents: Event[] = [];

  constructor(private popupDialog: PopupDialogService, private eventsService: EventsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('events' in changes) {
      this.displayedEvents = this.sortAndSliceEvents(this.currentPage, this.itemsPerPage);
    }
  }

  public onPageChange(event: PageEvent): void {
    this.currentPage = event.pageIndex;
    this.itemsPerPage = event.pageSize;

    this.displayedEvents = this.sortAndSliceEvents(this.currentPage, this.itemsPerPage);
  }

  private sortAndSliceEvents(pageIndex: number, pageSize: number): Event[] {
    const startItem = pageIndex * pageSize;
    const endIndex = startItem + pageSize;
    return this.events.sort((a, b) => Number(b.eventDate) - Number(a.eventDate)).slice(startItem, endIndex);
  }

  public openPopupEditEvent(eventData: Event): void {
    this.popupDialog.openPopup({
      type: PopupType.FORM,
      title: 'user_page.event.form_popup.edit_event.title',
      form: EditEventForm,
      user: this.currentUser,
      description: 'user_page.event.form_popup.edit_event.description',
      button: 'user_page.event.form_popup.edit_event.button',
      filledInputs: eventData,
    });
  }

  public async deleteEvent(event: Event): Promise<void> {
    const res = await this.eventsService.deleteEvent(this.currentUser, event.id);

    this.popupDialog.openMessageModalPopup({
      msg: res
        ? 'user_page.message_modal.message.delete_event_successfully'
        : 'user_page.message_modal.message.delete_event_failed',
      success: res,
    });
  }
}
