import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, Observable, Subscription, firstValueFrom, of, switchMap } from 'rxjs';
import { DbCollections } from '../interfaces/Collections.enum';
import { CouponType } from '../interfaces/Coupon.enum';
import { Coupon, UsedCouponsUsedBy } from '../interfaces/Coupon.interface';
import { User } from '../interfaces/User.interface';
import { DatabaseService } from './database.service';
import { PopupDialogService } from './popup-dialog.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class CouponService implements OnDestroy {
  private couponSubject = new BehaviorSubject<Coupon>(null);
  public coupon$: Observable<Coupon> = this.couponSubject.asObservable();
  public currentUser: User;
  private userSubscription?: Subscription;
  private freeEventCouponInitialize: boolean = true;

  constructor(
    private userService: UserService,
    private db: DatabaseService,
    private popupDialog: PopupDialogService,
    private firestore: AngularFirestore
  ) {
    this.userSubscription = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  private async updateUsedCoupon(
    couponData: Coupon,
    couponName: string,
    uuid: string,
    deleteCoupon: boolean = false
  ): Promise<void> {
    if (couponData && 'id' in couponData && couponData.id) {
      const res = await firstValueFrom(
        this.db.getById<UsedCouponsUsedBy>(`${DbCollections.USED_COUPONS}/${couponData.id}/usedBy`, uuid)
      );

      if (res) {
        await this.db.update(`${DbCollections.USED_COUPONS}/${couponData.id}/usedBy`, uuid, {
          used: [...res.used, firebase.firestore.Timestamp.fromDate(new Date())],
        });
      } else {
        await this.db.createWithId(`${DbCollections.USED_COUPONS}/${couponData.id}/usedBy`, uuid, {
          userRef: this.firestore.doc(`${DbCollections.USERS}/${uuid}`).ref,
          used: [firebase.firestore.Timestamp.fromDate(new Date())],
        });
      }
    } else {
      const id = await this.db.createAndGetId(DbCollections.USED_COUPONS, couponData);
      await this.db.createWithId(`${DbCollections.USED_COUPONS}/${id}/usedBy`, uuid, {
        userRef: this.firestore.doc(`${DbCollections.USERS}/${uuid}`).ref,
        used: [firebase.firestore.Timestamp.fromDate(new Date())],
      });

      if (!deleteCoupon) {
        await this.db.update(DbCollections.COUPONS, couponName, { ...couponData, id: id });
      }
    }

    if (deleteCoupon) {
      await this.db.delete(DbCollections.COUPONS, couponName);
    }
  }

  private checkCoupon(coupon: string, isNewCustomer: boolean): Observable<Coupon> {
    return this.db.getById<Coupon>('coupons', coupon).pipe(
      switchMap(couponData => {
        if (
          !couponData ||
          couponData.expires < firebase.firestore.Timestamp.fromDate(new Date()) ||
          (couponData.isOnlyForNewCustomers && !isNewCustomer)
        ) {
          return of(null);
        } else {
          this.couponSubject.next({ name: coupon, ...couponData });
          return of({ name: coupon, ...couponData });
        }
      })
    );
  }

  private isNewCustomer(): boolean {
    return !(this.currentUser && 'activeEvents' in this.currentUser
      ? Number(this.currentUser.activeEvents) +
        ('totalEvents' in this.currentUser ? Number(this.currentUser.totalEvents) : 0)
      : 0);
  }

  private checkCouponFreeEvent(): void {
    this.coupon$.subscribe(async coupon => {
      if (this.freeEventCouponInitialize) {
        this.freeEventCouponInitialize = false;

        if (coupon && coupon.discountType == CouponType.FREE_EVENT) {
          const updatedUserData = {
            ...this.currentUser,
            activeEvents: (
              (Number(this.currentUser.activeEvents) ? Number(this.currentUser.activeEvents) : 0) +
              Number(coupon.freeEvents)
            ).toString(),
          };

          const res = await this.userService.update(updatedUserData);

          const deleteCoupon = res && coupon.onlyUseOnce;
          await this.updateUsedCoupon(coupon, coupon.name, this.currentUser.uuid, deleteCoupon);

          this.popupDialog.openMessageModalPopup({
            msg: res ? 'payment_method.popup.coupon.activated' : 'payment_method.popup.coupon.failed',
            success: res,
          });

          this.couponSubject.next(null);

          this.removeSavedCoupon();
        }
      }
    });
  }

  public async applyCoupon(coupon: string, isInputEntry: boolean = false): Promise<boolean> {
    const isNewCustomer = this.isNewCustomer();
    const couponData = await firstValueFrom(this.checkCoupon(coupon, isNewCustomer));

    if (couponData && couponData.discountType === CouponType.FREE_EVENT && !isInputEntry) this.checkCouponFreeEvent();

    if (couponData && couponData.discountType !== CouponType.FREE_EVENT && !isInputEntry)
      this.popupDialog.openMessageModalPopup({
        msg: !!couponData ? 'payment_method.popup.coupon.activated' : 'payment_method.popup.coupon.failed',
        success: !!couponData,
      });

    if (!couponData) this.removeSavedCoupon();

    return couponData && couponData.discountType !== CouponType.FREE_EVENT;
  }

  public async checkIsCouponUsed(isPaymentSuccess: boolean): Promise<void> {
    if (isPaymentSuccess) {
      const couponName = this.checkSavedCoupons();
      const isNewCustomer = this.isNewCustomer();
      const couponData = await firstValueFrom(this.checkCoupon(couponName, isNewCustomer));
      const deleteCoupon = couponData && couponData.onlyUseOnce;
      if (couponName) await this.updateUsedCoupon(couponData, couponName, this.currentUser.uuid, deleteCoupon);
    }

    return;
  }

  public saveCoupon(couponCode: string): void {
    const d = new Date();
    d.setTime(d.getTime() + 10 * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = 'couponCode' + '=' + couponCode + ';' + expires + ';path=/';
  }

  public checkSavedCoupons(): string | undefined {
    let b = document.cookie.split('; ');
    for (let e = b.length - 1; e >= 0; e--) {
      let c = b[e].split('=');
      if ('couponCode' === c[0]) return c[1];
    }
    return undefined;
  }

  public removeSavedCoupon(): void {
    document.cookie = 'couponCode' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    this.couponSubject.next(null);
  }
}
