<div [class]="getContainerClasses()">
  <app-icon-block
    *ngIf="getIcon()"
    [icon]="getIcon()"
    [width]="confirmation.type === ConfirmationPopupType.DELETE ? 'w-5' : 'w-7'"
    [color]="confirmation.type === ConfirmationPopupType.DELETE ? 'red-600' : 'sky-700'"
  ></app-icon-block>

  <app-title [type]="TitleType.MEDIUM" [text]="confirmation.heading" customClasses="leading-tight"></app-title>

  <div class="w-auto"></div>

  <div class="w-full">
    <app-description
      [type]="DescriptionType.SMALL"
      [text]="confirmation.description"
      customClasses="mt-1"
    ></app-description>

    <div
      class="flex items-center justify-end gap-2 mt-6"
      [ngClass]="{ 'flex-wrap-reverse md-xs:flex': confirmation.type === ConfirmationPopupType.DEFAULT }"
    >
      <app-button
        *ngIf="!confirmation.disableCloseButton"
        (click)="sendConfirmation(false)"
        class="rounded-full w-fit"
        [ngClass]="{ 'w-full md-xs:w-fit': confirmation.type === ConfirmationPopupType.DEFAULT }"
        [buttonInfo]="{
          text: 'admin_page.users_table.edit_user.confirmation_popup.button.cancel',
          type:
            confirmation.type === ConfirmationPopupType.DELETE ? ButtonType.RED_OUTLINE : ButtonType.ROYAL_BLUE_OUTLINE,
          customClasses: confirmation.type === ConfirmationPopupType.DEFAULT ? 'w-full md-xs:w-fit' : ''
        }"
      ></app-button>

      <app-button
        (click)="sendConfirmation(true)"
        class="rounded-full w-fit"
        [ngClass]="{ 'w-full md-xs:w-fit': confirmation.type === ConfirmationPopupType.DEFAULT }"
        [buttonInfo]="{
          text: getConfirmationText(),
          type: confirmation.type === ConfirmationPopupType.DELETE ? ButtonType.RED_SMALL : ButtonType.ROYAL_BLUE_SMALL,
          customClasses: confirmation.type === ConfirmationPopupType.DEFAULT ? 'w-full md-xs:w-fit' : ''
        }"
      ></app-button>
    </div>
  </div>
</div>
