<div class="relative px-10 py-8 bg-white max-w-108">
  <button [mat-dialog-close]="true" class="absolute self-start w-5 h-5 top-6 right-6 text-zinc-600">
    <app-icon icon="close-icon.svg" color="zinc-600" width="w-5" alt="Close Icon"></app-icon>
  </button>
  <app-title [type]="titleType.BIG" text="add_images.popup.title.text"></app-title>

  <app-description
    [type]="descriptionType.MEDIUM"
    customClasses="mt-4"
    text="add_images.popup.description.text"
  ></app-description>

  <div class="w-full mt-6">
    <div
      fileDragDrop
      [isUploadInProgress]="blockFileUpload"
      (filesChangeEmiter)="setImages($event)"
      (filesHover)="onFileHover($event)"
      class="box-border relative flex justify-center py-6 border-2 border-dashed border-slate-300 w-fu"
    >
      <div
        *ngIf="imageUploadProgress >= 0"
        class="pointer-events-none absolute h-8 w-[70%] rounded-full border-2 border-sky-700 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50"
      >
        <div class="h-full transition-all rounded-full bg-sky-700" [style.width.%]="imageUploadProgress"></div>
        <p *ngIf="numberOfImages" class="absolute -translate-x-1/2 translate-y-1/2 top-2/3 left-1/2">
          {{ imagesUploaded }}/{{ numberOfImages }}
        </p>
      </div>
      <p
        *ngIf="isFileHovered"
        class="absolute z-40 -translate-x-1/2 -translate-y-1/2 pointer-events-none top-1/2 left-1/2 text-sky-900"
      >
        {{ 'global.text.drop_image' | translate }}
      </p>
      <div
        *ngIf="blockFileUpload || isFileHovered || imageUploadProgress >= 0"
        class="absolute top-0 z-30 w-full h-full bg-blue-100 pointer-events-none"
      ></div>
      <div
        [class]="blockFileUpload ? '' : 'hidden'"
        class="absolute z-40 w-9/12 overflow-hidden -translate-x-1/2 -translate-y-1/2 bg-transparent border rounded-full h-7 top-1/2 left-1/2 border-sky-700"
      >
        <div class="h-full duration-300 ease-out transition-width bg-sky-700"></div>
      </div>
      <div class="inline-flex flex-col items-center w-full pointer-events-none">
        <app-icon
          class="inline-flex pointer-events-none"
          [icon]="fileName === '' ? 'image-upload-icon.svg' : 'image-added-icon.svg'"
          color="slate-400"
          width="w-5"
        ></app-icon>
        <app-description
          [type]="descriptionType.SMALL"
          [text]="fileName === '' ? 'user_page.event.form_popup.add_image.text' : fileName"
          customClasses="mt-2 pointer-events-none"
        ></app-description>

        <input
          multiple
          accept=".png, .jpeg, .jpg"
          type="file"
          (change)="onFileInputChange($event.target)"
          #fileInput
          class="hidden"
        />

        <app-button
          class="mt-3 rounded-full"
          (click)="fileInput.click()"
          [class]="isFileHovered ? 'pointer-events-none' : 'pointer-events-auto'"
          [buttonInfo]="{
            type: ButtonType.ROYAL_BLUE_OUTLINE_SMALL,
            text:
              fileName === ''
                ? 'user_page.event.form_popup.add_image.button_add'
                : 'user_page.event.form_popup.add_image.button_add_another'
          }"
        ></app-button>
      </div>
    </div>
  </div>
</div>
