<div class="grid grid-cols-4 gap-5 mt-5 max-xl:grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1">
  <app-card
    *ngFor="let event of displayedEvents"
    [eventData]="event"
    (editEvent)="openPopupEditEvent(event)"
    (deleteEvent)="deleteEvent(event)"
  ></app-card>
</div>
<div *ngIf="displayedEvents.length > 0" class="mt-10">
  <app-pagination
    [length]="events.length"
    [pageSize]="itemsPerPage"
    [pageIndex]="currentPage"
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChange($event)"
  ></app-pagination>
</div>
