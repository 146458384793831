import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { DatabaseService } from 'src/app/services/database.service';
import { Timestamp } from 'firebase/firestore';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  public selectedDate: Date;
  public minDate: Date;
  @Input() date: Timestamp | Date;
  @Output() emitSelectedDate = new EventEmitter<Date>();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(private db: DatabaseService) {}

  ngOnInit(): void {
    if (this.date) {
      if (!(this.date instanceof Timestamp)) {
        this.date = firebase.firestore.Timestamp.fromDate(this.date) as Timestamp;
      }
      this.selectedDate = this.date.toDate();
    } else {
      this.selectedDate = new Date();
      this.emitSelectedDate.emit(this.selectedDate);
      this.minDate = new Date();
    }
  }

  public onDateSelected(newDate: Date): void {
    this.trigger.closeMenu();
    this.selectedDate = newDate;

    this.emitSelectedDate.emit(this.selectedDate);
  }

  public getDate(): string {
    return this.selectedDate.toLocaleDateString();
  }
}
