import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() openAbove: boolean = false;
  public isOpened: boolean = false;
  private static openModals: ModalComponent[] = [];

  public toggle(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.isOpened) {
      this.close();
    } else {
      this.closeAllExcept(this);
      this.isOpened = true;
      ModalComponent.openModals.push(this);
    }
  }

  public close(): void {
    this.isOpened = false;
    ModalComponent.openModals.splice(ModalComponent.openModals.indexOf(this), 1);
  }

  private closeAllExcept(currentModal: ModalComponent): void {
    ModalComponent.openModals.map(modal => modal !== currentModal && modal.close());
  }

  public isBottomReached(offset: number = 150): boolean {
    const bottomOfViewport = window.innerHeight + window.scrollY;
    const documentHeight = document.documentElement.scrollHeight;

    return bottomOfViewport >= documentHeight - offset;
  }
}
