import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, catchError, forkJoin, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DbCollections } from '../interfaces/Collections.enum';
import { Coupon } from '../interfaces/Coupon.interface';
import { Customer } from '../interfaces/Customer.interface';
import { Payment, PaymentMap } from '../interfaces/Payment.interface';
import { Product } from '../interfaces/Product.interface';
import { UserRoutes } from '../interfaces/Routes.enum';
import { User } from '../interfaces/User.interface';
import { CouponService } from './coupon.service';
import { DatabaseService } from './database.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private couponData: Coupon;

  constructor(
    private userService: UserService,
    private firestore: AngularFirestore,
    private db: DatabaseService,
    private coupon: CouponService
  ) {}

  public async initiatePayment(
    priceId: string,
    productId: string,
    callback: () => void,
    stripePromotionId?: string
  ): Promise<void> {
    try {
      const currentUser = this.userService.currentUser; // Use the currentUser getter

      if (currentUser) {
        const docRef = await this.firestore
          .collection(DbCollections.CUSTOMERS)
          .doc(currentUser.uuid)
          .collection(DbCollections.CHECKOUT_SESSIONS)
          .add({
            client: 'web',
            mode: 'payment',
            price: priceId,
            success_url: `${environment.baseUrl}${UserRoutes.USER}/${UserRoutes.PAYMENT}/${UserRoutes.SUCCESS}`,
            cancel_url: `${environment.baseUrl}${UserRoutes.USER}/${UserRoutes.PAYMENT}/${UserRoutes.CANCELED}?code=2`,
            promotion_code: stripePromotionId,
          });

        const sessionId = docRef.id;

        await docRef.update({
          success_url: environment.baseUrl + '/success?id=' + sessionId,
        });

        docRef.onSnapshot(snap => {
          const { error, url } = snap.data();

          if (error) {
            console.log('ERROR', error);
          }

          if (url) {
            window.location.assign(url);
            callback();
          }
        });
      }
    } catch (error) {
      console.error('Error fetching user information:', error);
    }
  }

  public getPaymentsForUser(userId: string): Observable<Payment[]> {
    if (userId) {
      const customersCollectionRef = this.firestore
        .collection<Customer>(DbCollections.CUSTOMERS)
        .doc(userId)
        .collection(DbCollections.PAYMENTS);

      return (customersCollectionRef.valueChanges() as Observable<Payment[]>).pipe(
        catchError(error => {
          console.error('Error fetching payments for user:', userId, error);
          return of([]); // Return an observable that emits an empty array
        })
      );
    } else {
      console.error('Error: No user ID provided.');
      return of([]); // Return an observable that emits an empty array if there's no userId
    }
  }

  public getAllPayments(): Observable<{ [userId: string]: { user: User; payments: any[] } }> {
    return this.firestore
      .collection(DbCollections.USERS)
      .snapshotChanges()
      .pipe(
        switchMap(snapshots => {
          const users = snapshots.map(snapshot => {
            const data = snapshot.payload.doc.data() as User;
            const id = snapshot.payload.doc.id;
            return { id, ...data };
          });

          const paymentObservables = users.map(user => {
            return this.getPaymentsForUser(user.id).pipe(
              take(1),
              map(payments => ({
                userId: user.id,
                user: { id: user.id, ...user },
                payments: payments,
              }))
            );
          });

          return forkJoin(paymentObservables);
        }),
        map(arrayOfUserPayments => {
          return arrayOfUserPayments.reduce<PaymentMap>((acc, curr) => {
            acc[curr.userId] = {
              user: curr.user,
              payments: curr.payments,
            };
            return acc;
          }, {});
        }),
        catchError(error => {
          console.error('Error fetching payments for all users:', error);
          return of({});
        })
      );
  }

  public filterPricesByCurrency(products: Product[], currency: string): Product[] {
    return products.map(product => {
      const filteredPrices = product.prices.filter(price => price.currency === currency && price.active);
      return { ...product, prices: filteredPrices };
    });
  }

  private updateUserEvents(userId: string, priceId: string): Observable<void> {
    // Get the user document reference
    const userDocRef = this.firestore.collection<User>(DbCollections.USERS).doc(userId);

    return userDocRef.get().pipe(
      map(userDoc => {
        const currentEvents = userDoc.data()?.activeEvents || 0; // If events attribute doesn't exist, default to 0
        return { userDocRef, currentEvents };
      }),
      switchMap(({ userDocRef, currentEvents }) => {
        // Get the price metadata and extract the event value
        return this.firestore
          .collection(DbCollections.PRODUCTS)
          .doc(priceId)
          .get()
          .pipe(
            map(priceDoc => {
              const productData = priceDoc.data() as Product; // Use the ProductData interface to define the type
              const metadata = productData?.metadata;
              const eventValue = metadata?.['event'] || 0; // If event metadata doesn't exist, default to 0
              return { userDocRef, newEvents: currentEvents + eventValue };
            })
          );
      }),
      switchMap(async ({ userDocRef, newEvents }) => {
        // Update the user document with the new events value
        await userDocRef.update({ activeEvents: newEvents });
        console.log('User events updated successfully!');
      })
    );
  }
}
