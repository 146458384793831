<button
  type="button"
  class="border box-border min-w-full w-auto border-neutral-300 p-2.5 text-sm shadow-input rounded font-normal text-zinc-900 placeholder:text-neutral-400"
  mat-icon-button
  [matMenuTriggerFor]="appMenu"
>
  <div class="flex items-center gap-3">
    <app-icon icon="calendar-icon.svg" color="slate-400" width="w-5"></app-icon>
    <p>{{ getDate() }}</p>
  </div>
</button>
<mat-menu #appMenu="matMenu">
  <mat-calendar
    [minDate]="minDate"
    (click)="$event.stopPropagation()"
    [selected]="selectedDate"
    (selectedChange)="onDateSelected($event)"
  ></mat-calendar>
</mat-menu>
