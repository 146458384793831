// USER ROUTES
export enum UserRoutes {
  PAYMENT = 'payment',
  CANCELED = 'canceled',
  SUCCESS = 'success',
  NOT_FOUND = 'page-not-found',
  USER = 'user',
  EVENTS = 'events',
  EVENTS_ACTIVE = 'active',
  EVENTS_INACTIVE = 'inactive',
  EVENT_DETAIL = 'events/:eventRefId',
  EVENT_INFORMATION = 'information',
  PACKAGES = 'packages',
  PACKAGES_SELECT = 'packages/:packageNumber',
  PURCHASES = 'purchases',
  PROFILE = 'profile',
  STATISTICS = 'statistics',
  ORDERS = 'orders',
  FINISHED_ORDERS = 'orders/finished',
  ORDER_DETAILS = 'orders/:orderId',
  ORDER_FINISHED_DETAILS = 'orders/finished/:orderId',
}

// ADMIN ROUTES
export enum AdminRoutes {
  ADMIN = 'admin',
  DASHBOARD = '', // Default route for 'admin'
  USERS = 'users',
  PACKAGES = 'packages',
  ORDERS = 'orders',
  ORDER_DETAIL = 'orders/:userId', // This is a dynamic route. Be cautious when using it directly.
  COUPON_GENERATOR = 'coupon-generator',
}

export enum ClientRoutes {
  CLIENT = 'client',
  LOGIN = 'login',
  EVENTS = 'event',
  EVENT_DETAIL = 'event/:eventRefId',
  CART = 'cart',
}

// PUBLIC ROUTES
export enum PublicUserRoutes {
  LOGIN = 'login',
  REGISTRATION = 'registration',
  PASSWORD_RESET = 'password-reset',
  PACKAGE_SELECTION = 'package/:packageNumber',
  COUPON_SELECTION = 'coupon/:couponCode',
}

export enum PublicClientRoutes {
  LOGIN = 'login',
  PASSWORD_RESET = 'password-reset',
  REGISTRATION = 'registration',
}
