<h2>Test APP Prod</h2>
<div>{{ 'test' | translate }}</div>
<div>
  <div>Set lang</div>
  <button (click)="changeLanguage('en')">en</button>
  <button (click)="changeLanguage('de')">de</button>
  <button (click)="changeLanguage('sr')">sr</button>
  <button (click)="changeLanguage('es')">es</button>
  <button (click)="changeLanguage('pt')">pt</button>
</div>
