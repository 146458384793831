<div class="container">
  <div class="flex flex-col w-full gap-2 mt-4 border-b md:mt-0 md:gap-4 md:flex-row border-b-slate-200">
    <a *ngFor="let link of links" (click)="onRouteChangeFunc('/' + link.href)" [routerLink]="'/' + link.href">
      <div
        class="px-3 py-2 border-b-2 md:py-4"
        [class]="isActive('/' + link.href) ? 'border-sky-700' : 'border-transparent'"
      >
        <p [class]="isActive('/' + link.href) ? 'text-sky-700' : 'text-gray-400'">{{ link.text | translate }}</p>
      </div>
    </a>
  </div>
</div>
