import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdminProfileForm } from 'src/app/definitions/Form.definitions';
import { DescriptionType } from 'src/app/interfaces/Description.enum';
import { FormType } from 'src/app/interfaces/Form.enum';
import { Form, FormCredentials } from 'src/app/interfaces/Form.interface';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { User } from 'src/app/interfaces/User.interface';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-admin-profile-page',
  templateUrl: './admin-profile-page.component.html',
  styleUrls: ['./admin-profile-page.component.scss'],
})
export class AdminProfilePageComponent implements OnInit, OnDestroy {
  public readonly FormType = FormType;
  public readonly TitleType = TitleType;
  public readonly DescriptionType = DescriptionType;
  public currentUser: User;
  private userSubscription?: Subscription;
  public formData: Form = AdminProfileForm;

  constructor(
    private userService: UserService,
    private popupDialogService: PopupDialogService,
    private auth: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  public emitFromForm(data: FormCredentials) {
    this.userService.update(data as User).then(res => {
      if (res) {
        this.popupDialogService.openMessageModalPopup({
          msg: res ? 'user_profile_page.message_modal.successful.text' : 'user_profile_page.message_modal.failed.text',
          success: res,
        });
      }
    });
  }

  public handleResetPassword(): void {
    if (this.currentUser && this.currentUser.email) {
      this.auth.passwordReset(this.currentUser.email);
    }
  }
}
