import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonActionType, ButtonType } from 'src/app/interfaces/Button.enum';
import { ConfirmationPopupType } from 'src/app/interfaces/ConfirmationPopup.enum';
import { DescriptionType } from 'src/app/interfaces/Description.enum';
import { Event } from 'src/app/interfaces/Event.interface';
import { FormType } from 'src/app/interfaces/Form.enum';
import { Form, FormCredentials } from 'src/app/interfaces/Form.interface';
import { PopupType } from 'src/app/interfaces/Popup.enum';
import { Popup } from 'src/app/interfaces/Popup.interface';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { User } from 'src/app/interfaces/User.interface';
import { EventsService } from 'src/app/services/events.service';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {
  public formData: Form;
  public delete: boolean;
  public isDeletionButtonDissabled: boolean = false;
  public readonly ButtonType = ButtonType;
  public readonly PopupType = PopupType;
  public readonly TitleType = TitleType;
  public readonly DescriptionType = DescriptionType;
  public readonly ButtonActionType = ButtonActionType;
  public readonly ConfirmationPopupType = ConfirmationPopupType;

  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public popupInfo: Popup,
    private userService: UserService,
    private popupDialog: PopupDialogService,
    private eventsService: EventsService,
    private shoppingCartService: ShoppingCartService
  ) {}

  ngOnInit(): void {
    this.formData = this.popupInfo.form;
  }

  public getMainContainerClasses(): string {
    switch (this.popupInfo.type) {
      case PopupType.SIDE_NAV_CONTAINER:
        return 'bg-white max-w-xs w-full h-full flex flex-col items-center gap-6 pt-8 shadow-container';
      case PopupType.FORM:
        return 'bg-white max-w-108 w-full h-full';
      case PopupType.DEFAULT_SMALL:
        return 'py-5 px-5 bg-white max-w-md';
      case PopupType.SALE:
        return 'flex flex-col items-center justify-center max-w-127.5 bg-white';
      case PopupType.CLIENT_POPUP:
        return 'flex flex-col items-center justify-center px-4 py-12 max-w-108 bg-white sm:px-10';
      default:
        const className = `flex flex-col items-center justify-center y-16 px-10 max-w-108 bg-white ${
          this.popupInfo.button && this.popupInfo.buttonFunction ? 'py-12' : 'py-16'
        }`;
        return className;
    }
  }

  public getCloseButtonClasses(): string {
    switch (this.popupInfo.type) {
      case PopupType.SIDE_NAV_CONTAINER:
        return 'absolute top-3 right-3 w-5 h-5 self-start text-zinc-600';
      case PopupType.FORM:
        return 'absolute top-6 right-6 w-5 h-5 self-start text-zinc-600';
      default:
        const className = 'absolute self-start w-5 h-5 top-6 right-6 text-zinc-600';
        return className;
    }
  }

  public performAction(): void {
    if (this.popupInfo && this.popupInfo.buttonFunction) {
      this.popupInfo.buttonFunction();
    }

    if (this.popupInfo.type === PopupType.SALE) {
      this.dialogRef.close();
    }
  }

  public secondBtnAction(): void {
    switch (this.popupInfo.form.type) {
      case FormType.EDIT_USER_POPUP:
        this.popupDialog
          .openConfirmationPopup({
            type: ConfirmationPopupType.DELETE,
            heading: 'admin_page.users_table.edit_user.confirmation_popup.title.delete_user',
            description: 'admin_page.users_table.edit_user.confirmation_popup.description.delete_user',
          })
          .subscribe(res => {
            if (res) {
              this.isDeletionButtonDissabled = true;

              this.userService.delete(this.popupInfo.filledInputs['uuid']).then(res => {
                this.dialogRef.close();

                this.popupDialog.openMessageModalPopup({
                  msg: res
                    ? 'admin_page.users_table.edit_user.message_modal.message.user_deletion_successfully'
                    : 'admin_page.users_table.edit_user.message_modal.message.user_deletion_failed',
                  success: res,
                });
              });
            }
          });

        break;
      case FormType.CLIENT_DATA:
        this.shoppingCartService.createOrder();
        this.dialogRef.close();
        break;
    }
  }

  public async emitFromForm(data: FormCredentials): Promise<void> {
    switch (this.formData.type) {
      case FormType.EDIT_USER_POPUP:
        this.userService.update(data as User).then(res => {
          this.popupDialog.openMessageModalPopup({
            msg: res
              ? 'admin_page.users_table.edit_user.message_modal.message.user_update_successfully'
              : 'admin_page.users_table.edit_user.message_modal.message.user_update_failed',
            success: res,
          });
        });

        this.dialogRef.close();
        break;
      case FormType.EDIT_PROFILE:
        this.userService.update({ ...data, isProfileCompleted: true } as User).then(res => {
          this.popupDialog.openMessageModalPopup({
            msg: res
              ? 'admin_page.users_table.edit_user.message_modal.message.user_update_successfully'
              : 'admin_page.users_table.edit_user.message_modal.message.user_update_failed',
            success: res,
          });
          res && this.dialogRef.close();
        });
        break;
      case FormType.EDIT_PROFILE_POPUP:
          this.userService.update({ ...data, isProfileCompleted: true } as User).then(res => {
            this.popupDialog.openMessageModalPopup({
              msg: res
                ? 'admin_page.users_table.edit_user.message_modal.message.user_update_successfully'
                : 'admin_page.users_table.edit_user.message_modal.message.user_update_failed',
              success: res,
            });
            res && this.dialogRef.close();
          });
          break;
      case FormType.ADD_EVENT:
        this.eventsService.addNewEvent(this.popupInfo.user, data as Event).then(res => {
          this.popupDialog.openMessageModalPopup({
            msg: res
              ? 'user_page.message_modal.message.add_new_event_successfully'
              : 'user_page.message_modal.message.add_new_event_failed',
            success: res,
          });

          this.dialogRef.close();
        });
        break;
      case FormType.EDIT_EVENT:
        this.eventsService.editEvent(this.popupInfo.user, data as Event).then(res => {
          this.popupDialog.openMessageModalPopup({
            msg: res
              ? 'user_page.message_modal.message.edit_event_successfully'
              : 'user_page.message_modal.message.edit_event_failed',
            success: res,
          });
          this.dialogRef.close();
        });
        break;
      case FormType.CLIENT_DATA:
        if (data && 'email' in data) await this.userService.update({ ...this.popupInfo.user, email: data['email'] });
        this.shoppingCartService.createOrder(data);
        this.dialogRef.close();
        break;
    }
  }

  public getTitleClasses(): string {
    switch (this.popupInfo.type) {
      case PopupType.DEFAULT_SMALL:
        return 'text-2xl-medium leading-8';
      case PopupType.CLIENT_POPUP:
        return 'text-2xl-medium text-left leading-8';
      default:
        return 'text-4xl text-center';
    }
  }

  public getSubtitleClasses(): string {
    switch (this.popupInfo.type) {
      case PopupType.DEFAULT_SMALL:
        return 'text-slate-400';
      case PopupType.CLIENT_POPUP:
        return 'text-left text-slate-400 leading-6';
      default:
        return 'text-zinc-900 text-center';
    }
  }

  public getButtonCustomClasses(): string {
    switch (this.popupInfo.type) {
      case PopupType.CLIENT_POPUP:
        return 'mt-6 flex items-center justify-center flex-row-reverse gap-3';
      default:
        return 'mt-8';
    }
  }

  public getDescClasses(): string {
    switch (this.popupInfo.type) {
      case PopupType.DEFAULT_SMALL:
        return 'mt-2';
      default:
        return 'mt-4';
    }
  }
}
