<div class="relative flex pt-4 pb-5 bg-sky-700 rounded-2xl">
  <app-icon icon="check-icon-round.svg" color="white" width="w-4" class="absolute top-5 left-5"></app-icon>
  <div class="flex gap-2 mx-12">
    <div class="flex flex-col mt-1 leading-5 text-white">
      <p class="font-normal">{{ msgInfo.msg | translate }}</p>
      <div class="flex items-center gap-1 mt-2">
        <button class="font-semibold leading-6" [routerLink]="msgInfo.routerLink">
          {{ msgInfo.routerLinkText | translate }}
        </button>
        <app-icon icon="arrow-right.svg" color="white" width="w-5"></app-icon>
      </div>
    </div>
  </div>
  <button class="absolute text-white top-5 right-5" [mat-dialog-close]="true">
    <app-icon icon="close-icon.svg" color="white" width="w-4" alt="Close Icon"></app-icon>
  </button>
</div>
