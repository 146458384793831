import { Component, OnInit } from '@angular/core';
import { getName } from 'country-list';
import { AdminPaymentStatistics, AdminStatistics } from 'src/app/definitions/AdminStatistics.definitions';
import { AdminStatisticsCountry, AdminStatisticsData } from 'src/app/interfaces/AdminStatistics.interface';
import { DbCollections } from 'src/app/interfaces/Collections.enum';
import { InfoBlock } from 'src/app/interfaces/InfoBlock.interface';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { DatabaseService } from 'src/app/services/database.service';

@Component({
  selector: 'app-admin-statistics-page',
  templateUrl: './admin-statistics-page.component.html',
  styleUrls: ['./admin-statistics-page.component.scss'],
})
export class AdminStatisticsPageComponent implements OnInit {
  public readonly TitleType = TitleType;
  public readonly adminStatisticsData: InfoBlock[] = AdminStatistics;
  public readonly pyamentStatisticsData: InfoBlock[] = AdminPaymentStatistics;
  public readonly collections: DbCollections[] = [
    DbCollections.USERS,
    DbCollections.EVENTS_LIST,
    DbCollections.CUSTOMERS,
    DbCollections.USERS,
  ];
  public countryStatisticsArray: InfoBlock[] = [];

  constructor(private db: DatabaseService) {}

  async ngOnInit(): Promise<void> {
    const statisticsBlockDataPromises = this.collections.map((collection, index) =>
      this.loadDbCount(collection, index)
    );
    await Promise.all(statisticsBlockDataPromises);

    this.db.getById<AdminStatisticsData>(DbCollections.STATISTICS, 'admin').subscribe(data => {
      if (data) {
        this.pyamentStatisticsData[0].data = data.errorPayments || 0;
        this.pyamentStatisticsData[1].data = data.succeededPayments || 0;
        this.pyamentStatisticsData[2].data = data.packageSoldSmall || 0;
        this.pyamentStatisticsData[3].data = data.packageSoldMedium || 0;
        this.pyamentStatisticsData[4].data = data.packageSoldBig || 0;
      }
    });

    this.db.getById<AdminStatisticsCountry>(DbCollections.STATISTICS, 'country').subscribe(data => {
      if (data) {
        this.countryStatisticsArray = Object.entries(data || {})
          .map(([key, value]) => ({
            title: getName(key) || key, // Use the country name if available, or fallback to the ISO code
            data: value,
            icon: key ? `flags/${key.toLowerCase()}.svg` : 'flags/xx.svg',
          }))
          .sort((a, b) => a.title.localeCompare(b.title));
      }
    });
  }

  private async loadDbCount(db: DbCollections, index: number): Promise<void> {
    let count = 0;
    if (this.adminStatisticsData[index].query) {
      count = await this.db.getDbCount(
        db,
        this.adminStatisticsData[index].query.key,
        this.adminStatisticsData[index].query.operator,
        this.adminStatisticsData[index].query.value
      );
    } else {
      count = await this.db.getDbCount(db);
    }

    this.adminStatisticsData[index] = {
      ...this.adminStatisticsData[index],
      data: count,
    };
  }
}
