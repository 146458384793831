import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LanguageUtils } from 'libs/common/utils/LanguageUtils';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private translateService: TranslateService) {}

  public initLanguage(): void {
    this.translateService.setDefaultLang('en');

    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      this.switchLanguage(savedLanguage);
    } else if (!this.translateService.currentLang) {
      this.switchLanguage(LanguageUtils.getCurrentLanguage());
    }
  }

  public switchLanguage(language: string): void {
    this.translateService.getTranslation(language).subscribe(() => {
      this.translateService.use(language);
    });
  }

  public onLanguageChange(): Observable<string> {
    return this.translateService.onLangChange.pipe(map((event: LangChangeEvent) => event.lang));
  }

  public getLang(): string {
    return this.translateService.currentLang;
  }
}
