export enum ButtonType {
  ROYAL_BLUE = 'royalBlue',
  ROYAL_BLUE_MOBILE_RESPONSIVE = 'royalBlueMobileResponsive',
  ROYAL_BLUE_SMALL = 'royalBlueSmall',
  RED_SMALL = 'redSmall',
  RED_SMALL_MOBILE_RESPONSIVE = 'redSmallMobileResponsive',
  WHITE_RECTANGLE = 'whiteRectangle',
  LANG_SWITCH = 'langSwitch',
  DROPDOWN_WHITE = 'dropdownWhite',
  ROYAL_BLUE_OUTLINE = 'royalBlueOutline',
  ROYAL_BLUE_OUTLINE_MOBILE_RESPONSIVE = 'royalBlueOutlineMobileResponsive',
  RED_OUTLINE = 'redOutline',
  ROYAL_BLUE_ADD = 'royalBlueAdd',
  ROYAL_BLUE_ADD_ICON = 'royalBlueAddIcon',
  ROYAL_BLUE_OUTLINE_SMALL = 'royalBlueOutlineSmall',
  ROYAL_BLUE_OUTLINE_ICON = 'royalBlueOutlineIcon',
  GREEN_MOBILE_RESPONSIVE = 'greenMobileResponsive',
}

export enum ButtonActionType {
  BUTTON = 'button',
  SUBMIT = 'submit',
}
