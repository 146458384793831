import { combineRoutes } from 'libs/common/utils/RouteUtils';
import { AdminRoutes, ClientRoutes, PublicUserRoutes, UserRoutes } from './Routes.enum';

export const NavigationPaths = {
  // Public User Routes
  LOGIN: PublicUserRoutes.LOGIN,
  REGISTRATION: PublicUserRoutes.REGISTRATION,

  // Admin Routes
  ADMIN_DASHBOARD: combineRoutes(AdminRoutes.ADMIN, AdminRoutes.DASHBOARD),

  // User Routes
  USER_DASHBOARD: UserRoutes.USER,
  USER_EVENTS: combineRoutes(UserRoutes.USER, UserRoutes.EVENTS),
  USER_EVENT_IMAGES: combineRoutes(UserRoutes.USER, UserRoutes.EVENT_DETAIL),
  USER_EVENT_INFORMATION: combineRoutes(UserRoutes.USER, UserRoutes.EVENT_DETAIL, UserRoutes.EVENT_INFORMATION),
  USER_EVENT_ORDERS: combineRoutes(UserRoutes.USER, UserRoutes.EVENT_DETAIL, UserRoutes.ORDERS),
  USER_EVENT_FINISHED_ORDERS: combineRoutes(UserRoutes.USER, UserRoutes.EVENT_DETAIL, UserRoutes.FINISHED_ORDERS),
  USER_ACTIVE_EVENTS: combineRoutes(UserRoutes.USER, UserRoutes.EVENTS, UserRoutes.EVENTS_ACTIVE),
  USER_INACTIVE_EVENTS: combineRoutes(UserRoutes.USER, UserRoutes.EVENTS, UserRoutes.EVENTS_INACTIVE),
  USER_PACKAGES: combineRoutes(UserRoutes.USER, UserRoutes.PACKAGES),

  // Client Routes
  CLIENT_LOGIN: combineRoutes(ClientRoutes.CLIENT, ClientRoutes.LOGIN),
  CLIENT_EVENTS: combineRoutes(ClientRoutes.CLIENT, ClientRoutes.EVENTS),
};
