import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { shortenFileName } from 'libs/common/utils/FileUtils';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { Event, EventImage } from 'src/app/interfaces/Event.interface';
import { ImageSelection } from 'src/app/interfaces/Image.interface';
import { User } from 'src/app/interfaces/User.interface';
import { EventsService } from 'src/app/services/events.service';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnInit, OnDestroy {
  private currentUser: User;
  private eventRefId: string;
  private event: Event;
  public eventImages: EventImage[] = [];
  public selectedImages: string[] = [];
  private subscriptions: Subscription = new Subscription();

  constructor(
    private popupDialog: PopupDialogService,
    private spinner: NgxSpinnerService,
    private eventsService: EventsService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {
    this.eventRefId = this.route.parent.snapshot.paramMap.get('eventRefId');
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.userService.currentUser$.subscribe(user => {
        this.currentUser = user;
      })
    );

    this.subscriptions.add(
      this.eventsService.getEvent(this.eventRefId).subscribe(res => {
        if (typeof res != 'boolean') {
          this.event = res;

          this.subscriptions.add(
            this.eventsService.getEventImages().subscribe(images => {
              images.sort((a: EventImage, b: EventImage) => {
                const dateA = new Date(a.creationDate);
                const dateB = new Date(b.creationDate);

                // Check if creationDate is valid and available
                if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
                  return dateB.getTime() - dateA.getTime();
                }

                // If creationDate is missing or invalid, use alphanumeric comparison by image name
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();

                const numA = parseInt(nameA, 10);
                const numB = parseInt(nameB, 10);

                // Check if both names are numeric
                if (!isNaN(numA) && !isNaN(numB)) {
                  return numA - numB;
                }

                // Handle alphanumeric comparison
                if (isNaN(numA) && isNaN(numB)) {
                  if (nameA < nameB) return -1;
                  if (nameA > nameB) return 1;
                  return 0;
                }

                // Sort numeric names before alphanumeric names
                if (!isNaN(numA)) return -1;
                if (!isNaN(numB)) return 1;

                // Default return statement
                return 0;
              });
              this.eventImages = images;
            })
          );
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getShorterFileName(fileName: string): string {
    return shortenFileName(fileName, 35);
  }

  public isSelected(imageId: string): boolean {
    return this.selectedImages.includes(imageId);
  }

  private resetSelection(): void {
    this.selectedImages = [];
  }

  // TODO: Select Image
  public selectImage(imageSelect: ImageSelection): void {
    imageSelect.isSelected
      ? this.selectedImages.push(imageSelect.imageId)
      : (this.selectedImages = this.selectedImages.filter(value => value !== imageSelect.imageId));

    this.popupDialog.openSelectionPopup(this.selectedImages.length).subscribe(res => {
      if (res) {
        this.spinner.show();
        const selectedImagesData: EventImage[] = this.eventImages.filter(item => this.selectedImages.includes(item.id));
        this.eventsService.deleteImages(this.currentUser.uuid, selectedImagesData, this.event.id).then(res => {
          this.spinner.hide();
          this.popupDialog.openMessageModalPopup({
            msg: res
              ? 'user_page.message_modal.message.delete_image_successfully'
              : 'user_page.message_modal.message.delete_image_failed',
            success: res,
          });

          this.resetSelection();
        });
      }
    });
  }
}
