import { Language } from '../interfaces/Language.interface';

export const Languages: Language[] = [
  {
    language: 'English',
    short: 'en',
    icon: 'en-icon.svg',
  },
  {
    language: 'Srpski',
    short: 'sr',
    icon: 'sr-icon.svg',
  },
  {
    language: 'Deutsch',
    short: 'de',
    icon: 'de-icon.svg',
  },
  {
    language: 'Español',
    short: 'es',
    icon: 'es-icon.svg',
  },
  {
    language: 'Português',
    short: 'pt',
    icon: 'pt-icon.svg',
  },
];
