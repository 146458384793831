import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { CartItem } from 'src/app/interfaces/CartItem.interface';
import { ConfirmationPopupType } from 'src/app/interfaces/ConfirmationPopup.enum';
import { ImageSelection } from 'src/app/interfaces/Image.interface';
import { Order } from 'src/app/interfaces/OrderDetails.interface';
import { PopupType } from 'src/app/interfaces/Popup.enum';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { User } from 'src/app/interfaces/User.interface';
import { MailService } from 'src/app/services/mail.service';
import { OrdersService } from 'src/app/services/orders.service';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-order-details-page',
  templateUrl: './user-order-details-page.component.html',
  styleUrls: ['./user-order-details-page.component.scss'],
})
export class UserOrderDetailsPageComponent {
  public readonly TitleType = TitleType;
  public readonly ButtonType = ButtonType;
  public readonly PopupType = PopupType;
  public currentUser: User;
  private userSubscription?: Subscription;
  private orderSubscription?: Subscription;
  // public eventData: EventData;
  public orderId: string;
  public order: Order;
  public orderImages: CartItem[];

  constructor(
    private userService: UserService,
    private popupDialog: PopupDialogService,
    private route: ActivatedRoute,
    private ordersService: OrdersService,
    private spinner: NgxSpinnerService,
    private mailService: MailService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.userService.currentUser$.subscribe(subUser => {
      this.currentUser = subUser;
      const eventRefId = this.route.parent.snapshot.paramMap.get('eventRefId');
      this.orderId = this.route.snapshot.paramMap.get('orderId');

      this.ordersService.getOrder(this.currentUser.uuid, eventRefId, this.orderId).subscribe(order => {
        if (typeof order != 'boolean') {
          this.order = order;
          this.orderImages = [...order.images];
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    if (this.orderSubscription) {
      this.orderSubscription.unsubscribe();
    }
  }

  public openFinishOrderPopup(): void {
    this.popupDialog
      .openConfirmationPopup({
        type: ConfirmationPopupType.DEFAULT,
        heading: 'event_page.finish_order.title',
        description: 'event_page.finish_order.description',
        buttonText: 'event_page.finish_order.button.text',
      })
      .subscribe(async res => {
        // TODO: Create on res true
        if (res) {
          this.spinner.show();

          const resOrderStatus = await this.ordersService.updateOrderStatus(this.currentUser, {
            ...this.order,
            id: this.orderId,
          });

          if (resOrderStatus) {
            // TODO: Update email
            if ('email' in this.order) {
              this.mailService.sendMail(
                this.order.email,
                this.translateService.instant('email_template.order_completed.subject.text', {
                  name: this.order.firstName,
                }),
                this.translateService.instant('email_template.order_completed.text.text'),
                this.translateService.instant('email_template.order_completed.title.text', {
                  name: this.order.firstName,
                }),
                this.translateService.instant('email_template.order_completed.mail.text')
              );
            }

            this.popupDialog.openMessageModalPopup({
              msg: 'event_page.finish_order.message_modal.success',
              success: true,
            });
          }

          this.spinner.hide();
          this.router.navigate(['..'], { relativeTo: this.route });
        }
      });
  }

  public async hangleNumberOfImageChange(image: ImageSelection): Promise<void> {
    this.spinner.show();
    const copyOfOrderImages = this.orderImages.map(orderImage =>
      orderImage.id === image.imageId ? { ...orderImage, numberOfImages: image.numberOfSelected } : orderImage
    );

    const totalNumberOfImages = copyOfOrderImages.reduce((total, obj) => total + obj.numberOfImages, 0);

    await this.ordersService.updateOrderImages(this.currentUser, this.orderId, copyOfOrderImages, totalNumberOfImages);
    this.spinner.hide();
  }

  public handleRemoveImageFromSelection(imageId: string): void {
    this.popupDialog
      .openConfirmationPopup({
        type: ConfirmationPopupType.DELETE,
        heading: 'event_page.order_details.popup.delete_image.title',
        description:
          this.orderImages.length > 1
            ? 'event_page.order_details.popup.delete_image.description'
            : 'event_page.order_details.popup.delete_image.description_last',
        buttonText: 'event_page.order_details.popup.delete_image.button',
      })
      .subscribe(async res => {
        if (res) {
          this.spinner.show();
          const copyOfOrderImages = this.orderImages.filter(orderImages => orderImages.id !== imageId);

          const totalNumberOfImages = copyOfOrderImages.reduce((total, obj) => total + obj.numberOfImages, 0);

          await this.ordersService.updateOrderImages(
            this.currentUser,
            this.orderId,
            copyOfOrderImages,
            totalNumberOfImages
          );

          if (totalNumberOfImages === 0) {
            await this.ordersService.updateOrderStatus(this.currentUser, {
              ...this.order,
              id: this.orderId,
            });

            this.router.navigate(['..'], { relativeTo: this.route });
          }

          this.spinner.hide();

          this.popupDialog.openMessageModalPopup({
            success: res,
            msg: res
              ? 'event_page.order_details.popup.delete_image.message_modal.success'
              : 'event_page.order_details.popup.delete_image.message_modal.failed',
          });
        }
      });
  }
}
