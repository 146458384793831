<div *ngIf="isDataLoaded" class="max-w-221.5 mx-auto">
  <div class="px-5 mx-auto">
    <app-title [type]="TitleType.BIG" text="user_page.packages.title.text" customClasses="text-center"></app-title>

    <app-description
      [type]="DescriptionType.SMALL_DARK"
      text="user_page.packages.description.text"
      customClasses="max-w-118.5 text-center mx-auto mt-4 text-zinc-900"
    ></app-description>
  </div>

  <!-- TODO: come back to this later -->
  <!-- <div class="flex justify-center my-4" *ngIf="isCurrencySwitchEnabled">
    <label for="currencySelect" class="mr-2">Select Currency:</label>
    <select id="currencySelect" class="p-2 border rounded" (change)="onCurrencyChange($event)">
      <option *ngFor="let currency of currencies" [value]="currency">{{ currency }}</option>
    </select>
  </div> -->

  <div class="w-full overflow-x-scroll scroll-y-hide p-8 pt-18.5 max-sm:pt-14">
    <div class="grid justify-between w-full grid-cols-1 gap-3 space-y-8 lg:grid-cols-3 lg:space-y-0">
      <div
        *ngFor="let product of products; let i = index"
        (click)="selectPackage(i)"
        [class]="
          (product.metadata?.events === selectedPackage?.metadata?.events
            ? 'outline-sky-700 bg-sky-50'
            : 'outline-transparent bg-white') +
          ' ' +
          (product.metadata?.isMostPopular ? 'shadow-cardsBlue' : 'shadow-cards')
        "
        class="relative cursor-pointer outline outline-2 rounded-3xl hover:outline-sky-700"
      >
        <h2 class="p-4 text-lg font-medium text-center uppercase rounded-3xl text-sky-900">{{ product.name }}</h2>
        <div
          *ngIf="product.metadata?.isMostPopular"
          class="absolute top-0 px-3 -translate-x-1/2 -translate-y-full bg-orange-400 rounded-t left-1/2"
        >
          <p class="text-white uppercase whitespace-nowrap">{{ 'user_page.packages.most_popular.text' | translate }}</p>
        </div>
        <div class="rounded-3xl" *ngFor="let price of product.prices">
          <!-- Check if price is active before rendering -->
          <div *ngIf="price.active" class="flex items-center justify-center gap-3 py-6 border-sky-100 border-y">
            <div class="relative inline-block">
              <p class="font-semibold text-4xl text-sky-900 opacity-30">{{ product.metadata?.oldPrice }}</p>
              <app-icon
                class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
                icon="crossed-out-icon.svg"
                color="sky-blue-700"
                width="w-16"
              ></app-icon>
            </div>

            <div class="flex gap-1">
              <p class="text-5.5xl font-semibold text-sky-900">{{ price.unit_amount / 100 }}</p>

              <p class="text-2xl font-semibold text-sky-900">{{ price.currency | uppercase }}</p>
            </div>
          </div>

          <div class="flex justify-center py-6 rounded-3xl">
            <div class="flex flex-col gap-1 align-center">
              <div class="flex items-center gap-3">
                <app-icon icon="check-icon-round.svg" width="w-5" color="sky-700"></app-icon>
                <p>{{ getEventLabel(product.metadata?.events) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- TODO: Fix this in new ticket -->
  <div class="flex justify-center px-5 pt-6 pb-14">
    <app-button
      class="rounded-full"
      [buttonInfo]="{
        type: ButtonType.ROYAL_BLUE,
        text: 'user_page.packages.button.text',
        icon: 'cart-icon-bold.svg',
        customClasses: 'flex items-center gap-2 max-w-68.5 px-10',
        isDisabled: isButtonDisabled
      }"
      (click)="initiatePayment()"
    ></app-button>
  </div>
</div>
