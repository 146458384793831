export enum PageLayoutType {
  LOGIN = 'login',
  REGISTRATION = 'registration',
  SEND_PASSWORD_RESET = 'sendPasswordReset',
  PASSWORD_RESET = 'passwordReset',
  ADMIN_PAGE = 'adminPage',
  USER_PAGE = 'userPage',
  EVENT_PAGE = 'eventPage',
  NOT_FOUND = 'notFound',
  CLIENT_LOGIN = 'clientLogin',
}

export enum AuthPageLayoutType {
  REGISTRATION = 'registration',
  PASSWORD_RESET = 'passwordReset',
  LOGIN = 'login',
}
