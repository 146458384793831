import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MsgInfo } from 'src/app/interfaces/MessageModal.interface';

@Component({
  selector: 'app-popup-with-link',
  templateUrl: './popup-with-link.component.html',
  styleUrls: ['./popup-with-link.component.scss'],
})
export class PopupWithLinkComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupWithLinkComponent>,
    @Inject(MAT_DIALOG_DATA) public msgInfo: MsgInfo
  ) {}
}
