import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegistrationPage } from 'src/app/definitions/PageLayout.definitions';
import { AuthPageLayout, PageLayout } from 'src/app/interfaces/PageLayout.interface';

@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss'],
})
export class RegistrationPageComponent {
  public registrationPage: AuthPageLayout = RegistrationPage;

  constructor(private spinner: NgxSpinnerService) {}
}
