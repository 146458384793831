import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, map, take } from 'rxjs';
import { CartItem } from '../interfaces/CartItem.interface';
import { ConfirmationPopupType } from '../interfaces/ConfirmationPopup.enum';
import { FormCredentials } from '../interfaces/Form.interface';
import { OrderDetails, PriceDetails } from '../interfaces/OrderDetails.interface';
import { DatabaseService } from './database.service';
import { PopupDialogService } from './popup-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  private cartItems: CartItem[] = [];
  private cartItemsSubject = new BehaviorSubject<CartItem[]>(this.cartItems);
  private orderDetailsSubject = new BehaviorSubject<OrderDetails>(null);
  private targetDbPathSubject = new BehaviorSubject<string>(null);

  constructor(
    private db: DatabaseService,
    private popupDialogService: PopupDialogService,
    private spinner: NgxSpinnerService,
    private location: Location
  ) {}

  public setOrderDetails(orderDetails: OrderDetails): void {
    this.orderDetailsSubject.next(orderDetails);
  }

  public getPriceDetails(): Observable<PriceDetails> {
    return this.orderDetailsSubject.pipe(map((orderDetails: OrderDetails) => orderDetails.price));
  }

  public setTargetDbPath(path: string): void {
    this.targetDbPathSubject.next(path);
  }

  public getItems(): Observable<CartItem[]> {
    return this.cartItemsSubject.asObservable();
  }

  public addItem(item: CartItem): void {
    const existingItem = this.cartItems.find(cartItem => cartItem.id === item.id);

    if (existingItem) {
      existingItem.numberOfImages += item.numberOfImages;
    } else {
      this.cartItems.push(item);
    }

    this.cartItemsSubject.next(this.cartItems);
  }

  public updateItem(item: CartItem): void {
    const existingItem = this.cartItems.find(cartItem => cartItem.id === item.id);

    if (existingItem) {
      existingItem.numberOfImages = item.numberOfImages;

      if (existingItem.numberOfImages <= 0) {
        this.removeItem(existingItem);
      } else {
        this.cartItemsSubject.next(this.cartItems);
      }
    }
  }

  public removeItem(item: CartItem): void {
    const index = this.cartItems.findIndex(cartItem => cartItem.name === item.name);

    if (index !== -1) {
      if (this.cartItems[index].numberOfImages > 1) {
        this.cartItems[index].numberOfImages -= 1;
      } else {
        this.cartItems.splice(index, 1);
      }

      this.cartItemsSubject.next(this.cartItems);
    }
  }

  public removeItemAll(item: CartItem): void {
    const index = this.cartItems.findIndex(cartItem => cartItem.name === item.name);

    if (index !== -1) {
      this.cartItems.splice(index, 1);

      this.cartItemsSubject.next(this.cartItems);
    }
  }

  public getTotalQuantity(): number {
    return this.cartItems.reduce((total, item) => total + item.numberOfImages, 0);
  }

  private resetCart(): void {
    this.cartItems = [];
    this.cartItemsSubject.next(this.cartItems);
  }

  // Create order from cart from client side
  public createOrder(data?: FormCredentials): void {
    this.spinner.show();
    this.orderDetailsSubject.pipe(take(1)).subscribe(orderDetails => {
      if (orderDetails) {
        let copyOrder = Object.assign({}, orderDetails.order);
        copyOrder.images = this.cartItems;
        if (data && 'email' in data) copyOrder.email = data['email'];
        copyOrder.totalNumberOfImages = this.getTotalQuantity();
        this.targetDbPathSubject.pipe(take(1)).subscribe(async path => {
          this.db.create(path, copyOrder).then(res => {
            if (res) {
              this.resetCart();

              this.popupDialogService
                .openConfirmationPopup({
                  type: ConfirmationPopupType.DEFAULT,
                  heading: 'client.event_page.order_created.popup.heading.text',
                  description: 'client.event_page.order_created.popup.description.text',
                  buttonText: 'client.event_page.order_created.popup.button.text',
                  disableCloseButton: true,
                })
                .subscribe((res: boolean) => {
                  if (res) {
                    this.location.back();
                  }
                });
            } else {
              this.popupDialogService.openMessageModalPopup({
                success: false,
                msg: 'client_page.cart.popup.failed_order.text',
              });
            }
            this.spinner.hide();
          });
        });
      } else {
        this.popupDialogService.openMessageModalPopup({
          success: false,
          msg: 'client_page.cart.popup.failed_order.text',
        });
        this.spinner.hide();
      }
    });
  }
}
