<a
  (click)="toggleSelection()"
  class="relative block shadow-modal"
  [ngClass]="{
    'rounded-lg overflow-hidden': isRounded,
    'cursor-pointer': !isSelectionBlocked && !canBeRemoved
  }"
>
  <img
    [src]="imageData.url"
    onerror="this.src='./assets/images/default-event-image.webp';"
    [alt]="imageData.name"
    class="object-cover object-center w-full h-72"
  />
  <div
    *ngIf="!blockNameView"
    class="absolute inset-0"
    style="background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 1))"
  ></div>

  <div class="absolute flex w-full p-2" [ngClass]="getPosition()">
    <div *ngIf="!isSelectionBlocked" class="relative grid w-10 h-10 rounded-lg place-items-center">
      <div class="absolute inset-0 bg-black rounded-lg opacity-50"></div>
      <div
        class="absolute grid border-2 rounded-full w-7 h-7 place-items-center"
        [ngClass]="{
          'bg-green-300 border-green-300': imageData.isSelected,
          'border-white': !imageData.isSelected
        }"
      >
        <app-icon *ngIf="imageData.isSelected" icon="check-icon.svg" width="w-5" color="white"></app-icon>
      </div>
    </div>

    <div
      *ngIf="imageData.numberOfImages"
      class="relative flex items-center justify-around rounded-lg cursor-default"
      [ngClass]="isSelectionBlocked ? 'w-10' : 'w-28'"
      (click)="stopPropagation($event)"
    >
      <div class="absolute inset-0 bg-black rounded-lg opacity-50"></div>

      <div class="absolute flex justify-between" [ngClass]="!isSelectionBlocked && 'w-24'">
        <button
          *ngIf="!isSelectionBlocked"
          (click)="operation($event, -1)"
          class="grid text-lg font-bold leading-none bg-white border-2 border-white rounded-full w-7 h-7 place-items-center"
          [ngClass]="imageData.numberOfImages === 1 ? 'opacity-50 cursor-default' : 'cursor-pointer'"
        >
          <app-icon icon="minus-icon.svg" color="sky-700" width="w-4"></app-icon>
        </button>

        <div class="grid text-sm bg-green-300 border-2 border-green-300 rounded-full w-7 h-7 place-items-center">
          <p class="font-bold leading-none text-white">{{ imageData.numberOfImages }}</p>
        </div>

        <button
          *ngIf="!isSelectionBlocked"
          (click)="operation($event, 1)"
          class="grid text-lg font-bold leading-none bg-white border-2 border-white rounded-full cursor-pointer w-7 h-7 place-items-center"
        >
          <app-icon icon="plus-icon.svg" color="sky-700" width="w-4"></app-icon>
        </button>
      </div>
    </div>

    <div *ngIf="canBeRemoved" class="relative grid w-10 h-10 rounded-lg place-items-center" (click)="handleRemove()">
      <div *ngIf="!isSelectionBlocked" class="absolute inset-0 bg-black rounded-lg opacity-50"></div>
      <div
        *ngIf="!isSelectionBlocked"
        class="absolute grid bg-red-400 border-2 rounded-full cursor-pointer w-7 h-7 place-items-center"
      >
        <app-icon icon="trash-can-icon.svg" width="w-3" color="red-600"></app-icon>
      </div>
    </div>
  </div>

  <p *ngIf="!blockNameView" class="absolute text-white bottom-2 left-2">{{ getShorterFileName(imageData.name) }}</p>
</a>
