import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { OrderDetailColumns } from 'src/app/definitions/Columns.definition';
import { Payment } from 'src/app/interfaces/Payment.interface';
import { Role } from 'src/app/interfaces/Role.enum';
import { TableType } from 'src/app/interfaces/Table.enum';
import { User } from 'src/app/interfaces/User.interface';
import { DatabaseService } from 'src/app/services/database.service';
import { StripeService } from 'src/app/services/stripe.service';

@Component({
  selector: 'app-orders-detail',
  templateUrl: './orders-detail.component.html',
  styleUrls: ['./orders-detail.component.scss'],
})
export class OrdersDetailComponent implements OnInit, OnDestroy {
  public readonly orderColumns = OrderDetailColumns;
  public readonly TableType = TableType;
  public readonly Role = Role;

  public userId: string;
  public userOrders: Observable<Payment[]>;
  public userInfo: User;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private stripeService: StripeService,
    private datePipe: DatePipe,
    private databaseService: DatabaseService
  ) {}

  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('userId');
    if (this.userId) {
      this.databaseService
        .getById('users', this.userId)
        .pipe(
          map((userData): User => {
            if (userData) {
              return userData as User;
            }
            throw new Error('User not found!');
          })
        )
        .subscribe(
          (user: User) => {
            this.userInfo = user;
          },
          error => {
            console.error('Error retrieving user info:', error);
          }
        );

      this.userOrders = this.stripeService.getPaymentsForUser(this.userId).pipe(
        takeUntil(this.unsubscribe$),
        map(orders =>
          orders.map(order => {
            const formattedAmount = (order.amount / 100).toFixed(2);
            const uppercaseCurrency = order.currency ? order.currency.toUpperCase() : 'N/A';
            const formattedDate = order.created
              ? this.datePipe.transform(new Date(order.created * 1000), 'medium')
              : 'N/A';

            return {
              ...order,
              last4: order.charges?.data[0]?.payment_method_details?.card?.last4 ?? '',
              brand: order.charges?.data[0]?.payment_method_details?.card?.brand ?? '',
              type: order.charges?.data[0]?.payment_method_details?.type ?? '',
              amountWithCurrency: `${formattedAmount} ${uppercaseCurrency}`,
              formattedCreatedDate: formattedDate,
              sortAmount: order.amount,
              sortDate: order.created * 1000,
            };
          })
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
