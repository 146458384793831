import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonActionType, ButtonType } from 'src/app/interfaces/Button.enum';
import { ConfirmationPopupType } from 'src/app/interfaces/ConfirmationPopup.enum';
import { DescriptionType } from 'src/app/interfaces/Description.enum';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { UploadPopup } from 'src/app/interfaces/UploadPopup.interface';
import { ImageUploadService } from 'src/app/services/image-upload.service';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';

@Component({
  selector: 'app-upload-popup',
  templateUrl: './upload-popup.component.html',
  styleUrls: ['./upload-popup.component.scss'],
})
export class UploadPopupComponent {
  public isFileHovered: boolean = false;
  public blockFileUpload: boolean = false;
  public fileName: string = '';
  public imageUploadProgress: number;
  public imagesUploaded: number = 0;
  public numberOfImages: number;
  public readonly ButtonType = ButtonType;
  public readonly ButtonActionType = ButtonActionType;
  public readonly descriptionType = DescriptionType;
  public readonly titleType = TitleType;

  constructor(
    private dialogRef: MatDialogRef<UploadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public popupInfo: UploadPopup,
    private popupDialogService: PopupDialogService,
    private imageUploadService: ImageUploadService
  ) {}

  public onFileHover(isHovered: boolean): void {
    this.isFileHovered = isHovered;
  }

  public onFileInputChange(event: any): void {
    this.setImages(event.files);
  }

  public setImages(images: FileList | File[]): void {
    const imagesNames: string[] = this.popupInfo.eventImages.map(image => image.name);
    let filteredImages: File[] = [];
    if (images instanceof FileList) {
      for (let index = 0; index < images.length; index++) {
        if (!this.imageUploadService.validateImageType(images.item(index).type)) {
          this.popupDialogService.openMessageModalPopup({
            success: false,
            msg: 'user_page.message_modal.message.wrong_format',
          });
          return;
        } else if (!imagesNames.includes(images.item(index).name)) {
          filteredImages.push(images.item(index));
        }
      }
    } else {
      filteredImages = images.filter(image => !imagesNames.includes(image.name));
    }
    this.numberOfImages = filteredImages.length;

    if (!filteredImages.length && images.length) {
      this.popupDialogService.openMessageModalPopup({
        success: false,
        msg: 'user_page.message_modal.message.images_already_uploaded',
      });
      return;
    }

    const popupData = {
      type: ConfirmationPopupType.DEFAULT,
      heading: 'user_page.event.upload_image.title',
      description: 'user_page.event.upload_image.description',
      icon: 'image-upload-icon.svg',
    };

    this.imageUploadService.progress$.subscribe(progress => {
      this.imageUploadProgress = Number(progress.toFixed(2));
      if (progress >= 100) {
        this.dialogRef.close();
        this.popupDialogService.openMessageModalPopup({
          success: true,
          msg: 'user_page.message_modal.message.successful_upload',
        });
      }
    });

    this.imageUploadService.fileUploaded$.subscribe(fileUploaded => {
      this.imagesUploaded = fileUploaded;
    });

    this.popupDialogService.openConfirmationPopup(popupData).subscribe(res => {
      if (res) {
        this.imageUploadProgress = 0;
        this.imageUploadService.uploadImage(
          filteredImages,
          `events/${this.popupInfo.uuid}/userEvents/${this.popupInfo.eventId}`,
          this.popupInfo.includeWatermark
        );
      }
    });
  }
}
