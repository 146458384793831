<div *ngIf="numberOfSelectedItems" class="flex items-center justify-center gap-8 p-4 bg-sky-700">
  <div class="flex gap-2">
    <app-icon class="grid place-items-center" icon="check-icon-round.svg" color="white" width="w-4"></app-icon>
    <p class="font-semibold text-white">
      {{
        (numberOfSelectedItems > 1 ? 'selected_images.popup.text' : 'select_image.popup.text')
          | translate : { numberOfImages: numberOfSelectedItems ? numberOfSelectedItems : 1 }
      }}
    </p>
  </div>
  <app-icon (click)="closePopup()" class="cursor-pointer" icon="delete-icon.svg" color="white" width="w-7"></app-icon>
</div>
