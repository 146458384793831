/**
 * Utility function to shorten File Name.
 *
 * @param segments - File Name and Max Length.
 * @returns Shorten File Name.
 */
export function shortenFileName(fileName: string, maxLength: number = 50): string {
  if (fileName.length <= maxLength) {
    return fileName;
  }

  const ellipsis = '...';
  const halfLength = Math.floor(maxLength / 2);
  const prefix = fileName.substring(0, halfLength).trim();
  const suffix = fileName.slice(-halfLength);

  return prefix + ellipsis + suffix;
}
