import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { Subscription } from 'rxjs';
import { DateSearchKeys, Event, InputSearchKeys } from 'src/app/interfaces/Event.interface';
import { User } from 'src/app/interfaces/User.interface';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { EventsService } from 'src/app/services/events.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-active-events-page',
  templateUrl: './user-active-events-page.component.html',
  styleUrls: ['./user-active-events-page.component.scss'],
})
export class UserActiveEventsPageComponent implements OnInit, OnDestroy {
  public currentUser: User;
  public events: Event[] = [];
  public copyEvents: Event[] = [];
  private userSubscription?: Subscription;
  private eventsSubscription?: Subscription;
  private dateRange: DateRange<Date>;
  public inputSearch: string;

  constructor(
    private eventsService: EventsService,
    private userService: UserService,
    private dataProviderService: DataProviderService
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });

    this.eventsSubscription = this.eventsService.getActiveEvents().subscribe(events => {
      this.copyEvents = events;
      this.filter();
    });

    this.dataProviderService.onDateRangeChange().subscribe(dateRange => {
      this.dateRange = dateRange;
      this.filter();
    });

    this.dataProviderService.searchInputChange().subscribe(search => {
      this.inputSearch = search;
      this.filter();
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.eventsSubscription.unsubscribe();
  }

  private filter(): void {
    this.events = this.eventsService.filter(
      this.copyEvents,
      DateSearchKeys.EVENT_DATE,
      InputSearchKeys.NAME,
      this.dateRange,
      this.inputSearch
    );
  }
}
