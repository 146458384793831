<div class="container flex flex-col items-center w-full gap-5 mt-10 mb-10 lg:flex-row">
  <app-event-info-block
    class="flex-grow w-full"
    [title]="'event_page.info.event_info_block.general'"
    [eventInfoBlocks]="eventGeneralInfoBlock"
  ></app-event-info-block>
  <app-event-info-block
    class="flex-grow w-full"
    [title]="'event_page.info.event_info_block.specific'"
    [eventInfoBlocks]="eventSpecificInfoBlock"
    [saleHoursLeft]="saleHoursLeft"
    [saleMinutesLeft]="saleMinutesLeft"
  ></app-event-info-block>
</div>
