<div class="container pb-10">
  <app-table
    *ngIf="orders && orders.length > 0"
    (emitId)="getIdFromTable($event)"
    [data]="orders"
    [displayedColumns]="userOrdersColumns"
    [type]="tableType.USER_ORDERS"
    [currentUser]="currentUser"
    pageTitle="event_page.table.orders.name"
    [isRowClickEnabled]="true"
  ></app-table>
  <p *ngIf="orders.length === 0" class="text-lg text-center mt-7 text-slate-300">
    {{ 'user_page.orders.active_orders.none' | translate }}
  </p>
</div>
