import { Component, Input } from '@angular/core';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { Button } from 'src/app/interfaces/Button.interface';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() buttonInfo!: Button;

  constructor() {}

  public getButtonClasses(): string {
    switch (this.buttonInfo.type) {
      case ButtonType.ROYAL_BLUE:
        return 'text-white w-full bg-sky-700 py-3 rounded-full text-lg hover:bg-sky-800 font-montserrat font-semibold';
      case ButtonType.ROYAL_BLUE_MOBILE_RESPONSIVE:
        return 'text-white w-full bg-sky-700 py-2.5 px-6 rounded-full hover:bg-sky-800 font-montserrat leading-tight';
      case ButtonType.ROYAL_BLUE_SMALL:
        return 'py-2.5 px-6.5 text-white font-semibold bg-sky-700 hover:bg-sky-800 rounded-full';
      case ButtonType.RED_SMALL:
        return 'py-2.5 px-6.5 text-white font-semibold bg-red-600 hover:bg-red-700 rounded-full';
      case ButtonType.RED_SMALL_MOBILE_RESPONSIVE:
        return 'py-2 px-6 text-white bg-red-600 hover:bg-red-700 rounded-full';
      case ButtonType.WHITE_RECTANGLE:
        return 'py-3 text-zinc-900 border border-neutral-300 w-full flex items-center justify-center gap-2 rounded hover:border-blue-500';
      case ButtonType.LANG_SWITCH:
        return 'p-2 shadow-lang rounded bg-white';
      case ButtonType.DROPDOWN_WHITE:
        return 'block w-full py-2 px-3 hover:bg-slate-100';
      case ButtonType.ROYAL_BLUE_OUTLINE:
        return 'py-2.5 px-6.5 border border-sky-700 text-sky-700 font-semibold bg-transparent rounded-full';
      case ButtonType.ROYAL_BLUE_OUTLINE_MOBILE_RESPONSIVE:
        return 'py-2 px-6 border border-sky-700 text-sky-700 bg-transparent rounded-full';
      case ButtonType.RED_OUTLINE:
        return 'py-2.5 px-6.5 outline outline-1 -outline-offset-1 outline-red-600 text-red-600 font-semibold bg-transparent rounded-full';
      case ButtonType.ROYAL_BLUE_OUTLINE_SMALL:
        return 'py-2 px-5 outline outline-1 -outline-offset-1 outline-sky-700 text-sky-700 font-semibold bg-transparent rounded-full text-sm';
      case ButtonType.ROYAL_BLUE_ADD:
        return 'py-3 px-8 text-white font-semibold bg-sky-700 rounded-full hover:bg-sky-800';
      case ButtonType.ROYAL_BLUE_ADD_ICON:
        return 'py-3 px-3 text-white font-semibold bg-sky-700 rounded-full hover:bg-sky-800';
      case ButtonType.ROYAL_BLUE_OUTLINE_ICON:
        return 'py-3 px-3 outline outline-1 -outline-offset-1 outline-sky-700 text-sky-700 font-semibold bg-transparent rounded-full text-sm';
      case ButtonType.GREEN_MOBILE_RESPONSIVE:
        return 'text-white w-full bg-green-300 py-2.5 px-6 rounded-full hover:bg-green-300-dark font-montserrat leading-tight';
      default:
        return '';
    }
  }

  public getAdditionalClasses(): string {
    return this.buttonInfo.isDisabled ? 'opacity-50 cursor-not-allowed' : '';
  }

  public getCustomClasses(): string {
    return this.buttonInfo.customClasses ? this.buttonInfo.customClasses : '';
  }
}
