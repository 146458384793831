<div class="w-full p-5 max-w-108">
  <qrcode
    #parent
    [elementType]="elementType"
    [qrdata]="domain + 'client/event/' + eventId"
    [allowEmptyString]="true"
    [ariaLabel]="'QR Code image with the following content...'"
    [cssClass]="'center mb-5 qr-code-download'"
    [colorDark]="'#000000ff'"
    [colorLight]="'#ffffffff'"
    [errorCorrectionLevel]="'M'"
    imageSrc="./assets/images/photo-seller-icon.svg"
    [imageHeight]="200"
    [imageWidth]="200"
    [margin]="4"
    [scale]="1"
    [title]="eventId"
    [width]="1200"
  >
  </qrcode>

  <app-button
    (click)="saveAsImage(parent)"
    class="rounded-full"
    [buttonInfo]="{
      text: 'user_page.event.qr_code.download_image',
      type: buttonType.ROYAL_BLUE
    }"
  >
  </app-button>
</div>
