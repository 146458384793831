export enum PopupType {
  DEFAULT = 'default',
  DEFAULT_SMALL = 'defaultSmall',
  CLIENT_POPUP = 'clientPopup',
  SHOPPING_CART = 'shoppingCart',
  SIDE_NAV_CONTAINER = 'sideNavContainer',
  MINI = 'mini',
  FORM = 'form',
  IMAGE_SELECT = 'imageSelect',
  SALE = 'sale',
}
