<div class="flex gap-0 lg:gap-1 items-center justify-center">
  <button
    class="flex items-center gap-2 px-3 py-2 rounded lg:bg-blue-100"
    mat-icon-button
    [matMenuTriggerFor]="appMenu"
  >
    <p class="hidden font-semibold text-sky-700 lg:block">{{ 'user_page.filter.text' | translate }}</p>

    <app-icon icon="calendar-icon.svg" width="w-5" color="slate-400"></app-icon>
  </button>
  <button
    *ngIf="selectedDateRange.start && selectedDateRange.end"
    class="px-3 py-3 cursor-pointer"
    (click)="resetPicker()"
  >
    <app-icon icon="close-icon.svg" width="w-4" color="red-600"></app-icon>
  </button>
</div>
<mat-menu #appMenu="matMenu">
  <mat-calendar [selected]="selectedDateRange" (click)="$event.stopPropagation()"></mat-calendar>
</mat-menu>
