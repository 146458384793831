import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import firebase from 'firebase/compat/app';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ButtonType } from 'src/app/interfaces/Button.enum';
import { CartItem } from 'src/app/interfaces/CartItem.interface';
import { DescriptionType } from 'src/app/interfaces/Description.enum';
import { Event, EventImage } from 'src/app/interfaces/Event.interface';
import { FormCredentials } from 'src/app/interfaces/Form.interface';
import { ImageSelection } from 'src/app/interfaces/Image.interface';
import { Order, OrderDetails, PriceDetails } from 'src/app/interfaces/OrderDetails.interface';
import { PopupType } from 'src/app/interfaces/Popup.enum';
import { TitleType } from 'src/app/interfaces/Title.enum';
import { User } from 'src/app/interfaces/User.interface';
import { EventsService } from 'src/app/services/events.service';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';
import { ShoppingCartService } from 'src/app/services/shopping-cart.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-client-event-page',
  templateUrl: './client-event-page.component.html',
  styleUrls: ['./client-event-page.component.scss'],
})
export class ClientEventPageComponent implements OnInit, OnDestroy {
  public readonly TitleType = TitleType;
  public readonly DescriptionType = DescriptionType;
  public readonly ButtonType = ButtonType;
  public event: Event;
  public imagesPrice: string;
  public currentUser: User;
  public tableNumber: number;
  public eventImages: EventImage[] = [];
  public slicedEventImages: EventImage[] = [];
  public selectedItems: CartItem[] = [];
  public selectedImages: string[] = [];
  public isDataLoaded: boolean = false;

  private eventSubscription?: Subscription;

  constructor(
    private popupDialog: PopupDialogService,
    private shoppingCartService: ShoppingCartService,
    private route: ActivatedRoute,
    private router: Router,
    private eventsService: EventsService,
    private translateService: TranslateService,
    private userService: UserService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    // TODO: replace with enums after merging with dev
    this.spinner.show();
    this.tableNumber = Number(localStorage.getItem('tableNumber'));

    this.userService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });

    this.getEventData();
  }

  ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  checkCart(event: any) {
    if (this.totalQuantity()) {
      return false;
    }

    return undefined;
  }

  public loadMoreImages() {
    const currentImageCount = this.slicedEventImages.length;
    const imagesToLoad = 20;

    if (currentImageCount + imagesToLoad <= this.eventImages.length) {
      const additionalImages = this.eventImages.slice(currentImageCount, currentImageCount + imagesToLoad);
      this.slicedEventImages = this.slicedEventImages.concat(additionalImages);
    }
  }

  private getEventData(): void {
    const eventRefId = this.route.snapshot.paramMap.get('eventRefId');
    const welcomePopupShownBefore = localStorage.getItem('showPopup') === 'true';

    // TODO: Get Event
    this.eventSubscription = this.eventsService.getEventAsObservable(eventRefId).subscribe(res => {
      if (typeof res == 'boolean') {
        this.router.navigate(['/page-not-found']);
        return;
      }

      this.event = res;

      this.imagesPrice = `${this.event.price} ${this.event.currency}`;

      const currentTimestamp = firebase.firestore.Timestamp.fromDate(new Date()).toMillis();
      if (this.event?.saleEnd?.toMillis() > currentTimestamp) {
        this.eventsService.getEventImages().subscribe(images => {
          this.eventImages = images;
          this.slicedEventImages = this.eventImages.slice(0, 20);
          this.isDataLoaded = true;
          this.spinner.hide();
        });
      } else {
        localStorage.removeItem('eventId');
        localStorage.removeItem('tableNumber');
        this.isDataLoaded = true;
        this.spinner.hide();
      }

      if (welcomePopupShownBefore && this.isEventActive()) {
        this.showWelcomePopup();
      }

      localStorage.removeItem('showPopup');
    });
  }

  private showWelcomePopup() {
    this.popupDialog.openPopup({
      type: PopupType.CLIENT_POPUP,
      title: this.getInstantTranslation('client_page.welcome_popup.title', { eventName: this.event.name }),
      icon: 'client-event-popup.svg',
      description: this.getInstantTranslation('client_page.welcome_popup.description', {
        eventName: this.event.name,
      }),
      button: 'client_page.welcome_popup.button',
      buttonIcon: 'arrow-right.svg',
      buttonFunction: () => {},
    });
  }

  public addToCartPopup(): void {
    // TODO: Need to make log for this
    this.popupDialog.openBottomPopup({
      type: PopupType.SHOPPING_CART,
      title: this.translateService.instant(
        this.getTotalNumber() > 1
          ? 'client_page.event.cart.popup.multiple_image.title.text'
          : 'client_page.event.cart.popup.single_image.title.text',
        {
          imagesLength: this.getTotalNumber(),
        }
      ),
      description: this.translateService.instant(
        this.getTotalNumber() > 1
          ? 'client_page.event.cart.popup.multiple_image.description.text'
          : 'client_page.event.cart.popup.single_image.description.text',
        {
          imagesLength: this.getTotalNumber(),
        }
      ),
      icon: 'cart-receipt-icon.svg',
      button: this.translateService.instant(
        this.getTotalNumber() > 1
          ? 'client_page.event.success.popup.add_mulltiple_image.button.text'
          : 'client_page.event.success.popup.add_single_image.button.text',
        {
          imagesLength: this.getTotalNumber(),
        }
      ),
      buttonFunction: () => {
        this.selectedItems.map(item => this.shoppingCartService.addItem(item));
        this.shoppingCartService.setOrderDetails(this.createOrderDetails());
        this.popupDialog.openPopupWithLink({
          msg: this.translateService.instant(
            this.getTotalNumber() > 1
              ? 'client_page.event.success.popup.multiple_image.text'
              : 'client_page.event.success.popup.single_image.text',
            { imagesLength: this.getTotalNumber() }
          ),
          routerLink: 'client/cart',
          routerLinkText: 'client_page.selection_popup.finish_shopping',
        });
        this.selectedItems = [];
      },
    });
  }

  public routeToCart(): void {
    this.shoppingCartService.setOrderDetails(this.createOrderDetails());
    this.router.navigate(['/client/cart']);
  }

  private createOrderDetails(): OrderDetails {
    const order: Order = {
      firstName: this.currentUser.firstName,
      lastName: this.currentUser.lastName,
      id: '',
      totalNumberOfImages: this.totalQuantity(),
      isOrderCompleted: false,
      images: [],
      ...(this.currentUser.email && { email: this.currentUser.email }),
      ...(this.currentUser.phoneNumber && { phoneNumber: this.currentUser.phoneNumber }),
      ...(this.tableNumber && { tableNumber: this.tableNumber }),
    };

    const priceDetails: PriceDetails = {
      price: this.event.price,
      currency: this.event.currency,
    };

    return {
      order,
      price: priceDetails,
    };
  }

  public totalQuantity(): number {
    return this.shoppingCartService.getTotalQuantity();
  }

  public getInstantTranslation(key: string, data: FormCredentials): string {
    return this.translateService.instant(key, data);
  }

  public getNumberOfSelectedImages(imageId: string): number {
    const result = this.selectedItems.find(item => item.id === imageId);
    return result ? result.numberOfImages : 0;
  }

  public selectImage(imageSelect: ImageSelection): void {
    this.popupDialog.closePopupWithLink();
    imageSelect.isSelected
      ? this.selectedItems.push({
          numberOfImages: 1,
          name: imageSelect.name,
          id: imageSelect.id,
          url: imageSelect.url,
        })
      : (this.selectedItems = this.selectedItems.filter(value => value.id !== imageSelect.imageId));
  }

  public isSelected(imageId: string) {
    return this.selectedItems.some(item => item.id === imageId);
  }

  public changeNumberOfImages(image: ImageSelection): void {
    this.selectedItems = this.selectedItems.map(item => {
      return item.id === image.imageId ? { ...item, numberOfImages: image.numberOfSelected } : item;
    });
  }

  public getTotalNumber(): number {
    return this.selectedItems.reduce((total, item) => total + item.numberOfImages, 0);
  }

  public isEventActive(): boolean {
    const currentTimestamp = firebase.firestore.Timestamp.fromDate(new Date()).toMillis();
    return this.event?.saleEnd?.toMillis() > currentTimestamp && this.event.isActive;
  }
}
