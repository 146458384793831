import { environment } from 'src/environments/environment';

/**
 * Utility function to get Base URL based on env.
 *
 *
 * @returns Correct baseUrl.
 */
export function getDomain(): string {
  return environment.baseUrl;
}
