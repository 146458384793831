import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  @Input() text: string;
  @Input() backWithoutHistory: boolean = false;
  @Input() param?: string;
  @Input() iconWidth: string;

  constructor(private router: Router, private location: Location, private route: ActivatedRoute) {}

  public goBack(): void {
    if (this.backWithoutHistory) {
      this.location.back();
    } else {
      this.router.navigate([`../${this.param || ''}`], { relativeTo: this.route });
    }
  }
}
