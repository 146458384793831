<div class="relative flex flex-col items-center justify-center px-10 py-8 bg-white max-w-108">
  <button [mat-dialog-close]="true" class="absolute self-start w-5 h-5 top-6 right-6 text-zinc-600">
    <app-icon icon="close-icon.svg" color="zinc-600" width="w-5" alt="Close Icon"></app-icon>
  </button>

  <app-title [type]="titleType.BIG" text="payment_method.popup.title.text"></app-title>

  <app-description
    [type]="descriptionType.MEDIUM"
    customClasses="mt-4"
    text="payment_method.popup.description.text"
  ></app-description>

  <div class="w-full flex flex-col gap-3.5 mt-5">
    <app-button
      (click)="stripeMethod()"
      [buttonInfo]="{
        text: 'payment_method.popup.stripe.button.text',
        type: buttonType.WHITE_RECTANGLE,
        icon: 'credit-card-icon.svg',
        iconSize: 'w-5'
      }"
    ></app-button>

    <ngx-paypal [config]="payPalConfig"></ngx-paypal>
  </div>

  <p
    *ngIf="!showCouponInput && !couponData"
    class="font-normal text-slate-400 font-montserrat text-medium-sm text-center"
  >
    {{ 'payment_method.popup.coupon.question.text' | translate }}{{ ' ' }}
    <button
      (click)="showCouponInputField()"
      class="font-medium text-sky-700 font-montserrat text-medium-sm hover:underline"
    >
      {{ 'payment_method.popup.coupon.text.link' | translate }}
    </button>
  </p>

  <div *ngIf="showCouponInput && !couponData" class="w-full">
    <div class="w-full h-px bg-sky-100 mt-2 mb-4"></div>
    <form (ngSubmit)="applyCoupon()">
      <input
        class="border box-border min-w-full w-auto border-neutral-300 p-2.5 text-base shadow-input rounded font-normal text-zinc-900 placeholder:text-neutral-400"
        type="text"
        [placeholder]="'payment_method.popup.coupon.input.placeholder' | translate"
        name="Coupon"
        [(ngModel)]="couponInput"
      />
      <app-button
        class="w-full"
        [buttonInfo]="{
          text: 'payment_method.popup.coupon.button.text',
          type: buttonType.ROYAL_BLUE_SMALL,
          isDisabled: !couponInput,
          buttonActionType: buttonActionType.SUBMIT,
          customClasses: 'mt-3 w-full'
        }"
      ></app-button>
    </form>
  </div>

  <p *ngIf="couponData" class="font-normal text-slate-400 font-montserrat text-medium-sm text-center">
    {{ 'payment_method.popup.coupon_applied.text' | translate }}{{ ' ' }}
    <span class="font-medium text-sky-700 font-montserrat text-medium-sm"
      >{{ couponData.name }} (-{{ couponData.discount
      }}{{ couponData.discountType == couponType.PERCENTAGE ? '%' : this.selectedCurrency.toUpperCase() }})</span
    >
  </p>
</div>
