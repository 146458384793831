import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[protectDisabled]',
})
export class ProtectDisabledDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    const inputElement = this.el.nativeElement;

    // Monitor changes to the attributes of the input element
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === 'disabled') {
          const disabledAttr = inputElement.getAttribute('disabled');
          if (disabledAttr === null) {
            this.renderer.setAttribute(inputElement, 'disabled', 'true');
          }
        }
      });
    });

    observer.observe(inputElement, { attributes: true });
  }
}
