<div
  class="flex flex-col w-full bg-white rounded-xl shadow-eventBlock hover:cursor-pointer"
  (click)="onCardClick(eventData.refId)"
>
  <div class="bg-white border border-gray-200 rounded-lg shadow-md">
    <img
      class="object-cover object-center w-full h-48 bg-no-repeat rounded-t-lg"
      [src]="this.eventData.mainImage ? this.eventData.mainImage : './assets/images/default-event-image.webp'"
      alt=""
    />

    <div class="flex items-center justify-between flex-1 py-4 pl-4 pr-2">
      <!-- TODO: Add what can be displayed here -->
      <p class="text-slate-400">
        {{ eventData.name }} ({{ convertToJSDate(eventData.eventDate) | date : 'dd LLL y' }})
      </p>

      <app-modal>
        <app-icon
          modal-button
          class="grid w-8 h-8 rounded-full cursor-pointer place-items-center hover:bg-slate-100"
          icon="three-dots-icon.svg"
          color="gray-400"
          width="w-1"
        ></app-icon>

        <div modal-container class="p-3 shadow-modal">
          <div class="flex flex-col space-y-1">
            <app-button
              (click)="onEditEvent(eventData)"
              class="text-sm whitespace-nowrap"
              [buttonInfo]="{
                type: ButtonType.DROPDOWN_WHITE,
                text: 'user_page.card_modal.button_edit',
                icon: 'edit-icon.svg',
                iconColor: 'sky-700',
                iconSize: 'w-4',
                customClasses: 'flex items-center rounded gap-2.5'
              }"
            ></app-button>
            <app-button
              (click)="onDeleteEvent(eventData)"
              class="text-sm whitespace-nowrap"
              [buttonInfo]="{
                type: ButtonType.DROPDOWN_WHITE,
                text: 'user_page.card_modal.button_delete',
                icon: 'trash-can-icon.svg',
                iconColor: 'red-600',
                iconSize: 'w-4',
                customClasses: 'flex items-center rounded gap-2.5'
              }"
            ></app-button>
          </div>
        </div>
      </app-modal>
    </div>
  </div>
</div>
