import { Component, Input } from '@angular/core';
import { EventInfoBlock } from 'src/app/interfaces/EventInfoBlock.interface';
import { TitleType } from 'src/app/interfaces/Title.enum';

@Component({
  selector: 'app-event-info-block',
  templateUrl: './event-info-block.component.html',
  styleUrls: ['./event-info-block.component.scss'],
})
export class EventInfoBlockComponent {
  @Input() eventInfoBlocks: EventInfoBlock[];
  @Input() title: string;
  @Input() saleHoursLeft?: number;
  @Input() saleMinutesLeft?: number;
  public readonly TitleType = TitleType;

  public isStringType(data: string | number): boolean {
    return typeof data === 'string';
  }
}
